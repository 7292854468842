import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchNetworks, addDefaultNetwork, addNetwork, editNetwork, deleteNetwork, uploadToNetwork, fetchBoardListCardNetworks } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useNetworks = () => {
  const query = useQuery(["get-networks"], fetchNetworks, {select: (data) => data,});
  checkUserAuthorizedQuery(query);
  return query;
};
export const useBoardListCardNetworks = (boardListCardId) => {
  return useQuery(["get-networks2", boardListCardId], fetchBoardListCardNetworks, {select: (data) => data,});
};
export const useAddNetwork = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-network"], {
    mutationFn: (data) => addNetwork(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-networks']);
      }, 500);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-network"]);
};

export const useAddDefaultNetwork = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-default-network"], {
    mutationFn: (data) => addDefaultNetwork(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-networks']);
      }, 500);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-default-network"]);
};

export const useEditNetwork = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-network"], {
    mutationFn: (data) => editNetwork(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-networks']);
      }, 500);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-network"]);
};

export const useDeleteNetwork = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-network"], {
    mutationFn: (data) => deleteNetwork(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-networks']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-network"]);
};


export const useUploadToNetwork = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["upload-network"], {
    mutationFn: (data) => uploadToNetwork(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["upload-network"]);
};

export const useScheduledNetworks = () => {
  const query = useQuery(["get-networks"], fetchNetworks, {select: (data) => data,});
  return query;
};