import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchLocations, uploadToLocation, editLocation } from "../helpers/api";

export const useLocations = () => {
  const query = useQuery(["get-locations"], fetchLocations, {select: (data) => data,});
  return query;
};

export const useEditLocation = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-location"], {
    mutationFn: (data) => editLocation(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-locations'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-location"]);
};

export const useUploadToLocation = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["upload-location"], {
    mutationFn: (data) => uploadToLocation(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["upload-location"]);
};
