import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useLocations } from 'hooks/locations.hook';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { MarkerWithInfoWindow } from 'components/MarkerWithInfoWindow';
import { dateYear } from 'helpers/formatter';

export { ManageProfileLocationHistoryMap };

function ManageProfileLocationHistoryMap() {
  const defaultPosition = { lat: 43.7153176, lng: -79.5178468 };
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [yearRange, setYearRange] = useState([]);
  const [events] = useState([]);
  const [searchText, setSearchText] = useState("");

  const { data: locationsData } = useLocations();
  useEffect(() => {
    if (locationsData !== undefined) {
      setTimeout(function () {
      let distinctLoc = locationsData.reduce((arr, item) => {
        const filtered = arr.filter(i => i['address'] !== item['address']);
        return [...filtered, item];
      }, []);
    
      setFilteredLocations(distinctLoc);
      setLocations(locationsData);

      if (locationsData.length > 0) {
        var currentYear = new Date().getFullYear(), years = [];
        var startYear = dateYear(locationsData[0].durationStart);  
        while (startYear <= currentYear) {
            years.push(currentYear--);
        }   
        setYearRange(years);
      }

      }, 500);
    }
  }, [locationsData, events]);

  const handleFilterMap = (y, m, s) => {
    setMonth(m);
    setYear(y); 
    setSearchText(s);

    let loc = locations;

    if (s !== '') {
      loc = locations.filter(item => (item.name !== null && item.name.toLowerCase().indexOf(s.toLowerCase()) !== -1) || (item.address !== null && item.address.toLowerCase().indexOf(s.toLowerCase()) !== -1));
    }

    if (y !== '0' && y !== 0) {
      if (m === '0' || m === 0) {
        loc = loc.filter(item => item.durationStart.indexOf(y) !== -1);
      }
      else {
        loc = loc.filter(item => item.durationStart.indexOf(y + "-" + m) !== -1);
      }
    }

    setFilteredLocations(loc.reduce((arr, item) => {
        const filtered = arr.filter(i => i['address'] !== item['address']);
        return [...filtered, item];
    }, []));
  }
  
  return (
    <>
      {locations && locations.length > 0 ?
        <div className='row'>
          <div className='col-4'>
            <Form.Select defaultValue={'0'} onChange={(e) => handleFilterMap(e.target.value, "0", "")}>
              <option key={0} value={'0'}>All Years</option>
              {yearRange && yearRange.map((m) => { return <option key={m} value={m}>{m}</option>})}
            </Form.Select>
          </div>
          <div className='col-4'>
            <Form.Select value={month} onChange={(e) => handleFilterMap(year, e.target.value, "")}>
              <option key={0} value={'0'}>All Months</option>
              <option key={1} value={'01'}>January</option>
              <option key={2} value={'02'}>February</option>
              <option key={3} value={'03'}>March</option>
              <option key={4} value={'04'}>April</option>
              <option key={5} value={'05'}>May</option>
              <option key={6} value={'06'}>June</option>
              <option key={7} value={'07'}>July</option>
              <option key={8} value={'08'}>August</option>
              <option key={9} value={'09'}>September</option>
              <option key={10} value={'10'}>October</option>
              <option key={11} value={'11'}>November</option>
              <option key={12} value={'12'}>December</option>
            </Form.Select>
          </div>
          <div className='col-4'>
            <Form.Control type="text" placeholder="Search..." value={searchText} onChange={(e) => handleFilterMap(year, month, e.target.value)} />
          </div>
        </div>
        : <></>
      }
          
      <p>&nbsp;</p>
        {filteredLocations && filteredLocations.length > 0 ?
          <div className='map-container'>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['marker']}>
              <Map
                mapId={'bf51a910020fa25a'}
                defaultZoom={10}
                defaultCenter={defaultPosition}
                gestureHandling={'greedy'}
                disableDefaultUI>
                {filteredLocations.map(item => {
                  return <MarkerWithInfoWindow key={item.id} location={item} locations={locations} />
                })}
              </Map>
            </APIProvider>
          </div>
        : <></>
        }
      <p>&nbsp;</p>
    </>
  );
}