import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchNews, addNews, editNews, deleteNews, fetchNewsHighlights } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useNews = (id, count) => {
  const query = useQuery(["get-news", id, count], fetchNews, {select: (data) => data,});
  checkUserAuthorizedQuery(query);
  return query;
};
export const useAddNews = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-news"], {
    mutationFn: (data) => addNews(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-news'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-news"]);
};

export const useEditNews = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-news"], {
    mutationFn: (data) => editNews(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-news'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-news"]);
};

export const useDeleteNews = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-news"], {
    mutationFn: (data) => deleteNews(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-news'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-news"]);
};

export const useNewsHighlights = () => {
  const query = useQuery(["get-news-highlights"], fetchNewsHighlights, {select: (data) => data,});
  return query;
};