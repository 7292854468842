import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import { OrgChart } from "d3-org-chart";
import CustomNodeContent from "./customNodeContent";
import CustomExpandButton from "./customExpandButton";
import DetailCard from "./detailCard";

const OrganizationalChart = (props) => {
  const d3Container = useRef(null);
  const [cardShow, setCardShow] = useState(false);
  const [networkId, setNetworkId] = useState("");
  const handleShow = () => setCardShow(true);
  const handleClose = () => { setCardShow(false); props.resetSelectedNetwork(); };
  const handleReopen = (newNetworkId) => { 
    handleClose();
    setNetworkId(newNetworkId);
  }

  useEffect(() => {
    if (networkId !== "") { 
      handleShow(); 
    }
  }, [networkId]);

  useLayoutEffect(() => {
    const toggleDetailsCard = (nodeId) => {
      setNetworkId(nodeId);
      handleShow();
    };
    
    if (props.selectedNetwork !== null && props.selectedNetwork !== '') {
      toggleDetailsCard(props.selectedNetwork);
    }

    const chart = new OrgChart();
    if (props.data && d3Container.current) {
      chart
        .container(d3Container.current)
        .data(props.data)
        .nodeWidth((d) => 300)
        .nodeHeight((d) => 140)
        .compactMarginBetween((d) => 80)
        .onNodeClick((d) => {
          toggleDetailsCard(d.data);
        })
        .buttonContent((node, state) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CustomExpandButton {...node.node} />
          );
        })
        .nodeContent((d) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CustomNodeContent {...d} />
          );
        })
        .render();
    }
  }, [props, props.data]);

  return (
    <div className="org-chart" ref={d3Container}>
      {cardShow && networkId && (
        <DetailCard
          networks={props.data}
          network={props.data.find((network) => network.id === networkId.id)}
          handleClose={handleClose}
          handleReopen={handleReopen}
        />
      )}
    </div>
  );
};

export { OrganizationalChart };
