import React, { useState, useEffect } from 'react';
import { SidePanelRow } from 'components';
import { ManageAccounts, Transactions, Dashboard} from 'sections';
import { useAccounts } from 'hooks/accounts.hook';
import { useBanks } from 'hooks/banks.hook';
import { useAccountTypes } from 'hooks/account-types.hook';
import { highlightAccount, togglePanelSideChildren, togglePanelSideSelected } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';

export { Accounts };

function Accounts() {
    const [transactions, setTransactions] = useState(false);
    const [manageAccounts, setManageAccounts] = useState(false);
    const [dashboard, setDashboard] = useState(true);
    
    const [total, setTotal] = useState(0);
    const [totalBanking, setTotalBanking] = useState(0);
    const [totalInvestments, setTotalInvestments] = useState(0);
    const [totalCash, setTotalCash] = useState(0);
    const [totalCredit, setTotalCredit] = useState(0);
    const [totalAssets, setTotalAssets] = useState(0);

    const [selectedAccount, setSelectedAccount] = useState({});

    const { data: accountsData, error: accountsError, isError: accountsIsError, isLoading: accountsIsLoading } = useAccounts();
    const [accounts, setAccounts] = useState([]);
    useEffect(() => { setAccounts(accountsData);}, [accountsData]);

    const { data: bankData } = useBanks();
    const [banks, setBanks] = useState([]);
    useEffect(() => { setBanks(bankData); }, [bankData]);
  
    const { data: accountTypeData } = useAccountTypes();
    const [accountTypes, setAccountTypes] = useState([]);
    useEffect(() => { setAccountTypes(accountTypeData); }, [accountTypeData]);

    useEffect(() => { 
        if (accounts != null) {
            setTotal(accounts.filter(account => account.accountType.id !== "3e00754e-32ae-456a-8271-96c8e0b1319f" && 
            account.accountType.id !== "ad31cc1e-ab80-4b0f-9d44-830df5fb32c1" &&
            account.accountType.id !== "c2ceb7eb-1510-4b75-87a6-f7d4b128539a" && 
            account.accountType.id !== "4dc6ec5e-6dd8-4c09-b597-a157a8c28f3e" && 
            account.accountType.id !== "8b6cb561-d793-48fb-b85a-4da4d075ba47").reduce((a,v) =>  a = a + v.total , 0 ));

            setTotalCash(
                accounts.filter(account => account.accountType.id === "dfa5e1cd-eff2-4315-8f7f-e458682cb79f").reduce((a,v) =>  a = a + v.total , 0 )
            );

            setTotalInvestments(
                accounts.filter(account => account.accountType.id === "b4763e19-554b-48b0-a5b9-1084bcd95582").reduce((a,v) =>  a = a + v.total , 0 )
            );

            setTotalBanking(
                accounts.filter(account => account.accountType.id === "9f281b4b-7d84-450b-b3b1-e5feea97f4e6" || account.accountType.id === "aed60cb1-9aed-4b97-bcb4-31b6f7f0883b").reduce((a,v) =>  a = a + v.total , 0 )
            );

            setTotalAssets(
                accounts.filter(account => account.accountType.id === "4dc6ec5e-6dd8-4c09-b597-a157a8c28f3e").reduce((a,v) =>  a = a + v.total , 0 )
            );

            setTotalCredit(
                accounts.filter(account => (account.accountType.id === "3e00754e-32ae-456a-8271-96c8e0b1319f" || 
                                            account.accountType.id === "ad31cc1e-ab80-4b0f-9d44-830df5fb32c1" || 
                                            account.accountType.id === "c2ceb7eb-1510-4b75-87a6-f7d4b128539a"|| 
                                            account.accountType.id === "8b6cb561-d793-48fb-b85a-4da4d075ba47") && account.total > 0).reduce((a,v) =>  a = a + v.total , 0 )
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accounts]);

    const uniqueAccountTypesChecker = [];
    const uniqueAccountTypes = [];
    if (accounts) { 
        accounts.map(account => {
            if (uniqueAccountTypesChecker.indexOf(account.accountType.id) === -1) {
                uniqueAccountTypesChecker.push(account.accountType.id)
                uniqueAccountTypes.push(account.accountType)
            }
            return true;
        });
    }

    const uniqueBanksChecker = [];
    const uniqueBanks = [];
    if (accounts) { 
        accounts.map(account => {
            if (uniqueBanksChecker.indexOf(account.bank.id) === -1) {
                uniqueBanksChecker.push(account.bank.id)
                uniqueBanks.push(account.bank)
            }
            return true;
        });
    }

    const resetContent = () => {
        //reset
        setDashboard(false);
        setManageAccounts(false);
        setTransactions(false);
    }

    const handleContent = (e) => {
        resetContent();
        switch (e) {
            case "dashboard":
                window.location.reload(false);
                setDashboard(true);
                break;
            case "manageAccounts":
                setManageAccounts(true);
                break;
            default:
                break;
        }
    }

    const handleTransaction = (e, obj) =>{
        if (obj) {
            if (accountTypes.filter(item => item.id === obj.id).length > 0) {
                togglePanelSideSelected(document.getElementById(obj.id));
                const at = document.getElementsByClassName(obj.id);
                if (at.length > 0) {
                    [...at].forEach((e) => {togglePanelSideChildren(e);});
                }
            }
            else {
                resetContent();
                setSelectedAccount(obj);
                setTransactions(true);
                highlightAccount(e);
            }
        }
    }

    return (
        <div>
            <div className="panel-side panel-side-accounts">
                <div className="panel-side-row">
                    <button className='btn btn-primary' onClick={()=> handleContent("dashboard")}><Icon.BarChartFill size={20}/> Dashboard</button>
                    &nbsp; &nbsp;
                    <button className='btn btn-primary' onClick={()=> handleContent("manageAccounts")}><Icon.GearFill size={20}/> Manage</button>
                </div>
                <SidePanelRow title={<span><Icon.Bank2 size={20}/>&nbsp;&nbsp;Banking</span>} amount={totalBanking} type="panel-side-type-summary" sendHandleTransaction={handleTransaction} />
                <SidePanelRow title={<span><Icon.Safe size={20}/>&nbsp;&nbsp;Investments</span>} amount={totalInvestments} type="panel-side-type-summary" sendHandleTransaction={handleTransaction} />
                <SidePanelRow title={<span><Icon.Cash size={20}/>&nbsp;&nbsp;Cash</span>} amount={totalCash} type="panel-side-type-summary" sendHandleTransaction={handleTransaction} />
                <SidePanelRow title={<span><Icon.Calculator size={20}/>&nbsp;&nbsp;Total</span>} amount={total} type="panel-side-type-summary" sendHandleTransaction={handleTransaction} />
                <SidePanelRow title={<span><Icon.CarFront size={20}/>&nbsp;&nbsp;Assets</span>} amount={totalAssets} type="panel-side-type-summary" sendHandleTransaction={handleTransaction} />
                <SidePanelRow title={<span><Icon.CreditCard size={20}/>&nbsp;&nbsp;Credit</span>} amount={totalCredit} type="panel-side-type-summary" sendHandleTransaction={handleTransaction} />
                <div className='panel-side-separator'></div>
                {uniqueAccountTypes && uniqueAccountTypes.sort((a, b) => a.name > b.name ? 1 : -1).map((accountType) => {
                    return (
                        <div key={accountType.id}>
                            <span id={accountType.id}><SidePanelRow obj={accountType} title={<span><Icon.PiggyBankFill size={20}/>&nbsp;&nbsp;{accountType.name}</span>} amount={accounts.filter(account => account.accountType.id === accountType.id && account.total >= 0).reduce((a,v) =>  a = a + v.total , 0 )} type="panel-side-type-accounttype" sendHandleTransaction={handleTransaction} /></span>
                            {uniqueBanks && uniqueBanks.sort((a, b) => a.name > b.name ? 1 : -1).map((bank) => {
                                if (accounts.filter(account => account.accountType.id === accountType.id && account.bank.id === bank.id).length > 0) {
                                    return (
                                        <div key={bank.id} className={'panel-side-hide ' + accountType.id}>
                                            <SidePanelRow title={<span><Icon.Bank size={20}/>&nbsp;&nbsp;{bank.name}</span>} amount={accounts.filter(account => account.accountType.id === accountType.id && account.bank.id === bank.id && account.total >= 0).reduce((a,v) =>  a = a + v.total , 0 )} type="panel-side-type-bank" sendHandleTransaction={handleTransaction} />
                                            {accounts && accounts.sort((a, b) => a.name > b.name ? 1 : -1).filter(account => account.accountType.id === accountType.id && account.bank.id === bank.id).map((account) => {
                                                return (
                                                    <SidePanelRow key={account.id} obj={account} title={account.name} amount={account.total} type="panel-side-type-account" sendHandleTransaction={handleTransaction} />
                                                )
                                            })}
                                        </div>
                                    );
                                }
                                else { 
                                    return '';
                                }
                            })}
                        </div>);
                })}
                {accountsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                {accountsIsError && <div className="text-danger">Error loading accounts: {accountsError.message}</div>}     
            </div> 
            <div className="panel-content">
                {dashboard && (
                    <Dashboard />
                )}
                {manageAccounts && (
                    <ManageAccounts accountsData={accounts} bankData={banks} accountTypeData={accountTypes} accountsError={accountsError} accountsIsError={accountsIsError} accountsIsLoading={accountsIsLoading} />
                )}
                {transactions && (
                    <Transactions accountsData={accounts} account={selectedAccount} limit={50} />
                )}
            </div>
        </div>
    );
}
