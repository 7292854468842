import { ManageProfileGeneral} from 'sections';

export { Profile };

function Profile() {
    return (
        <div className='panel'>
            <ManageProfileGeneral/>
        </div>
    );
}
