import { useSelector } from 'react-redux';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { isUserVerified } from 'helpers/current-user';
import * as Icon from 'react-bootstrap-icons';
import { useLocation } from "react-router-dom";

export { Navigation };

function Navigation() {
    const authUser = useSelector(x => x.auth.user);

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");

    return (
      <Navbar expand="lg">
          <Navbar.Brand href="/" className={splitLocation[1] === "" ? "nav-active" : "" } title={"Bartolo Digital Accounting v." + process.env.REACT_APP_VERSION}>Bartolo Digital Accounting</Navbar.Brand>
          {authUser && (
            <>
              <Navbar.Toggle aria-controls="navbar-nav" />
                <Navbar.Collapse id="navbar-nav">
                  <Nav className="me-auto">
                    {isUserVerified() && <Nav.Link href="/accounts" className={splitLocation[1] === "accounts" ? "nav-active" : ""}><span><Icon.Coin size={20} /> </span>Accounts</Nav.Link>}
                    {isUserVerified() && <Nav.Link href="/budget" className={splitLocation[1] === "budget" ? "nav-active" : ""}><span><Icon.PiggyBank size={20} /> </span>Budget</Nav.Link>}
                    {isUserVerified() && <Nav.Link href="/boards" className={splitLocation[1] === "boards" ? "nav-active" : ""}><span><Icon.CardChecklist size={20} /> </span>Boards</Nav.Link>}
                    {isUserVerified() && <Nav.Link href="/network" className={splitLocation[1] === "network" ? "nav-active" : ""}><span><Icon.People size={20} /> </span>Network</Nav.Link>}
                    {isUserVerified() && <Nav.Link href="/vaults" className={splitLocation[1] === "vaults" ? "nav-active" : ""}><span><Icon.Lock size={20} /> </span>Vaults</Nav.Link>}
                    {isUserVerified() && <Nav.Link href="/inventory" className={splitLocation[1] === "inventory" ? "nav-active" : ""}><span><Icon.ClipboardCheck size={20} /> </span>Inventory</Nav.Link>}
                    {isUserVerified() && <Nav.Link href="/news" className={splitLocation[1] === "news" ? "nav-active" : ""}><span><Icon.Book size={20} /> </span>News</Nav.Link>}
                  </Nav>
                </Navbar.Collapse>
            </>
          )}
          {!authUser && (<Nav className="me-auto"></Nav>)}
          
          <span className='version'>{process.env.REACT_APP_ENV === "production" ? "" : <>{process.env.REACT_APP_ENV} </>}v.{process.env.REACT_APP_VERSION} </span>
          <a className='poweredby' href='https://www.bartolodigital.com' target='_blank'><img src='/images/icon-powered.png'></img></a>
      </Navbar>
    );
}