import React, {useState, useRef, useEffect} from 'react';
import { getUser, isUserVerified } from 'helpers/current-user';
import { useVerification, useEditUserVerification } from 'hooks/users.hook';

export { Verify };

function Verify() {
    const [user] = useState(getUser());

    const [validated, setValidated] = useState(true);
    const [errorMessage, setSetErroMessage] = useState("");
    const [verificationCode, setVerificationCode] = useState("0");
    const [loaded] = useState(false);
    const numberOfDigits = 6;
    const [otp, setOtp] = useState(new Array(numberOfDigits).fill(""));
    const otpBoxReference = useRef([]);

    const editUserVerificationMutation = useEditUserVerification();

    function handleChange(value, index) {
        let newArr = [...otp];
        newArr[index] = value;
        setOtp(newArr);

        if(value && index < numberOfDigits-1){
            otpBoxReference.current[index + 1].focus()
        }
    }

    function handleBackspaceAndEnter(e, index) {
        if(e.key === "Backspace" && !e.target.value && index > 0){
            otpBoxReference.current[index - 1].focus()
        }
        if(e.key === "Enter" && e.target.value && index < numberOfDigits-1){
            otpBoxReference.current[index + 1].focus()
        }
        
        setVerificationCode(otp.join(""));
    }

    useEffect(() => { 
        if ((isUserVerified())) {
            window.location.href = "/";
        }
    }, []);

    const [verified, setVerified] = useState(false);
    const { data: verifiedData } = useVerification(verificationCode);
    useEffect(() => { 
        setVerified(verifiedData); 
    }, [verifiedData]);

    const handleSubmit = async (event) => {
        setSetErroMessage("");
        if (otp.join("").length === 6) {
            if (verified) {
                setValidated(true);
                setSetErroMessage("");

                let user = localStorage.getItem('user').replace('"verified":"false"', '"verified":"true"');
                localStorage.setItem('user', user);

                editUserVerificationMutation.mutate({
                    code: otp.join(""),
                });

                window.location.href = "/";
            }
            else {
                if (verificationCode.length === 6) {
                    setValidated(false);
                    setSetErroMessage("Incorrect code. Please try again.");
                }
            }
        }
        else {
            setValidated(false);
            setSetErroMessage("Incorrect code. Please try again.");
        }

        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div className='panel'>
            <h1 className='text-center'>User Verification</h1>
            <p></p>
            
            {!loaded && <div className='verification'> 
                <p className='text-center'>{user.username}</p>
                <p className='text-center'>{`${user.firstName} ${user.lastName}`}</p>
                <form onSubmit={handleSubmit}>          
                    <h4 className="text-center mb-4">Enter your code</h4>
                    <div className="d-flex mb-3">
                    {otp.map((digit, index)=>(
                    <input key={index} value={digit} maxLength={1}  
                        onChange={(e)=> handleChange(e.target.value, index)}
                        onKeyUp={(e)=> handleBackspaceAndEnter(e, index)}
                        ref={(reference) => (otpBoxReference.current[index] = reference)}
                        className={"form-control"}/>))}
                    </div>
                    <button type="submit" className="w-100 btn btn-primary">Verify account</button>
                    {!validated &&
                        <div className="mt-3 mb-0 error">{errorMessage}</div>
                    }
                </form> 
            </div>
            }
        </div>
                
    );
}
