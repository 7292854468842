import React, { useState, useEffect, useMemo} from 'react';
import { useSummaryByYear, useSummaryByMonth, useSummaryTransactions } from 'hooks/transactions.hook';
import { PieChart, Pie, Cell } from 'recharts';
import { currency, date } from 'helpers/formatter';
import { TableBasicClick } from 'components';
import * as Icon from 'react-bootstrap-icons';

export { Dashboard };

function Dashboard() {
  
  const [title, setTitle] = useState("Dashboard");
  const [showYears, setShowYears] = useState(true);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showMonths, setShowMonths] = useState(false);
  const [selectedYear, setSelectedYear] = useState(0);
  const [selectedMonth, setSelectedMonth] = useState(0);

  const { data: yearData, isLoading: isYearLoading } = useSummaryByYear();
  const [year, setYear] = useState([]);
  useEffect(() => { setYear(yearData); }, [yearData]);

  const { data: monthData, isLoading: isMonthLoading } = useSummaryByMonth(selectedYear);
  const [month, setMonth] = useState([]);
  useEffect(() => { setMonth(monthData); }, [monthData]);

  const { data: transactionsData, isLoading: isTransactionsLoading } = useSummaryTransactions(selectedYear, selectedMonth);
  const [transactions, setTransactions] = useState([]);
  useEffect(() => { setTransactions(transactionsData); }, [transactionsData]);

  const COLORS = ['#223e7d', '#b91946'];
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  const handleShowMonths = (year) => {
    setShowYears(false);
    setShowTransactions(false);
    setShowMonths(true);
    setSelectedYear(year);
    setTitle(year);
  }
  const handleShowTransactions = (year, month, monthName) => {
    setShowMonths(false);
    setShowTransactions(true);
    setSelectedMonth(month);
    setTitle(`${monthName} ${year}`);
  }
  const handleShowYears = () => {
    setShowYears(true);
    setShowMonths(false);
    setTitle("Dashboard");
  }

  const formatTrProps = (state = {}) => {
    //return { onClick: () => editOnClick(state.original) };
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => (
          date(row.original.date)
        )
      },
      {
        Header: "Payee",
        accessor: "payee",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Account",
        accessor: "accountName",
      },
      {
        Header: "Bank",
        accessor: "bankName",
      },
      {
        Header: "Withdrawal",
        accessor: "withdrawal",
        Cell: ({ row }) => (
          <div className='text-right'>{row.original.withdrawal !== 0 ? currency(-1 * row.original.withdrawal) : ""}</div>
        )
      },
      {
        Header: "Deposit",
        accessor: "deposit",
        Cell: ({ row }) => (
          <div className='text-right'>{row.original.deposit !== 0 ? currency(row.original.deposit) : ""}</div>
        )
      },
    ],
    []
  );

  return (
    <div>
      <h1><Icon.BarChartFill size={30}/> {title}</h1>
      <p></p>
      <hr/>
      {showMonths && (
        <div className="menu-content">
          <p></p>
          <button className='btn btn-primary' onClick={() => handleShowYears()}><Icon.CaretLeftFill size={20}/> Back</button>&nbsp;
        </div>
      )}
      {showTransactions && (
        <div className="menu-content">
          <p></p>
          <button className='btn btn-primary' onClick={() => handleShowMonths(selectedYear)}><Icon.CaretLeftFill size={20}/> Back</button>&nbsp;
        </div>
      )}
      <p>&nbsp;</p>
        {showYears && year && year.map(y => (
          <div key={y.year} className='charts' onClick={() => handleShowMonths(y.year)}>
            <div className='chart-header'>
              <button className='btn btn-primary'><Icon.Calendar3 size={15}/>  {y.year}</button>
            </div>
            <PieChart width={350} height={400}>
              <Pie
                data={y.chartData}
                cx="50%"
                cy="50%"
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {y.chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
            <div className='chart-footer'>
              <div className='chart-footer-content'>
                <p><span className='chart-income'></span><span className='chart-subtitle'>Income: <span>{currency(y.income)}</span></span></p>
                <p><span className='chart-expenses'></span><span className='chart-subtitle'>Expenses: <span>{currency(y.expenses)}</span></span></p>
                <p><span className='chart-gross'></span><span className='chart-subtitle'>Gross: <span className={y.gross < 0 ? 'chart-gross-loss' : 'chart-gross-gain'}>{currency(y.gross)}</span></span></p>
              </div>
            </div>
            <hr></hr>
          </div>
        ))}
      {isYearLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }

      {showMonths && month && month.map(m => (
        <div key={m.month} className='charts' onClick={() => handleShowTransactions(m.year, m.month, m.monthName)}>
          <div className='chart-header'>
            <button className='btn btn-primary'><Icon.Calendar3 size={15}/> {m.monthName} {m.year}</button>
          </div>
          <PieChart width={350} height={400}>
            <Pie
              data={m.chartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={100}
              fill="#8884d8"
              dataKey="value"
            >
              {m.chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
          </PieChart>
          <div className='chart-footer'>
            <div className='chart-footer-content'>
              <p><span className='chart-income'></span><span className='chart-subtitle'>Income: <span>{currency(m.income)}</span></span></p>
              <p><span className='chart-expenses'></span><span className='chart-subtitle'>Expenses: <span>{currency(m.expenses)}</span></span></p>
              <p><span className='chart-gross'></span><span className='chart-subtitle'>Gross: <span className={m.gross < 0 ? 'chart-gross-loss' : 'chart-gross-gain'}>{currency(m.gross)}</span></span></p>
            </div>
          </div>
          <hr></hr>
        </div>
      ))}
      {isMonthLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }

      {showTransactions && transactions && <TableBasicClick responsive columns={columns} data={transactions} formatRowProps={(state) => formatTrProps(state)} />}
      {isTransactionsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
    </div>
  );
}