import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { useUsers, useEditUser, useAddUser, useDeleteUser } from 'hooks/users.hook';
import * as Icon from 'react-bootstrap-icons';

export { Users };

function Users() {
  const [validated, setValidated] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [passwordMatchValidation, setPasswordMatchValidation] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  const { data: usersData, error: usersError, isError: usersIsError, isLoading: usersIsLoading } = useUsers();
  const [users, setUsers] = useState([]);
  useEffect(() => { setUsers(usersData); }, [usersData]);

  const addUserMutation = useAddUser();
  const editUserMutation = useEditUser();
  const deleteUserMutation = useDeleteUser();

  const handleAddClose = () => setShowAddDialog(false);
  const handleEditClose = () => setShowEditDialog(false);
  const handleDeleteClose = () => setShowDeleteDialog(false);
      
  useEffect(()=>{
    if (addUserMutation && addUserMutation.isSuccess) {
      //setShowAddDialog(false);
      //setValidated(false);
    }
  },[addUserMutation]);

  const handleDelete = () => {
    deleteUserMutation.mutate({
      id: selectedUser.id,
    });
    setShowDeleteDialog(false);
    setShowEditDialog(false);
  };

  const handleAddSubmit = (event) => {
    setPasswordMatchValidation(true);
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
        if (form.formPassword1.value === form.formPassword2.value) {
            addUserMutation.mutate({
                firstName: form.formFirstName.value,
                lastName: form.formLastName.value,
                username: form.formUsername.value,
                password: form.formPassword1.value
            });

            setShowAddDialog(false);
            setValidated(false);
        }
        else {
          setPasswordMatchValidation(false);
          setValidated(true);
        }
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEditSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      if (form.formPassword1.value === form.formPassword2.value) {
          editUserMutation.mutate({
            id: selectedUser.id,
            firstName: form.formFirstName.value,
            lastName: form.formLastName.value,
            username: form.formUsername.value,
            password: form.formPassword1.value,
            isActive: isActive
          });

          setShowEditDialog(false);
          setValidated(false);
      }
      else {
        setPasswordMatchValidation(false);
        setValidated(true);
      }
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const editOnClick = (account) => {
    if (account != null) {
      setSelectedUser(account);
      setShowEditDialog(true);
      setPasswordMatchValidation(true);
    }
  };

  const formatTrProps = (state = {}) => {
    return { onClick: () => editOnClick(state.original) };
  };
  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "Is Active",
        accessor: "isActive",
        Cell: ({ row }) => (
          <>
              {row.original.isActive && (<div><Icon.Check size={25}/></div>)}
          </>
        )
      }
    ],
    []
  );

  return (
    <div className='panel'>
        <h1><Icon.PeopleFill size={35}/> Users</h1>
        <p></p>
        <hr></hr>
        <p><button className='btn btn-primary' onClick={() => setShowAddDialog(true)} ><Icon.Plus size={20}/> Add User</button></p>
        
        {users && <TableBasicClick responsive columns={columns} data={users} formatRowProps={(state) => formatTrProps(state)} />}
        {usersIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
        {usersIsError && <div className="text-danger">Error loading users: {usersError.message}</div>}

        <Modal
          size="lg"
          show={showAddDialog}
          onHide={() => setShowAddDialog(false)}
          aria-labelledby="modal-add-account"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-add-account">Add User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleAddSubmit}>
              <Form.Group className="mb-3" controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control required maxLength="50" type="text" placeholder="Enter a username" />
                <Form.Control.Feedback type="invalid">Please enter a username.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required maxLength="50" type="text" placeholder="Enter a first name" />
                <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required maxLength="50" type="text" placeholder="Enter a last name" />
                <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPassword1">
                <Form.Label>Password</Form.Label>
                <Form.Control required type="password" placeholder="Enter a password" />
                <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPassword2">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control required type="password" placeholder="Confirm password" />
                <Form.Control.Feedback type="invalid">Please confirm password.</Form.Control.Feedback>
                {!passwordMatchValidation && <div className="form-error">Your password does not match. Please try again</div>}
              </Form.Group>
              <Button variant="primary" type="submit">Save</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleAddClose}>Cancel</Button>
              {addUserMutation.isError && <div className="form-error"><p></p>{addUserMutation.error.response.data}</div>}
            </Form>
          </Modal.Body>
        </Modal>
        
        <Modal
          size="lg"
          show={showEditDialog}
          onHide={() => setShowEditDialog(false)}
          aria-labelledby="modal-edit-account"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-edit-account">Edit User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
              <Form.Group className="mb-3" controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control readOnly type="text" defaultValue={selectedUser.username} placeholder="Enter a username" />
                <Form.Control.Feedback type="invalid">Please enter a username.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control required type="text" defaultValue={selectedUser.firstName} placeholder="Enter a first name" />
                <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control required type="text" defaultValue={selectedUser.lastName} placeholder="Enter a last name" />
                <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPassword1">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter a password" />
                <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formPassword2">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control type="password" placeholder="Confirm password" />
                {!passwordMatchValidation && <div className="form-error">Your password does not match. Please try again</div>}
              </Form.Group>
              <Form.Group className="mb-3" controlId="formIsActive">
                <Form.Label>Is Active</Form.Label>
                <Form.Check type="switch" defaultChecked={selectedUser.isActive} onChange={(e) => {setIsActive(!isActive);}} />
              </Form.Group>
              <br></br><br></br>
              <Button variant="primary" type="submit">Save</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleEditClose}>Cancel</Button>
              <Button variant="danger" className='modal-btn-right' onClick={()=> setShowDeleteDialog(true)}>Delete</Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeleteDialog}
          onHide={() => setShowDeleteDialog(false)}
          aria-labelledby="modal-delete"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Are you sure you would like to delete:</p>
              {showDeleteDialog && <div><p>{selectedUser.username}</p><p>{selectedUser.firstName}</p><p>{selectedUser.lastName}</p></div>}
              <Button variant="danger" type="submit" onClick={handleDelete}>Yes</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleDeleteClose}>No</Button>
          </Modal.Body>
        </Modal>
    </div>
  );
}