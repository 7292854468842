import React, {useState} from 'react'
import {useAsyncDebounce} from 'react-table'
 
export function GlobalFilterInput({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter
}) {
 
    const [ value, setValue ] = useState(globalFilter)
    const onChange = useAsyncDebounce((value) => {
        setGlobalFilter(value || undefined);
    }, 300);
 
    return (
        <div className='global-filter'>
          <input className='form-control'
            value={value || ''}
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`Search...`}
          />
        </div>
      );
}