import { useSelector, useDispatch } from 'react-redux';
import { isUserInRole, isUserVerified } from 'helpers/current-user';
import * as Icon from 'react-bootstrap-icons';
import { useDate } from 'hooks/date-time.hook';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { authActions } from 'store';

export { StatusBar };

function StatusBar() {
    const authUser = useSelector(x => x.auth.user);
    const { currentDate, currentDateMin, currentTime, wish } = useDate();
    const dispatch = useDispatch();
    const logout = () => dispatch(authActions.logout());
    const navDropdownUser = (<><span className='wish'>{wish}</span> <Icon.PersonFill size={16}/> {authUser?.firstName} </>);

    return (
      <>
          {authUser && (
            <div className='status-bar'>
             <Icon.Calendar3 size={16}/>&nbsp;&nbsp;<span className='date-full'>{currentDate}</span><span className='date-min'>{currentDateMin}</span>&nbsp;&nbsp;<Icon.Clock size={16}/>&nbsp;<span><strong>{currentTime}</strong></span>
              <NavDropdown title={navDropdownUser} id="nav-dropdown-account" className='float-right'>
                {isUserVerified() && <NavDropdown.Item href="/profile"><span><Icon.PersonSquare /> </span>Profile</NavDropdown.Item>}
                {isUserVerified() && <NavDropdown.Item href="/locations"><span><Icon.GeoAltFill /> </span>Location History</NavDropdown.Item>}
                {isUserVerified() && isUserInRole(1) && <NavDropdown.Item  href="/users"><Icon.PeopleFill/> Users</NavDropdown.Item>}
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
              </NavDropdown>
            </div>
          )}
      </>
    );
}