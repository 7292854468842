import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchUsers, addUser, editUser, deleteUser, editUserPassword, editUserProfile, deactivateUser, fetchVerify, editUserVerification } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useUsers = () => {
  const query = useQuery(["get-users"], fetchUsers, {
    select: (data) => data,
    //retry: 2,
    //retryDelay: 2000,
  });
  checkUserAuthorizedQuery(query);
  return query;
};

export const useAddUser = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-user"], {
    mutationFn: (data) => addUser(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-users'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-user"]);
};

export const useEditUser = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-user"], {
    mutationFn: (data) => editUser(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-users'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-user"]);
};

export const useDeactivateUser = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["deactivate-user"], {
    mutationFn: (data) => deactivateUser(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["deactivate-user"]);
};

export const useEditUserPassword = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-user-password"], {
    mutationFn: (data) => editUserPassword(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-users'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-user-password"]);
};

export const useEditUserProfile = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-user-profile"], {
    mutationFn: (data) => editUserProfile(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-users'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-user-profile"]);
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-user"], {
    mutationFn: (data) => deleteUser(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-users'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-user"]);
};

export const useVerification = (code) => {
  return useQuery(["get-verification", code], fetchVerify, {select: (data) => data,});
};

export const useEditUserVerification = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-user-verification"], {
    mutationFn: (data) => editUserVerification(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-user-verification"]);
};