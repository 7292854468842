import { useQuery, useQueryClient, useMutation, useInfiniteQuery } from "react-query";
import { fetchTransactions, addTransaction, importTransactions, editTransaction, deleteTransaction, fetchTransactionPayees, fetchTransactionCategories, fetchLatestTransactionOfPayee, fetchSummaryByYear, fetchSummaryByMonth, fetchSummaryTransactions, fetchFilteredBalance, fetchSummaryByYearByAccount, fetchSummaryByMonthByAccount, fetchSummaryTransactionsByAccount } from "../helpers/api";

export const useTransactions = (accountId, limit, count, search, start, end) => {
  const queryClient = useQueryClient();

  return useInfiniteQuery(["get-transactions", accountId, limit, count, search, start, end], fetchTransactions,
  {
    select: (data) => data,
    getNextPageParam: (_lastPage, pages) => {
      if (pages.length < (count/limit)) {
        return pages.length+1;
      } else {
        return undefined;
      }
    },
    onSuccess:(result, variables, context) => {
      queryClient.invalidateQueries(['get-accounts']);
    }
  });
};

export const useFilteredBalance = (accountId, search, end) => {
  return useQuery(["get-filtered-balance", accountId, search, end], fetchFilteredBalance,{select: (data) => data,});
};

export const useTransactionPayees = (accountId) => {
  return useQuery(["get-transaction-payees", accountId], fetchTransactionPayees,{select: (data) => data,});
};

export const useTransactionCategories = (accountId) => {
  return useQuery(["get-transaction-categories", accountId], fetchTransactionCategories,{select: (data) => data,});
};

export const useLatestTransactionOfPayee = (accountId, payee) => {
  return useQuery(["get-latest-transaction-of-payee", accountId, payee], fetchLatestTransactionOfPayee,{select: (data) => data,});
};

export const useAddTransaction = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-transaction"], {
    mutationFn: (data) => addTransaction(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-transactions']);
      }, 500);    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-transaction"]);
};

export const useImportTransactions = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["import-transaction"], {
    mutationFn: (data) => importTransactions(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-transactions']);
      }, 500);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["import-transaction"]);
};

export const useEditTransaction = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-transaction"], {
    mutationFn: (data) => editTransaction(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-transactions']);
      }, 500);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-transaction"]);
};

export const useDeleteTransaction = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-transaction"], {
    mutationFn: (data) => deleteTransaction(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      setTimeout(function() {
        queryClient.invalidateQueries(['get-transactions']);
      }, 500);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-transaction"]);
};

export const useSummaryByYear = () => {
  return useQuery(["get-transaction-summaries-by-year"], fetchSummaryByYear, {
    select: (data) => data,
  });
};
export const useSummaryByMonth = (year) => {
  return useQuery(["get-transaction-summaries-by-month", year], fetchSummaryByMonth, {
    select: (data) => data,
  });
};
export const useSummaryTransactions = (year, month) => {
  return useQuery(["get-transaction-summaries-of-month", year, month], fetchSummaryTransactions, {
    select: (data) => data,
  });
};

export const useSummaryByYearByAccount = (accountId) => {
  return useQuery(["get-transaction-summaries-by-year-by-account", accountId], fetchSummaryByYearByAccount, {
    select: (data) => data,
  });
};
export const useSummaryByMonthByAccount = (accountId, year) => {
  return useQuery(["get-transaction-summaries-by-month-by-account", year, accountId], fetchSummaryByMonthByAccount, {
    select: (data) => data,
  });
};
export const useSummaryTransactionsByAccount = (accountId, year, month) => {
  return useQuery(["get-transaction-summaries-of-month-of-account", year, month, accountId], fetchSummaryTransactionsByAccount, {
    select: (data) => data,
  });
};