import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {date } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';
import Autocomplete from "react-google-autocomplete";
import { useEditInventory, useDeleteInventory, useAddInventoryRecord, useInventoryRecords, useEditInventoryRecord, useDeleteInventoryRecord } from 'hooks/inventories.hook';

export { ManageInventories };

function ManageInventories(props) {
  const [validated, setValidated] = useState(false);
  const [showEditInventoryDialog, setShowEditInventoryDialog] = useState(false);
  const [showDeleteInventoryDialog, setShowDeleteInventoryDialog] = useState(false);
  const [showAddInventoryRecordDialog, setShowAddInventoryRecordDialog] = useState(false);
  const [showEditInventoryRecordDialog, setShowEditInventoryRecordDialog] = useState(false);
  const [showDeleteInventoryRecordDialog, setShowDeleteInventoryRecordDialog] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [location,setLocation] = useState("");
  const [selectedInventoryRecord, setSelectedInventoryRecord] = useState(0);

  const [inventoryRecords, setInventoryRecords] = useState([]);
  const { data: inventoryRecordsData, isError: inventoryRecordsIsError, error: inventoryRecordsError, isLoading: inventoryRecordsIsLoading } = useInventoryRecords(props.selectedInventory.id);
  useEffect(() => { setInventoryRecords(inventoryRecordsData); }, [inventoryRecordsData]);

  const editInventoryMutation = useEditInventory();
  const deleteInventoryMutation = useDeleteInventory();
  const addInventoryRecordMutation = useAddInventoryRecord();
  const editInventoryRecordMutation = useEditInventoryRecord();
  const deleteInventoryRecordMutation = useDeleteInventoryRecord();

  const handleEditInventoryClose = () => setShowEditInventoryDialog(false);
  const handleDeleteInventoryClose = () => setShowDeleteInventoryDialog(false);
  const handleAddInventoryRecordClose = () => {setShowAddInventoryRecordDialog(false); }
  const handleEditInventoryRecordClose = () => { setShowEditInventoryRecordDialog(false); }
  const handleDeleteInventoryRecordClose = () => setShowDeleteInventoryRecordDialog(false);

  const handleInventoryDelete = () => {
    deleteInventoryMutation.mutate({
      id: props.selectedInventory.id,
    });
    setShowDeleteInventoryDialog(false);
    setShowEditInventoryDialog(false);
    window.location.reload(false);
  };
  const handleEditInventorySubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      editInventoryMutation.mutate({
        id: props.selectedInventory.id,
        name: form.formInventoryName.value,
      });

      props.selectedInventory.name = form.formInventoryName.value;

      setShowEditInventoryDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleInventoryRecordDelete = () => {
    deleteInventoryRecordMutation.mutate({
      id: selectedInventoryRecord.id,
    });
    setShowDeleteInventoryRecordDialog(false);
    setShowEditInventoryRecordDialog(false);
  };

  const handleEditInventoryRecordSubmit = (event) => {
    const form = event.currentTarget;
    let lat = parseFloat(latitude);
    let lng = parseFloat(longitude);
    
    if (form.checkValidity() === true) {
      editInventoryRecordMutation.mutate({
        id: selectedInventoryRecord.id,
        inventoryId: props.selectedInventory.id,
        name: form.formInventoryRecordName.value,
        detail: form.formInventoryRecordDetail.value,
        count: form.formInventoryRecordCount.value,
        min: form.formInventoryRecordMin.value,
        max: form.formInventoryRecordMax.value,
        address: location,
        latitude: parseInt(lat * 10000000),
        longitude: parseInt(lng * 10000000),
      });

      setShowEditInventoryRecordDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAddInventoryRecordSubmit = (event) => {
    const form = event.currentTarget;
    let lat = parseFloat(latitude);
    let lng = parseFloat(longitude);

    if (form.checkValidity() === true) {
      addInventoryRecordMutation.mutate({
        inventoryId: props.selectedInventory.id,
        name: form.formInventoryRecordName.value,
        detail: form.formInventoryRecordDetail.value,
        count: form.formInventoryRecordCount.value,
        min: form.formInventoryRecordMin.value,
        max: form.formInventoryRecordMax.value,
        address: location,
        latitude: parseInt(lat * 10000000),
        longitude: parseInt(lng * 10000000),
      });

      setShowAddInventoryRecordDialog(false);
      setValidated(false);

    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const editInventoryRecordOnClick = (inventoryRecord) => {
    if (inventoryRecord != null) {
      setSelectedInventoryRecord(inventoryRecord);
      setLocation(inventoryRecord.address);
      setLatitude(inventoryRecord.latitude/10000000);
      setLongitude(inventoryRecord.longitude/10000000);
      setShowEditInventoryRecordDialog(true);
    }
  };
  const addInventoryRecordOnClick = () => {
      setShowAddInventoryRecordDialog(true);
      setLocation("");
      setLatitude(0);
      setLongitude(0);
  };
  const formatTrProps = (state = {}) => {
    return { onClick: () => editInventoryRecordOnClick(state.original) };
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ row }) => (
          <>
            {row.original.count === row.original.min ? (
              <span className="inventory-warning">{row.original.name}</span>
            ) : (
              <>
              {row.original.count < row.original.min ? (
                <span className="inventory-danger">{row.original.name}</span>
              ) : (
                <>{row.original.name}</>
              )}
              </>
            )}
          </>
        )
      },
      {
        Header: "Count",
        accessor: "count",
        Cell: ({ row }) => (
          <>
            {row.original.count === row.original.min ? (
              <span className="inventory-warning">{row.original.count}</span>
            ) : (
              <>
              {row.original.count < row.original.min ? (
                <span className="inventory-danger">{row.original.count}</span>
              ) : (
                <>{row.original.count}</>
              )}
              </>
            )}
          </>
        )
      },
      {
        Header: "Modified",
        accessor: "modified",
        Cell: ({ row }) => (
          <>
            {row.original.count === row.original.min ? (
              <span className="inventory-warning">{date(row.original.modified)}</span>
            ) : (
              <>
              {row.original.count < row.original.min ? (
                <span className="inventory-danger">{date(row.original.modified)}</span>
              ) : (
                <>{date(row.original.modified)}</>
              )}
              </>
            )}
          </>
        )
      }
    ],
    []
  );
 
  return (
    <div>
      <h1><Icon.ClipboardCheck size={30} /> {props.selectedInventory.name} <button className="button-text" onClick={() => setShowEditInventoryDialog(true)}><Icon.ThreeDots size={40} /></button></h1>
      <p></p>
      <p><button className='btn btn-primary' onClick={() => addInventoryRecordOnClick()} ><Icon.Plus size={20}/> Add Record</button></p>
      <p></p>
      {inventoryRecords && <TableBasicClick responsive columns={columns} data={inventoryRecords} formatRowProps={(state) => formatTrProps(state)} />}
      {inventoryRecordsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
      {inventoryRecordsIsError && <div className="text-danger">Error loading inventoryRecords: {inventoryRecordsError.message}</div>}

      <Modal
        size="lg"
        show={showEditInventoryDialog}
        onHide={() => setShowEditInventoryDialog(false)}
        aria-labelledby="modal-edit-inventory"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-inventory">Edit Inventory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditInventorySubmit}>
            <Form.Group className="mb-3" controlId="formInventoryName">
              <Form.Label>Inventory Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter a inventory name" defaultValue={props.selectedInventory.name} />
              <Form.Control.Feedback type="invalid">Please enter a inventory name.</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditInventoryClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteInventoryDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteInventoryDialog}
        onHide={() => setShowDeleteInventoryDialog(false)}
        aria-labelledby="modal-delete"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteInventoryDialog && <div><p>{props.selectedInventory.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleInventoryDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteInventoryClose}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showAddInventoryRecordDialog}
        onHide={() => setShowAddInventoryRecordDialog(false)}
        aria-labelledby="modal-add-inventory-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-add-inventory-record">Add Inventory Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddInventoryRecordSubmit}>
            <Form.Group className="mb-3" controlId="formInventoryRecordName">
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" maxLength="100" placeholder="Enter an inventory record name" autoComplete="off" />
              <Form.Control.Feedback type="invalid">Please enter a inventory record name.</Form.Control.Feedback>
            </Form.Group>
            <div className='row'>
              <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordCount">
                <Form.Label>Count</Form.Label>
                <Form.Control required type="number" maxLength="1000000" placeholder="Enter an inventory record count" defaultValue={1} autoComplete="off" />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordMin">
                <Form.Label>Min</Form.Label>
                <Form.Control type="number" maxLength="1000000" placeholder="Enter an inventory record min" defaultValue={0} autoComplete="off" />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordMax">
                <Form.Label>Max</Form.Label>
                <Form.Control type="number" maxLength="1000000" placeholder="Enter an inventory record max" defaultValue={0} autoComplete="off" />
              </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="formInventoryRecordDetail">
              <Form.Label>Detail</Form.Label>
              <Form.Control as="textarea" rows={3} maxLength="400" placeholder="Enter detail" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formInventoryRecordLocation">
              <Form.Label>Location</Form.Label>
                <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={(place) => {
                    setLatitude(place.geometry.location.lat());
                    setLongitude(place.geometry.location.lng());
                    setLocation(place.formatted_address);
                  }}
                  options={{
                      types: ["address"],
                      componentRestrictions: { country: "ca" },
                  }}
              />          
            </Form.Group>
            {location && location.length > 0 && <div><iframe style={{width:"100%"}} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div> }
            <p></p>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleAddInventoryRecordClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showEditInventoryRecordDialog}
        onHide={() => setShowEditInventoryRecordDialog(false)}
        aria-labelledby="modal-edit-inventory-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-inventory-record">Edit Inventory Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditInventoryRecordSubmit}>
            <Form.Group className="mb-3" controlId="formInventoryRecordName">
              <Form.Label>Inventory Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter a inventory name" autoComplete="off" defaultValue={selectedInventoryRecord.name} />
              <Form.Control.Feedback type="invalid">Please enter a inventory name.</Form.Control.Feedback>
            </Form.Group>

            <div className='row'>
              <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordCount">
                <Form.Label>Count</Form.Label>
                <Form.Control required type="number" maxLength="1000000" placeholder="Enter an inventory record count" defaultValue={selectedInventoryRecord.count} autoComplete="off" />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordMin">
                <Form.Label>Min</Form.Label>
                <Form.Control type="number" maxLength="1000000" placeholder="Enter an inventory record min" defaultValue={selectedInventoryRecord.min} autoComplete="off" />
              </Form.Group>
              <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordMax">
                <Form.Label>Max</Form.Label>
                <Form.Control type="number" maxLength="1000000" placeholder="Enter an inventory record max" defaultValue={selectedInventoryRecord.max} autoComplete="off" />
              </Form.Group>
            </div>
            <Form.Group className="mb-3" controlId="formInventoryRecordDetail">
              <Form.Label>Detail</Form.Label>
              <Form.Control as="textarea" rows={3} maxLength="400" placeholder="Enter detail" defaultValue={selectedInventoryRecord.detail} />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formInventoryRecordLocation">
              <Form.Label>Location</Form.Label>
                <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                  apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                  onPlaceSelected={(place) => {
                    setLatitude(place.geometry.location.lat());
                    setLongitude(place.geometry.location.lng());
                    setLocation(place.formatted_address);
                  }}
                  options={{
                      types: ["address"],
                      componentRestrictions: { country: "ca" },
                  }}
                  defaultValue={selectedInventoryRecord.address}
              />          
            </Form.Group>
            {location && location.length > 0 && <div><iframe style={{width:"100%"}} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div> }
            <p></p>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditInventoryRecordClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteInventoryRecordDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteInventoryRecordDialog}
        onHide={() => setShowDeleteInventoryRecordDialog(false)}
        aria-labelledby="modal-delete-inventory-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete-inventory-record">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteInventoryRecordDialog && <div><p>{selectedInventoryRecord.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleInventoryRecordDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteInventoryRecordClose}>No</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}