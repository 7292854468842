import React, { useState, useEffect } from 'react';
import { SidePanelRow } from 'components';
import { ManageNews } from 'sections';
import { useNews, useAddNews } from 'hooks/news.hook';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { highlightBoard } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';

export { News };

function News() {
    const [validated, setValidated] = useState(false);
    const [showAddNewsDialog, setShowAddNewsDialog] = useState(false);

    const [manageNews, setManageNews] = useState(false);
    const [dashboard, setDashboard] = useState(true);
    const [pinned, setPinned] = useState(false);
    const [selectedNews, setSelectedNews] = useState({});

    const { data: newsData, isError:newsIsError, error:newsError, isLoading:newsIsLoading } = useNews("0", 10);
    const [news, setNews] = useState([]);
    useEffect(() => { setNews(newsData);}, [newsData]);

    const handleAddNewsClose = () => setShowAddNewsDialog(false);

    const addNewsMutation = useAddNews();

    const resetContent = () => {
        //reset
        setDashboard(false);
        setManageNews(false);
    }
    const handleNews=(e, obj) =>{
        if (obj) {
            resetContent();
            setSelectedNews(obj);
            setManageNews(true);
            highlightBoard(e);
        }
    }

    const handleAddNewsSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addNewsMutation.mutate({
            name: form.formNewsName.value,
            pinned: pinned,
            tag: form.formNewsTag.value
          });
    
          setShowAddNewsDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
      };

    return (
        <div>
            <div className="panel-side">
                <div className="panel-side-row">
                    <button className='btn btn-primary' onClick={() => setShowAddNewsDialog(true)}><Icon.Plus size={20}/> Add News</button>
                    &nbsp; &nbsp;
                </div>
                {news && news.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((news) => {
                    return (
                        <div key={news.id} className={news.pinned ? 'panel-tagged':''}><SidePanelRow obj={news} title={<span><Icon.Book size={20}/>&nbsp;&nbsp;{news.name}</span>} amount={0} type="panel-side-type-board" sendHandleTransaction={handleNews} hideCost={true}/></div>
                    )
                })}
                {newsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                {newsIsError && <div className="text-danger">Error loading news: {newsError.message}</div>}     
            </div> 
            <div className="panel-content">
                {dashboard && (
                    <div className='text-center-mobile home-nav'>
                    <h1><Icon.Book size={30}/> News</h1>
                    {news && news.sort((a, b) => a.name > b.name ? 1 : -1).map((news) => {
                        return (
                            <Button key={news.id} id={news.id} onClick={(e) => handleNews(e, news)}><span><Icon.Book size={60} /></span>{news.name}</Button>
                        )
                    })}
                    </div>
                )}
                {manageNews && (
                    <ManageNews selectedNews={selectedNews} newsData={news} newsError={newsError} newsIsError={newsIsError} newsIsLoading={newsIsLoading}/>
                )}
            </div>

            <Modal
                size="lg"
                show={showAddNewsDialog}
                onHide={() => setShowAddNewsDialog(false)}
                aria-labelledby="modal-add-news"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-news">Add News</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAddNewsSubmit}>
                        <Form.Group className="mb-3" controlId="formNewsName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control required type="text" maxLength="50" placeholder="Enter a news name" />
                            <Form.Control.Feedback type="invalid">Please enter a news name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formNewsTag">
                            <Form.Label>Tag</Form.Label>
                            <Form.Control required type="text" maxLength="50" placeholder="Enter tags" />
                            <Form.Control.Feedback type="invalid">Please enter a news tags.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formNewsPinned">
                            <Form.Label>Pinned</Form.Label>
                            <Form.Check type="switch" onChange={(e) => {setPinned(!pinned);}} />
                        </Form.Group>
                        <br/><br/>
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={handleAddNewsClose}>Cancel</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
}
