import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from "@fullcalendar/interaction"
import { Link } from 'react-router-dom';
import * as Icon from 'react-bootstrap-icons';
import { useScheduledBoardListCards } from 'hooks/boards.hook';
import { date, floatWithoutComma, currency, dateAppendTime, time24to12_2d, time, guid } from 'helpers/formatter';
import { useDeleteBoardListCardHome, useEditBoardListCard, useBoards, useAddBoardListCard, useEditBoardListCardNetworks} from 'hooks/boards.hook';
import { useScheduledNetworks } from 'hooks/networks.hook';
import { useWarningInventories, useEditInventoryRecord, useDeleteInventoryRecord } from 'hooks/inventories.hook';

import { useNewsHighlights } from 'hooks/news.hook';
import { useDate } from 'hooks/date-time.hook';
import Autocomplete from "react-google-autocomplete";
import Multiselect from 'multiselect-react-dropdown';
import { ManageBoardNetworks } from 'sections/ManageBoardNetworks';

export { Home };

function Home() {
    const [validated, setValidated] = useState(false);
    const [showAddBoardListCardDialog, setShowAddBoardListCardDialog] = useState(false);
    const [showEditBoardListCardDialog, setShowEditBoardListCardDialog] = useState(false);
    const [showBoardViewerDialog, setShowBoardViewerDialog] = useState(false);
    const [selectedBoardListCard, setSelectedBoardListCard] = useState(0);
    const [boardListCardComplete, setBoardListCardComplete] = useState(false);
    const [boardListCardNotify, setBoardListCardNotify] = useState(false);
    const [boardListCardShareable, setBoardListCardShareable] = useState(false);
    const [cardPrice, setCardPrice] = useState("");
    const [location, setLocation] = useState("");
    const [boardListCardRepeat, setBoardListCardRepeat] = useState(false);
    const [boardListCardRepeatRemind, setBoardListCardRepeatRemind] = useState(false);
    const [showDeleteBoardListCardDialog, setShowDeleteBoardListCardDialog] = useState(false);
    const [showNetworkDialog, setShowNetworkDialog] = useState(false);
    const [showRepeat, setShowRepeat] = useState(false);
    const [showRepeatRemind, setShowRepeatRemind] = useState(false);
    const [dateClickStart, setDateClickStart] = useState(null);
    const [timeClickStart, setTimeClickStart] = useState(null);
    const [latitude, setLatitude] = useState(0);
    const [longitude, setLongitude] = useState(0);
    const [showEditInventoryRecordDialog, setShowEditInventoryRecordDialog] = useState(false);
    const [showDeleteInventoryRecordDialog, setShowDeleteInventoryRecordDialog] = useState(false);
    const [selectedInventoryRecord, setSelectedInventoryRecord] = useState(0);

    const handleAddBoardListCardClose = () => setShowAddBoardListCardDialog(false);
    const handleEditBoardListCardClose = () => setShowEditBoardListCardDialog(false);
    const handleDeleteBoardListCardClose = () => setShowDeleteBoardListCardDialog(false);
    const handleEditInventoryRecordClose = () => { setShowEditInventoryRecordDialog(false); }
    const handleDeleteInventoryRecordClose = () => setShowDeleteInventoryRecordDialog(false);

    const [selectedBoard, setSelectedBoard] = useState({});
    const [selectedBoardList, setSelectedBoardList] = useState({});

    const [boardLists, setBoardLists] = useState([]);

    const [boardListCardsFiltered, setBoardListCardsFiltered] = useState([]);
    const [boardListCards, setBoardListCards] = useState([]);
    const [events, setEvents] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [inventories, setInventories] = useState([]);

    const editInventoryRecordMutation = useEditInventoryRecord();
    const deleteInventoryRecordMutation = useDeleteInventoryRecord();

    const [networkBirthDates, setNetworkBirthDates] = useState([]);
    const [networkAnniversaries, setNetworkAnniversaries] = useState([]);
    const [selectedNetwork, setSelectedNetwork] = useState({});
    const [isBirthdate, setIsBirthdate] = useState(false);
    const [isAnniversary, setIsAnniversary] = useState(false);
    const [newsHighlights, setNewsHighlights] = useState([]);

    const [boardListCardNetworks, setBoardListCardNetworks] = useState([]);
    const [boardListCardNetworksSelectedList, setBoardListCardNetworksSelectedList] = useState([]);
    const [selectedNetworks, setSelectedNetworks] = useState([]);
    const handlerNetworksOnSelect = (selectedList, selectedItem) => {
      let selectedNetworkIds = selectedList.map(item => { return item.id; });
      setSelectedNetworks(selectedNetworkIds);
      setBoardListCardNetworks(networks.filter(i => selectedNetworkIds.includes(i.id)));
      setBoardListCardNetworksSelectedList(selectedList.map(item => { return {"networkId":item.id}}));
    }

    const { data: boardsData } = useBoards();
    const [boards, setBoards] = useState([]);
    useEffect(() => { setBoards(boardsData); }, [boardsData]);

    const { data: inventoriesData, isLoading: inventoriesIsLoading } = useWarningInventories();
    useEffect(() => { 
        if (inventoriesData !== undefined) {
            setInventories(inventoriesData);
        }
    }, [inventoriesData]);

    const { data: newsData, isLoading: newsHiighlightsIsLoading  } = useNewsHighlights();
    useEffect(() => { setNewsHighlights(newsData);}, [newsData]);

    const { data: networksData } = useScheduledNetworks();
    useEffect(() => { 
        if (networksData !== undefined) {
            setNetworks(networksData.sort((a, b) => a.lastName > b.lastName ? 1 : -1).sort((a, b) => a.firstName > b.firstName ? 1 : -1));
        }
    }, [networksData]);
    const { data: boardListCardsData, isError: boardListCardsIsError, error: boardListCardsError, isLoading: boardListCardsIsLoading } = useScheduledBoardListCards();
    useEffect(() => {
        if (boardListCardsData !== undefined && networksData !== undefined) {
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 2);

            setBoardListCards(boardListCardsData);
            let cards = boardListCardsData.filter(e => { return ((new Date(e.dateStart) > yesterday || e.repeatRemind) && (new Date(e.dateStart).toString().includes(new Date().getFullYear()) || new Date(e.dateStart).toString().includes(new Date().getFullYear() + 1))) && (e.repeatRemind || !e.repeat) }).sort((a, b) => a.dateStart < b.dateStart ? 1 : -1,);
            let cardsNoDuplicates = cards.reduce((arr, item) => {
                const filtered = arr.filter(i => i['id'] !== item['id']);
                return [...filtered, item];
            }, []);
            setBoardListCardsFiltered(cardsNoDuplicates.sort((a, b) => a.dateStart > b.dateStart ? 1 : -1,));

            if (events.length === 0) {
                for (var i in boardListCardsData) {
                    var isWithinEndDate = false;
                    if (boardListCardsData[i].dateEnd !== undefined && !boardListCardsData[i].dateEnd.includes('1900-01-01') && new Date(boardListCardsData[i].dateEnd) > new Date().getDate() - 60) {
                        isWithinEndDate = true;
                    }
                    if (new Date(date(boardListCardsData[i].dateStart)) >= new Date("1900-01-02") || isWithinEndDate || boardListCardsData[i].repeat === true) {
                        var dateEnd = boardListCardsData[i].dateEnd < boardListCardsData[i].dateStart ? boardListCardsData[i].dateStart : boardListCardsData[i].dateEnd;
                        var dateStart = boardListCardsData[i].dateStart;
                        var dtStart = new Date(boardListCardsData[i].dateStart);
                        var dtEnd = new Date(dateEnd);
                        if (boardListCardsData[i].dateEnd !== undefined && !boardListCardsData[i].dateEnd.includes('1900-01-01')) {
                            dtEnd.setDate(dtEnd.getDate() + 1);
                        }
                        if (dtStart.getHours() === 0 && dtStart.getMinutes() === 0 && dtStart.getSeconds() === 0) {
                            dateStart = date(boardListCardsData[i].dateStart);
                        }
                        if (dtEnd.getHours() === 0 && dtEnd.getMinutes() === 0 && dtEnd.getSeconds() === 0) {
                            dateEnd = date(dtEnd);
                        }
                        if (boardListCardsData[i].repeat) {
                            events.push({ 'id': boardListCardsData[i].id, 'start': dateStart, 'end': dateEnd, 'title': boardListCardsData[i].name, 'description': boardListCardsData[i].description, 'color': '#0d6efd' });
                        } else {
                            events.push({ 'id': boardListCardsData[i].id, 'start': dateStart, 'end': dateEnd, 'title': boardListCardsData[i].name, 'description': boardListCardsData[i].description});
                        }
                    }
                }

                let networkBirthDatesData = networksData.filter(e => { return new Date(date(e.birthDate)) >= new Date("1900-01-02") });
                let networkAnniversariesData = networksData.filter(e => { return new Date(date(e.anniversary)) >= new Date("1900-01-02") });
                setNetworkBirthDates(networkBirthDatesData);
                setNetworkAnniversaries(networkAnniversariesData);

                let thisYear = new Date();
                for (var k in networkBirthDatesData) {
                    let d1 = new Date(date(networkBirthDatesData[k].birthDate));
                    let d2 = new Date(date(networkBirthDatesData[k].birthDate));
                    d1.setFullYear(thisYear.getFullYear());
                    d1.setDate(d1.getDate() + 1);
                    d2.setFullYear(thisYear.getFullYear() + 1);
                    d2.setDate(d2.getDate() + 1);
                    events.push({ 'id': networkBirthDatesData[k].id, 'start': date(d1), 'end': date(d1), 'title': networkBirthDatesData[k].firstName + " " + networkBirthDatesData[k].lastName + "'s Birthday", 'description': "BirthDate", 'color': '#45317a' });
                    events.push({ 'id': networkBirthDatesData[k].id, 'start': date(d2), 'end': date(d2), 'title': networkBirthDatesData[k].firstName + " " + networkBirthDatesData[k].lastName + "'s Birthday", 'description': "BirthDate", 'color': '#45317a' });
                }
                for (var j in networkAnniversariesData) {
                    let d1 = new Date(date(networkAnniversariesData[j].anniversary));
                    let d2 = new Date(date(networkAnniversariesData[j].anniversary));
                    d1.setFullYear(thisYear.getFullYear());
                    d1.setDate(d1.getDate() + 1);
                    d2.setFullYear(thisYear.getFullYear() + 1);
                    d2.setDate(d2.getDate() + 1);
                    events.push({ 'id': networkAnniversariesData[j].id, 'start': date(d1), 'end': date(d1), 'title': networkAnniversariesData[j].firstName + " " + networkAnniversariesData[j].lastName + "'s Anniversary", 'description': "Anniversary", 'color': '#b91946' });
                    events.push({ 'id': networkAnniversariesData[j].id, 'start': date(d2), 'end': date(d2), 'title': networkAnniversariesData[j].firstName + " " + networkAnniversariesData[j].lastName + "'s Anniversary", 'description': "Anniversary", 'color': '#b91946' });
                }
            }
        }
    }, [boardListCardsData],[networksData]);

    const { currentDate, currentTime, wish } = useDate();
    const deleteBoardListCardMutation = useDeleteBoardListCardHome();
    const addBoardListCardMutation = useAddBoardListCard();
    const editBoardListCardMutation = useEditBoardListCard();
    const editBoardListCardNetworksMutation = useEditBoardListCardNetworks();

    const handleInventoryRecordDelete = () => {
        deleteInventoryRecordMutation.mutate({
          id: selectedInventoryRecord.id,
        });
        setShowDeleteInventoryRecordDialog(false);
        setShowEditInventoryRecordDialog(false);
      };
    const handleEditInventoryRecordSubmit = (event) => {
        const form = event.currentTarget;
        let lat = parseFloat(latitude);
        let lng = parseFloat(longitude);
        
        if (form.checkValidity() === true) {
          editInventoryRecordMutation.mutate({
            id: selectedInventoryRecord.id,
            inventoryId: selectedInventoryRecord.inventoryId,
            name: form.formInventoryRecordName.value,
            detail: form.formInventoryRecordDetail.value,
            count: form.formInventoryRecordCount.value,
            min: form.formInventoryRecordMin.value,
            max: form.formInventoryRecordMax.value,
            address: location,
            latitude: parseInt(lat * 10000000),
            longitude: parseInt(lng * 10000000),
          });
    
          setShowEditInventoryRecordDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const editInventoryRecordOnClick = (inventoryRecord) => {
        if (inventoryRecord != null) {
          setSelectedInventoryRecord(inventoryRecord);
          setLocation(inventoryRecord.address);
          setLatitude(inventoryRecord.latitude/10000000);
          setLongitude(inventoryRecord.longitude/10000000);
          setShowEditInventoryRecordDialog(true);
        }
      };

    const handleInviteDetails = (e) => {
        editBoardListCardNetworksMutation.mutate({
            id: selectedBoardListCard.id,
            networkIds: selectedNetworks,
        });
        setTimeout(function() {
            setShowBoardViewerDialog(!showBoardViewerDialog);
        }, 1000);

        e.preventDefault(); 
        e.stopPropagation();
    }

    const handleBoardListCardDelete = () => {
        deleteBoardListCardMutation.mutate({
            id: selectedBoardListCard.id,
        });

        setTimeout(()=>{ window.location.reload(false); },500);

        setShowDeleteBoardListCardDialog(false);
        setShowEditBoardListCardDialog(false);
    };

    const handleEditBoardListCardSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {

            let formBoardListCardPrice = 0;
            let formBoardListCardDateStart = date(new Date(1900, 0, 1));
            let formBoardListCardDateEnd = date(new Date(1900, 0, 1));
            let repeat = boardListCardRepeat;
            let repeatRemind = boardListCardRepeatRemind;
            let lat = parseFloat(latitude);
            let lng = parseFloat(longitude);

            if (form.formBoardListCardPrice.value.length > 0) { formBoardListCardPrice = floatWithoutComma(form.formBoardListCardPrice.value) }
            if (form.formBoardListCardDateStart.value.length > 0) { formBoardListCardDateStart = form.formBoardListCardDateStart.value }
            else { setBoardListCardRepeat(false); setBoardListCardRepeatRemind(false); repeat = false; repeatRemind = false; }
            if (form.formBoardListCardDateEnd.value.length > 0) { formBoardListCardDateEnd = form.formBoardListCardDateEnd.value }
            let timeStart = form.formBoardListCardDateStartTime.value;
            let timeEnd = form.formBoardListCardDateEndTime.value;

            editBoardListCardMutation.mutate({
                id: selectedBoardListCard.id,
                boardListId: selectedBoardList.id,
                name: form.formBoardListCardName.value,
                description: form.formBoardListCardDescription.value,
                dateStart: formBoardListCardDateStart,
                dateEnd: formBoardListCardDateEnd,
                timeStart: form.formBoardListCardDateStartTime.value,
                timeEnd: form.formBoardListCardDateEndTime.value,
                price: formBoardListCardPrice,
                location: location,
                latitude: parseInt(lat * 10000000),
                longitude: parseInt(lng * 10000000),
                complete: boardListCardComplete,
                notify: boardListCardNotify,
                shareable: boardListCardShareable,
                repeat: repeat,
                repeatRemind: repeatRemind,
            });

            var dateEndFix = new Date(date(formBoardListCardDateEnd));
            var dateStartFix = new Date(date(formBoardListCardDateStart));
            
            let dateEnd = date(formBoardListCardDateEnd) < date(formBoardListCardDateStart) ? date(formBoardListCardDateStart) : date(dateEndFix.setDate(dateEndFix.getDate() + 3));
            let dateStart = date(dateStartFix.setDate(dateStartFix.getDate() + 2));
            const d = new Date();
            const newEvents = events.map((e) => {
                if (e.id === selectedBoardListCard.id && e.start.includes(dateStartFix.getFullYear().toString())) {
                    if (dateStartFix.getFullYear() < d.getFullYear() && repeat) {
                        dateStart = dateStart.replace(dateStartFix.getFullYear().toString(), d.getFullYear().toString());
                    }
                    if (timeStart.length > 0) { dateStart += "T"+timeStart; }
                    if (timeEnd.length > 0) { dateEnd += "T"+timeEnd; }
                    return { ...e, id: selectedBoardListCard.id, start: dateStart, end: dateEnd, title: form.formBoardListCardName.value, description: form.formBoardListCardDescription.value };
                }
                else if (e.id === selectedBoardListCard.id && e.start.includes(d.getFullYear().toString())) {
                    dateStart = dateStart.replace(d.getFullYear().toString(), dateStartFix.getFullYear().toString());
                    if (timeStart.length > 0) { dateStart += "T"+timeStart; }
                    if (timeEnd.length > 0) { dateEnd += "T"+timeEnd; }
                    return { ...e, id: selectedBoardListCard.id, start: dateStart, end: dateEnd, title: form.formBoardListCardName.value, description: form.formBoardListCardDescription.value };
                }
                else {
                    return e;
                }
            });

            setEvents([]);
            setTimeout(function () {
                setEvents(newEvents);
            }, 500);

            let dateStart2 = date(dateStartFix.setDate(dateStartFix.getDate()));
            let dateEnd2 = date(formBoardListCardDateEnd) < date(formBoardListCardDateStart) ? date(formBoardListCardDateStart) : date(dateEndFix.setDate(dateEndFix.getDate()));

            if (dateStartFix.getFullYear() < d.getFullYear() && repeat) {
                dateStart2 = dateStart2.replace(dateStartFix.getFullYear().toString(), d.getFullYear().toString());
            }

            if (dateEndFix < dateStartFix) {
                dateEnd2 = "1900-01-01T00:00:00";
            } else {
                dateEnd2 += dateAppendTime(form.formBoardListCardDateEndTime.value);
            }

            dateStart2 += dateAppendTime(form.formBoardListCardDateStartTime.value);

            let dateStartOriginal = dateStart2;
            if (repeat) {
                dateStartOriginal = date(dateStartFix.setDate(dateStartFix.getDate()));
                dateStartOriginal += dateAppendTime(form.formBoardListCardDateStartTime.value);
            }

            let newBoardListCards = [...boardListCards.filter(e => !e.id.includes(selectedBoardListCard.id)), {
                id: selectedBoardListCard.id,
                boardListId: selectedBoardList.id,
                name: form.formBoardListCardName.value,
                description: form.formBoardListCardDescription.value,
                dateStart: dateStartOriginal,
                dateEnd: dateEnd2,
                price: formBoardListCardPrice,
                complete: boardListCardComplete,
                notify: boardListCardNotify,
                shareable: boardListCardShareable,
                location: location,
                latitude: parseInt(lat * 10000000),
                longitude: parseInt(lng * 10000000),
                repeat: repeat,
                repeatRemind: repeatRemind,
                dateStartOriginal: dateStartOriginal,
                boardListCardNetworks: boardListCardNetworksSelectedList,
            }].sort((a, b) => a.dateStart > b.dateStart ? 1 : -1,);

            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 2);
            let cards = newBoardListCards.filter(e => { return ((new Date(e.dateStart) > yesterday || e.repeatRemind) && (new Date(e.dateStart).toString().includes(new Date().getFullYear()) || new Date(e.dateStart).toString().includes(new Date().getFullYear() + 1))) && (e.repeatRemind || !e.repeat) }).sort((a, b) => a.dateStart < b.dateStart ? 1 : -1,);
            let cardsNoDuplicates = cards.reduce((arr, item) => {
                const filtered = arr.filter(i => i['id'] !== item['id']);
                return [...filtered, item];
            }, []);

            setBoardListCardsFiltered(cardsNoDuplicates.sort((a, b) => a.dateStart > b.dateStart ? 1 : -1,));
            setBoardListCards(newBoardListCards);

            setTimeout(function() {
                editBoardListCardNetworksMutation.mutate({
                  id: selectedBoardListCard.id,
                  networkIds: selectedNetworks,
                });
              }, 500);

            setShowEditBoardListCardDialog(false);
            setValidated(false);
        }
        else {
            setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleAddBoardListCardSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
            const id = guid();
            let formBoardListCardPrice = 0;
            let formBoardListCardDateStart = date(new Date(1900, 0, 1));
            let formBoardListCardDateEnd = date(new Date(1900, 0, 1));
            let repeat = boardListCardRepeat;
            let repeatRemind = boardListCardRepeatRemind;
            let lat = parseFloat(latitude);
            let lng = parseFloat(longitude);

            if (form.formBoardListCardPrice.value.length > 0) { formBoardListCardPrice = floatWithoutComma(form.formBoardListCardPrice.value) }
            if (form.formBoardListCardDateStart.value.length > 0) { formBoardListCardDateStart = form.formBoardListCardDateStart.value }
            else { setBoardListCardRepeat(false); setBoardListCardRepeatRemind(false); repeat = false; repeatRemind = false; }
            if (form.formBoardListCardDateEnd.value.length > 0) { formBoardListCardDateEnd = form.formBoardListCardDateEnd.value }
            
            addBoardListCardMutation.mutate({
                id: id,
                boardListId: selectedBoardList.id,
                name: form.formBoardListCardName.value,
                description: form.formBoardListCardDescription.value,
                dateStart: formBoardListCardDateStart,
                dateEnd: formBoardListCardDateEnd,
                timeStart: form.formBoardListCardDateStartTime.value,
                timeEnd: form.formBoardListCardDateEndTime.value,
                price: formBoardListCardPrice,
                location: location,
                latitude: parseInt(lat * 10000000),
                longitude: parseInt(lng * 10000000),
                complete: boardListCardComplete,
                notify: boardListCardNotify,
                shareable: boardListCardShareable,
                repeat: repeat,
                repeatRemind: repeatRemind,
            });

            setTimeout(function() {
                editBoardListCardNetworksMutation.mutate({
                    id: id,
                    networkIds: selectedNetworks,
                });
            }, 500);

            setTimeout(()=>{ window.location.reload(false); },500);
        }
        else {
            setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const viewCardOnClick = (e) => {

        if (e.event !== undefined) {
            e = { 'dateStart': e.event.start, 'dateEnd': e.event.end, 'name': e.event.title, 'description': e.event._def.extendedProps.description, 'id': e.event.id };
        }
        let card = boardListCards.filter(boardListCard => boardListCard.id === e.id)[0];

        if (card !== undefined) {
            card.dateStart = card.dateStartOriginal;
            setSelectedBoardListCard(card);
            setLocation(card.location);
            setCardPrice(currency(card.price));
            setBoardListCardComplete(card.complete);
            setBoardListCardNotify(card.notify);
            setBoardListCardShareable(card.shareable);
            setShowEditBoardListCardDialog(true);
            setBoardListCardRepeat(card.repeat);
            setBoardListCardRepeatRemind(card.repeatRemind);
            setLatitude(card.latitude/10000000);
            setLongitude(card.longitude/10000000);

            let parentBoard = boards.filter(b => b.boardLists.filter(z => z.boardId === b.id && z.id === card.boardListId).length > 0)[0];
            setSelectedBoard(parentBoard);
            setBoardLists(parentBoard.boardLists);
            setSelectedBoardList(parentBoard.boardLists.filter(b => b.id === card.boardListId)[0]);

            setBoardListCardNetworksSelectedList(card.boardListCardNetworks);

            let selectedNetworkIds = card.boardListCardNetworks.map(item => { return item.networkId; });
            setSelectedNetworks(selectedNetworkIds);
            setBoardListCardNetworks(networks.filter(i => selectedNetworkIds.includes(i.id)));

            if (!card.dateStart.includes("1900-01-01")) {
                setShowRepeat(true);
            }
            else {
                setShowRepeat(false);
            }
            if (card.repeat) {
                setShowRepeatRemind(true);
            }
            else {
                setShowRepeatRemind(false);
            }
        }
        else {
            setLatitude(0);
            setLongitude(0);
            setIsBirthdate(false);
            setIsAnniversary(false);
            let networkBirthDate = networkBirthDates.filter(n => n.id === e.id)[0];
            if (networkBirthDate !== undefined && e.description === "BirthDate") {
                setIsBirthdate(true);
                setSelectedNetwork(networkBirthDate);
                setShowNetworkDialog(true);
            }
            else {
                let networkAnniversary = networkAnniversaries.filter(n => n.id === e.id)[0];
                if (networkAnniversary !== undefined &&  e.description === "Anniversary") {
                    setIsAnniversary(true);
                    setSelectedNetwork(networkAnniversary);
                    setShowNetworkDialog(true);
                }
            }
        }
    }

    const blurCardPrice = e => {
        if (e.target.value !== "") {
            let result = parseFloat(floatWithoutComma(e.target.value));
            if (!isNaN(result)) { setCardPrice(currency(result)); }
            else { setCardPrice(""); }
        }
    }

    const onChangeCardPrice = e => {
        setCardPrice(e.target.value);
    }

    const onChangeToggleRepeatGroup = e => {
        if (e.target.value.length > 0) {
            setShowRepeat(true);
        }
        else {
            setShowRepeat(false);
            setBoardListCardRepeat(false);
            setBoardListCardRepeatRemind(false);
        }
    }

    const handleDateClick = (e) => {
        setValidated(false);
        setLocation("");
        setCardPrice("");
        setBoardLists(null);
        setSelectedBoardList(null);
        setDateClickStart(date(e.date));
        if (time(e.date).includes("00:00")) {
            setTimeClickStart("");
        }
        else {
            setTimeClickStart(time(e.date));
        }
        setShowRepeat(true);
        setShowAddBoardListCardDialog(true);
    }
    const formatTrProps = (state = {}) => {
        return { onClick: () => viewCardOnClick(state.original) };
    };
    const formatTrInventoryProps = (state = {}) => {
        return { onClick: () => editInventoryRecordOnClick(state.original) 

        };
    };
    const columns = useMemo(() => [
        {
            Header: "Board List Card",
            accessor: "name",
        },
        {
            Header: "Notify",
            accessor: "notify",
            Cell: ({ row }) => (
                <>{row.original.notify && <><Icon.CheckLg size={24}/></>}</>
            )
        },
        {
            Header: "Date",
            accessor: "dateStart",
            Cell: ({ row }) => (
                date(row.original.dateStartOriginal)
            )
        },
        {
            Header: "Time",
            accessor: "timeStart",
            Cell: ({ row }) => (
                <div>
                    {!row.original.dateStartOriginal.includes("00:00:00") && (time24to12_2d(row.original.dateStartOriginal))}
                </div>
            )
        }
    ], []);

    const columnsInventory = useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
            Cell: ({ row }) => (
                <span className={row.original.count === row.original.min ? "inventory-warning" : "inventory-danger"}>{row.original.name}</span>
            )
        },
        {
            Header: "Inventory",
            accessor: "inventoryName",
            Cell: ({ row }) => (
                <span className={row.original.count === row.original.min ? "inventory-warning" : "inventory-danger"}>{row.original.inventoryName}</span>
            )
        },
        {
            Header: "Count",
            accessor: "count",
            Cell: ({ row }) => (
                <span className={row.original.count === row.original.min ? "inventory-warning" : "inventory-danger"}>{row.original.count}</span>
            )
        },
        {
            Header: "Min",
            accessor: "min",
            Cell: ({ row }) => (
                <span className={row.original.count === row.original.min ? "inventory-warning" : "inventory-danger"}>{row.original.min}</span>
            )
        }
    ], []);

    return (
        <div>
            <div className='text-center panel'>
                <h6>{currentDate}</h6>
                <h1>{currentTime}</h1>
                <h5>{wish}{JSON.parse(localStorage.getItem('user')).firstName}</h5>
                <div className='home-nav'>
                    <Link to="/accounts"><span><Icon.Coin size={60} /></span>Accounts</Link>
                    <Link to="/budget"><span><Icon.PiggyBank size={60} /></span>Budget</Link>
                    <Link to="/boards"><span><Icon.CardChecklist size={60} /></span>Boards</Link>
                    <Link to="/network"><span><Icon.People size={60} /></span>Network</Link>
                    <Link to="/vaults"><span><Icon.Lock size={60} /></span>Vaults</Link>
                    <Link to="/inventory"><span><Icon.ClipboardCheck size={60} /></span>Inventory</Link>
                    <Link to="/news"><span><Icon.Book size={60} /></span>News</Link>
                </div>
                <p>&nbsp;</p>
                <hr/>
            </div>
            {boardListCardsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
            {events && events.length > 0 &&
                <div className='calendar-container'>
                    <h2 className='text-center'><Icon.Calendar3 size={28}/> Calendar</h2>
                    <p>&nbsp;</p>
                    <div className='row'>
                        <div className='col-lg-8 text-left'>
                            <FullCalendar
                                headerToolbar={{
                                    start: "today prev next",
                                    end: "dayGridMonth timeGridWeek timeGridDay",
                                    center: "title"
                                }}
                                events={events}
                                plugins={[daygridPlugin,interactionPlugin, timeGridPlugin]}
                                views={["dayGridMonth", "dayGridWeek"]}
                                eventClick={(e) => viewCardOnClick(e)}
                                dateClick={handleDateClick}
                            />
                            <p>&nbsp;</p>
                        </div>
                        <div className='col-lg-4'>
                            {boardListCardsFiltered && <TableBasicClick responsive columns={columns} data={boardListCardsFiltered} formatRowProps={(state) => formatTrProps(state)} />}
                            {boardListCardsIsError && <div className="text-danger">Error loading cards: {boardListCardsError.message}</div>}
                        </div>
                    </div>
                </div>
            }

            {inventoriesIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
            {inventories && inventories.length > 0 &&
                <div className='panel'>
                    <hr></hr>
                    <br/>
                    <h2 className='text-center'><Icon.ClipboardCheck size={28}/> Inventory Warnings</h2>
                    <p>&nbsp;</p>
                    <TableBasicClick responsive columns={columnsInventory} data={inventories} formatRowProps={(state) => formatTrInventoryProps(state)} />
                </div>
            }

            {newsHiighlightsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
            {newsHighlights && newsHighlights.length > 0 &&
                <div className='panel'>
                    <hr></hr>
                    <br/>
                    <h2 className='text-center'><Icon.Book size={28}/> News Highlights</h2>
                    <p>&nbsp;</p>
                        {newsHighlights.map((item, index) => (
                            <a key={index} className='news div-center' href={item.link} target='_blank' rel="noreferrer">
                                <span className='news-title'>{item.title}</span>
                                <hr/>
                                <span className='news-date'>{date(item.pubDate)} - {item.category} - {item.source}</span>
                                {item.imageUrl !== null && <span className='news-img' style={{"backgroundImage":`url(${item.imageUrl})`}} />  }
                                <span className='news-description'>{item.description}</span>
                            </a>
                        ))}
                </div>
            }
            <Modal
                size="lg"
                show={showEditBoardListCardDialog}
                onHide={() => setShowEditBoardListCardDialog(false)}
                aria-labelledby="modal-edit-board-list-card"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-edit-board-list-card">Edit Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Link className="btn-card-viewer" to={`/view/${selectedBoardListCard.id}`} target='_blank'><Icon.WindowFullscreen size={20} /></Link>
                    <Form noValidate validated={validated} onSubmit={handleEditBoardListCardSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Board</Form.Label>
                            <Form.Control type="text" readOnly defaultValue={selectedBoard.name} />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Board List</Form.Label>
                            <Form.Select defaultValue={selectedBoardList && selectedBoardList.id} onChange={(e) => { setSelectedBoardList(boardLists.filter(i => i !== null && i.id === e.target.value)[0]); }}>
                                {boardLists && (boardLists.map((m) => { return <option key={m.id} value={m.id}>{m.name}</option> }))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBoardListCardName">
                            <Form.Label>Card Name</Form.Label>
                            <Form.Control required type="text" maxLength="100" placeholder="Enter a card name" defaultValue={selectedBoardListCard.name} />
                            <Form.Control.Feedback type="invalid">Please enter a card name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBoardListCardDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={6} maxLength="4000" placeholder="Enter a description" defaultValue={selectedBoardListCard.description} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBoardListCardPrice">
                            <Form.Label>Price</Form.Label>
                            <Form.Control type="text" value={cardPrice} autoComplete="off" onBlur={blurCardPrice} onChange={onChangeCardPrice} placeholder="Enter a price" />
                        </Form.Group>
                        <div className='row'>
                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStart">
                                <Form.Label>Start Date</Form.Label>
                                <Form.Control type="date" placeholder="Enter a start date" defaultValue={selectedBoardListCard.dateStart && !selectedBoardListCard.dateStart.includes("1900") && (date(selectedBoardListCard.dateStart))} onChange={onChangeToggleRepeatGroup} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStartTime">
                                <Form.Label>Start Time</Form.Label>
                                <Form.Control type="time" defaultValue={selectedBoardListCard.dateStart && !selectedBoardListCard.dateStart.includes("1900") && !selectedBoardListCard.dateStart.includes("00:00:00") && time(selectedBoardListCard.dateStart)} />
                            </Form.Group>
                        </div>
                        <div className='row'>
                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEnd">
                                <Form.Label>End Date</Form.Label>
                                <Form.Control type="date" placeholder="Enter an end date" defaultValue={selectedBoardListCard.dateEnd && !selectedBoardListCard.dateEnd.includes("1900") && (date(selectedBoardListCard.dateEnd))} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEndTime">
                                <Form.Label>End Time</Form.Label>
                                <Form.Control type="time" defaultValue={selectedBoardListCard.dateEnd && !selectedBoardListCard.dateEnd.includes("1900") && !selectedBoardListCard.dateEnd.includes("00:00:00") && time(selectedBoardListCard.dateEnd)} />
                            </Form.Group>
                        </div>
                        <div className='row'>
                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardNotify">
                                <Form.Label>Send Notifications</Form.Label>
                                <Form.Check type="switch" defaultChecked={selectedBoardListCard.notify} onChange={(e) => { setBoardListCardNotify(!boardListCardNotify); }} />
                            </Form.Group>
                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardShareable">
                                <Form.Label>Shareable</Form.Label>
                                <Form.Check type="switch" defaultChecked={selectedBoardListCard.shareable} onChange={(e) => { setBoardListCardShareable(!boardListCardShareable); }} />
                            </Form.Group>
                        </div>
                        <br></br><p></p>
                        {showRepeat &&
                            <>
                                <div className='row'>
                                    <Form.Group className="mb-3 col-6" controlId="formBoardListCardRepeat">
                                        <Form.Label>Repeat Yearly</Form.Label>
                                        <Form.Check type="switch" defaultChecked={selectedBoardListCard.repeat} onChange={(e) => { setBoardListCardRepeat(!boardListCardRepeat); setShowRepeatRemind(!boardListCardRepeat); setBoardListCardRepeatRemind(false); }} />
                                    </Form.Group>
                                    {showRepeatRemind &&
                                        <Form.Group className="mb-3 col-6" controlId="formBoardListCardRepeatRemind">
                                            <Form.Label>Important</Form.Label>
                                            <Form.Check type="switch" defaultChecked={selectedBoardListCard.repeatRemind} onChange={(e) => { setBoardListCardRepeatRemind(!boardListCardRepeatRemind); }} />
                                        </Form.Group>
                                    }
                                </div>
                                <br></br><p></p>
                            </>
                        }
                        <Form.Group className="mb-3" controlId="formBoardListCardLocation">
                            <Form.Label>Location</Form.Label>
                            <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                onPlaceSelected={(place) => {
                                    setLatitude(place.geometry.location.lat());
                                    setLongitude(place.geometry.location.lng());
                                    setLocation(place.formatted_address);
                                }}
                                options={{
                                    types: ["address"],
                                    componentRestrictions: { country: "ca" },
                                }}
                                defaultValue={selectedBoardListCard.location}
                            />
                        </Form.Group>
                        {location && location.length > 0 && <div><iframe title="Map" style={{ width: "100%" }} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div>}
                        <p></p>
                        <Form.Group className="mb-3" controlId="formBoardListCardNetworks">
                            <Form.Label>Invite</Form.Label>
                            <Multiselect
                                displayValue="fullName"
                                placeholder=""
                                onKeyPressFn={function noRefCheck(){}}
                                onRemove={handlerNetworksOnSelect}
                                onSearch={function noRefCheck(){}}
                                onSelect={handlerNetworksOnSelect}
                                selectedValues={boardListCardNetworks.map(item => { return {"fullName": `${item.firstName} ${item.lastName}`, "id":item.id} })}
                                options={networks.filter(item => item.team === "").map(item => { return {"fullName": `${item.firstName} ${item.lastName}`, "id":item.id} })}
                            />
                        </Form.Group>
                        <p></p>
                        {selectedNetworks.length > 0 && <p><button className='btn btn-warning' onClick={handleInviteDetails}>Invites Details</button></p> }

                        <Form.Group className="mb-3" controlId="formBoardListCardComplete">
                            <Form.Label>Is Complete</Form.Label>
                            <Form.Check type="switch" defaultChecked={selectedBoardListCard.complete} onChange={(e) => { setBoardListCardComplete(!boardListCardComplete); }} />
                        </Form.Group>
                        <br /><br />
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={handleEditBoardListCardClose}>Cancel</Button>
                        <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteBoardListCardDialog(true)}>Delete</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showAddBoardListCardDialog}
                onHide={() => setShowAddBoardListCardDialog(false)}
                aria-labelledby="modal-add-board-list-card"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-board-list-card">Add Card</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAddBoardListCardSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Board</Form.Label>
                            <Form.Select onChange={(e) => { setBoardLists(boards.filter(i => i.id === e.target.value)[0].boardLists.sort((a, b) => a.name > b.name ? 1 : -1)); setSelectedBoardList(undefined); }}>
                                <option key={0} value={0}></option>
                                {boards && (boards.map((m) => { return <option key={m.id} value={m.id}>{m.name}</option> }))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Board List</Form.Label>
                            <Form.Select onChange={(e) => { setSelectedBoardList(boardLists.filter(i => i.id === e.target.value)[0]); }}>
                                <option key={0} value={0}></option>
                                {boardLists && (boardLists.map((m) => { return <option key={m.id} value={m.id}>{m.name}</option> }))}
                            </Form.Select>
                        </Form.Group>
                        {selectedBoardList && selectedBoardList.id !== undefined ? (
                        <>
                            <Form.Group className="mb-3" controlId="formBoardListCardName">
                                <Form.Label>Card Name</Form.Label>
                                <Form.Control required type="text" maxLength="100" placeholder="Enter a card name" />
                                <Form.Control.Feedback type="invalid">Please enter a card name.</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBoardListCardDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows={6} maxLength="4000" placeholder="Enter a description" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBoardListCardPrice">
                                <Form.Label>Price</Form.Label>
                                <Form.Control type="text" value={cardPrice} autoComplete="off" onBlur={blurCardPrice} onChange={onChangeCardPrice} placeholder="Enter a price" />
                            </Form.Group>
                            <div className='row'>
                                <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStart">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" placeholder="Enter a start date" defaultValue={dateClickStart} onChange={onChangeToggleRepeatGroup} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateStartTime">
                                    <Form.Label>Start Time</Form.Label>
                                    <Form.Control type="time" defaultValue={timeClickStart} />
                                </Form.Group>
                            </div>
                            <div className='row'>
                                <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEnd">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" placeholder="Enter an end date" />
                                </Form.Group>
                                <Form.Group className="mb-3 col-6" controlId="formBoardListCardDateEndTime">
                                    <Form.Label>End Time</Form.Label>
                                    <Form.Control type="time" />
                                </Form.Group>
                            </div>
                            <div className='row'>
                                <Form.Group className="mb-3 col-6" controlId="formBoardListCardNotify">
                                    <Form.Label>Send Notifications</Form.Label>
                                    <Form.Check type="switch" onChange={(e) => { setBoardListCardNotify(!boardListCardNotify); }} />
                                </Form.Group>
                                <Form.Group className="mb-3 col-6" controlId="formBoardListCardShareable">
                                    <Form.Label>Shareable</Form.Label>
                                    <Form.Check type="switch" onChange={(e) => { setBoardListCardShareable(!boardListCardShareable); }} />
                                </Form.Group>
                            </div>
                            <br></br><p></p>
                            {showRepeat &&
                                <>
                                    <div className='row'>
                                        <Form.Group className="mb-3 col-6" controlId="formBoardListCardRepeat">
                                            <Form.Label>Repeat Yearly</Form.Label>
                                            <Form.Check type="switch" onChange={(e) => { setBoardListCardRepeat(!boardListCardRepeat); setShowRepeatRemind(!boardListCardRepeat); setBoardListCardRepeatRemind(false); }} />
                                        </Form.Group>
                                        {showRepeatRemind &&
                                            <Form.Group className="mb-3 col-6" controlId="formBoardListCardRepeatRemind">
                                                <Form.Label>Important</Form.Label>
                                                <Form.Check type="switch" onChange={(e) => { setBoardListCardRepeatRemind(!boardListCardRepeatRemind); }} />
                                            </Form.Group>
                                        }
                                    </div>
                                    <br></br><p></p>
                                </>
                            }
                            <Form.Group className="mb-3" controlId="formBoardListCardLocation">
                                <Form.Label>Location</Form.Label>
                                <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                    onPlaceSelected={(place) => {
                                        setLatitude(place.geometry.location.lat());
                                        setLongitude(place.geometry.location.lng());
                                        setLocation(place.formatted_address);
                                    }}
                                    options={{
                                        types: ["address"],
                                        componentRestrictions: { country: "ca" },
                                    }}
                                />
                            </Form.Group>
                            {location && location.length > 0 && <div><iframe title="Map" style={{ width: "100%" }} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div>}
                            <Form.Group className="mb-3" controlId="formBoardListCardNetworks">
                                <Form.Label>Invites</Form.Label>
                                <Multiselect
                                    displayValue="fullName"
                                    placeholder=""
                                    onKeyPressFn={function noRefCheck(){}}
                                    onRemove={handlerNetworksOnSelect}
                                    onSearch={function noRefCheck(){}}
                                    onSelect={handlerNetworksOnSelect}
                                    options={networks.filter(item => item.team === "").map(item => { return {"fullName": `${item.firstName} ${item.lastName}`, "id":item.id} })}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBoardListCardComplete">
                                <Form.Label>Is Complete</Form.Label>
                                <Form.Check type="switch" onChange={(e) => { setBoardListCardComplete(!boardListCardComplete); }} />
                            </Form.Group>
                            <br /><br />
                            <Button variant="primary" type="submit">Save</Button>&nbsp;
                            <Button variant="secondary" type="button" onClick={handleAddBoardListCardClose}>Cancel</Button>
                        </>) : <></>}
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteBoardListCardDialog}
                onHide={() => setShowDeleteBoardListCardDialog(false)}
                aria-labelledby="modal-delete-board-list-card"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-delete-board-list-card">Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete:</p>
                    {showDeleteBoardListCardDialog && <div><p>{selectedBoardListCard.name}</p></div>}
                    <hr></hr>
                    <Button variant="danger" type="submit" onClick={handleBoardListCardDelete}>Yes</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleDeleteBoardListCardClose}>No</Button>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showNetworkDialog}
                onHide={() => setShowNetworkDialog(false)}
                aria-labelledby="modal-edit-network"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-edit-network">
                        <div>{selectedNetwork.firstName} {selectedNetwork.lastName}'s&nbsp;
                        {isBirthdate ? (<span>Birthday</span>) : (<></>)}
                        {isAnniversary ? (<span>Anniversary</span>) : (<></>)}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate>
                        <div>
                            <div className='detail-shortcuts'>
                                {selectedNetwork.phone && selectedNetwork.phone.length > 0 &&
                                    <span>
                                        <a href={"tel:" + selectedNetwork.phone} title={selectedNetwork.phone}><Icon.TelephoneFill size={26} /></a>
                                        <a href={"sms:" + selectedNetwork.phone} title={selectedNetwork.phone}><Icon.ChatLeftFill size={26} /></a>
                                        <a href={"https://wa.me/" + selectedNetwork.phone} rel="noreferrer" target='_blank' title={selectedNetwork.phone}><Icon.Whatsapp size={26} /></a>
                                    </span>
                                }
                                {selectedNetwork.email && selectedNetwork.email.length > 0 &&
                                    <a href={"mailto:" + selectedNetwork.email} title={selectedNetwork.email}><Icon.EnvelopeAtFill size={26} /></a>
                                }
                                {selectedNetwork.facebook && selectedNetwork.facebook.length > 0 &&
                                    <a href={"https://www.facebook.com/" + selectedNetwork.facebook} rel="noreferrer" title={selectedNetwork.facebook} target='_blank'><Icon.Facebook size={26} /></a>
                                }
                                {selectedNetwork.instagram && selectedNetwork.instagram.length > 0 &&
                                    <a href={"https://www.instagram.com/" + selectedNetwork.instagram} rel="noreferrer" title={selectedNetwork.instagram} target='_blank'><Icon.Instagram size={26} /></a>
                                }
                            </div>
                            {selectedNetwork.photoUrl && selectedNetwork.photoUrl.length === 0 ? (
                                <div className="detail-img-default"><Icon.Person size={150} /></div>
                            ) : (
                                <div className="detail-img" style={{ backgroundImage: "url(" + selectedNetwork.photoUrl + ")" }}></div>
                            )}

                            <p></p><hr />

                            <h1 className='text-center'>{selectedNetwork.firstName} {selectedNetwork.lastName}</h1>
                            <h4 className='text-center'>{selectedNetwork.position}</h4>
                            <h3 className='text-center' >
                                {isBirthdate && <>{new Date().getFullYear() - new Date(selectedNetwork.birthDate).getFullYear()} Years Old!</>  }
                                {isAnniversary && <>{new Date().getFullYear() - new Date(selectedNetwork.anniversary).getFullYear()} Years Married!</>  }
                            </h3>
                            <h5 className='text-center'>
                                {isBirthdate ? (<>{date(selectedNetwork.birthDate)}</>) : (<></>)}
                                {isAnniversary ? (<>{date(selectedNetwork.anniversary)}</>) : (<></>)}
                            </h5>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal
                size="xl"
                show={showBoardViewerDialog}
                onHide={() => setShowBoardViewerDialog(false)}
                aria-labelledby="modal-viewer"
            >
                <Modal.Header closeButton closeVariant='white'>
                <Modal.Title id="modal-viewer">Invites Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ManageBoardNetworks selectedBoardListCard={selectedBoardListCard} selectedNetworks={selectedNetworks} networks={networks} />
                </Modal.Body>
            </Modal>      

            <Modal
                size="lg"
                show={showEditInventoryRecordDialog}
                onHide={() => setShowEditInventoryRecordDialog(false)}
                aria-labelledby="modal-edit-inventory-record"
            >
                <Modal.Header closeButton closeVariant='white'>
                <Modal.Title id="modal-edit-inventory-record">Edit Inventory Record</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleEditInventoryRecordSubmit}>
                    <Form.Group className="mb-3" controlId="formInventoryRecordName">
                    <Form.Label>Inventory Name</Form.Label>
                    <Form.Control required type="text" maxLength="50" placeholder="Enter a inventory name" autoComplete="off" defaultValue={selectedInventoryRecord.name} />
                    <Form.Control.Feedback type="invalid">Please enter a inventory name.</Form.Control.Feedback>
                    </Form.Group>

                    <div className='row'>
                    <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordCount">
                        <Form.Label>Count</Form.Label>
                        <Form.Control required type="number" maxLength="1000000" placeholder="Enter an inventory record count" defaultValue={selectedInventoryRecord.count} autoComplete="off" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordMin">
                        <Form.Label>Min</Form.Label>
                        <Form.Control type="number" maxLength="1000000" placeholder="Enter an inventory record min" defaultValue={selectedInventoryRecord.min} autoComplete="off" />
                    </Form.Group>
                    <Form.Group className="mb-3 col-sm-4" controlId="formInventoryRecordMax">
                        <Form.Label>Max</Form.Label>
                        <Form.Control type="number" maxLength="1000000" placeholder="Enter an inventory record max" defaultValue={selectedInventoryRecord.max} autoComplete="off" />
                    </Form.Group>
                    </div>
                    <Form.Group className="mb-3" controlId="formInventoryRecordDetail">
                    <Form.Label>Detail</Form.Label>
                    <Form.Control as="textarea" rows={3} maxLength="400" placeholder="Enter detail" defaultValue={selectedInventoryRecord.detail} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formInventoryRecordLocation">
                    <Form.Label>Location</Form.Label>
                        <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        onPlaceSelected={(place) => {
                            setLatitude(place.geometry.location.lat());
                            setLongitude(place.geometry.location.lng());
                            setLocation(place.formatted_address);
                        }}
                        options={{
                            types: ["address"],
                            componentRestrictions: { country: "ca" },
                        }}
                        defaultValue={selectedInventoryRecord.address}
                    />          
                    </Form.Group>
                    {location && location.length > 0 && <div><iframe style={{width:"100%"}} src={'https://maps.google.com/maps?q=' + location.replace("#", "") + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe></div> }
                    <p></p>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleEditInventoryRecordClose}>Cancel</Button>
                    <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteInventoryRecordDialog(true)}>Delete</Button>
                </Form>
                </Modal.Body>
            </Modal>
            <Modal
                show={showDeleteInventoryRecordDialog}
                onHide={() => setShowDeleteInventoryRecordDialog(false)}
                aria-labelledby="modal-delete-inventory-record"
            >
                <Modal.Header closeButton closeVariant='white'>
                <Modal.Title id="modal-delete-inventory-record">Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <p>Are you sure you would like to delete:</p>
                {showDeleteInventoryRecordDialog && <div><p>{selectedInventoryRecord.name}</p></div>}
                <hr></hr>
                <Button variant="danger" type="submit" onClick={handleInventoryRecordDelete}>Yes</Button>&nbsp;
                <Button variant="secondary" type="button" onClick={handleDeleteInventoryRecordClose}>No</Button>
                </Modal.Body>
            </Modal>                                  
        </div>
    );
}
