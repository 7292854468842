import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import BTable from 'react-bootstrap/Table';
import { currency, calculateMortgagePayment, floatWithoutComma } from 'helpers/formatter';
import { useBudgetIncome, useBudgetExpenses, useAddBudget, useEditBudget, useDeleteBudget, useBudgetMortgages, useAddBudgetMortgage, useEditBudgetMortgage, useDeleteBudgetMortgage } from 'hooks/budgets.hook';
import * as Icon from 'react-bootstrap-icons';

export { Budget };

function Budget() {
    const [validated, setValidated] = useState(false);

    const [showAddIncomeDialog, setShowAddIncomeDialog] = useState(false);
    const [showEditIncomeDialog, setShowEditIncomeDialog] = useState(false);
    const [showDeleteIncomeDialog, setShowDeleteIncomeDialog] = useState(false);
    const [showAddExpenseDialog, setShowAddExpenseDialog] = useState(false);
    const [showEditExpenseDialog, setShowEditExpenseDialog] = useState(false);
    const [showDeleteExpenseDialog, setShowDeleteExpenseDialog] = useState(false);
    const [showAddMortgageDialog, setShowAddMortgageDialog] = useState(false);
    const [showEditMortgageDialog, setShowEditMortgageDialog] = useState(false);
    const [showDeleteMortgageDialog, setShowDeleteMortgageDialog] = useState(false);

    const [monthlyIncome, setMonthlyIncome] = useState(0);
    const [monthlyExpenses, setMonthlyExpenses] = useState(0);
    const [monthlyMortgages, setMonthlyMortgages] = useState(0);
    const [yearlyIncome, setYearlyIncome] = useState(0);
    const [yearlyExpenses, setYearlyExpenses] = useState(0);
    const [yearlyMortgages, setYearlyMortgages] = useState(0);

    const [incomeAmount, setIncomeAmount] = useState("");
    const [expenseAmount, setExpenseAmount] = useState("");
    const [mortgageHouseCost, setMortgageHouseCost] = useState("");
    const [mortgageDownPayment, setMortgageDownPayment] = useState("");
    const [mortgagePaidToDate, setMortgagePaidToDate] = useState("");
    const [mortgageRate, setMortgageRate] = useState("");
    const [mortgageMaintenance, setMortgageMaintenance] = useState("");
    const [mortgagePropertyTax, setMortgagePropertyTax] = useState("");

    const [selectedBudgetIncome, setSelectedBudgetIncome] = useState(0);
    const [selectedBudgetExpense, setSelectedBudgetExpense] = useState(0);
    const [selectedBudgetMortgage, setSelectedBudgetMortgage] = useState(0);

    const handleAddIncomeClose = () => setShowAddIncomeDialog(false);
    const handleEditIncomeClose = () => setShowEditIncomeDialog(false);
    const handleDeleteIncomeClose = () => setShowDeleteIncomeDialog(false);
    const handleAddExpenseClose = () => setShowAddExpenseDialog(false);
    const handleEditExpenseClose = () => setShowEditExpenseDialog(false);
    const handleDeleteExpenseClose = () => setShowDeleteExpenseDialog(false);
    const handleAddMortgageClose = () => setShowAddMortgageDialog(false);
    const handleEditMortgageClose = () => setShowEditMortgageDialog(false);
    const handleDeleteMortgageClose = () => setShowDeleteMortgageDialog(false);

    const addBudgetMutation = useAddBudget();
    const editBudgetMutation = useEditBudget();
    const deleteBudgetMutation = useDeleteBudget();
    const addMortgageMutation = useAddBudgetMortgage();
    const editMortgageMutation = useEditBudgetMortgage();
    const deleteMortgageMutation = useDeleteBudgetMortgage();

    const { data: budgetIncomeData, isLoading: budgetIncomeIsLoading } = useBudgetIncome();
    const [budgetIncome, setBudgetIncome] = useState([]);
    useEffect(() => { 
        setBudgetIncome(budgetIncomeData); 
        let total = 0;
        if (budgetIncomeData && budgetIncomeData.length > 0) {
            for (var i = 0; i < budgetIncomeData.length; i++ ) {
                if (budgetIncomeData[i].budgetTypeId === 2) { total += budgetIncomeData[i].amount / 12; }
                else if (budgetIncomeData[i].budgetTypeId === 3) { total += budgetIncomeData[i].amount * 2; }
                else if (budgetIncomeData[i].budgetTypeId === 4) { total += budgetIncomeData[i].amount * 26 / 12; }
                else if (budgetIncomeData[i].budgetTypeId === 5) { total += budgetIncomeData[i].amount * 52 / 12; }
                else { total += budgetIncomeData[i].amount; }
            }
            setMonthlyIncome(currency(total));
            setYearlyIncome(currency(total * 12));
        }
        else {
            setMonthlyIncome(currency(0));
            setYearlyIncome(currency(0));
        }
    }, [budgetIncomeData]);

    const { data: budgetExpenseData, isLoading: budgetExpenseIsLoading } = useBudgetExpenses();
    const [budgetExpense, setBudgetExpense] = useState([]);
    useEffect(() => { 
        setBudgetExpense(budgetExpenseData); 
        let total = 0;
        if (budgetExpenseData && budgetExpenseData.length > 0) {
            for (var i = 0; i < budgetExpenseData.length; i++ ) {
                if (budgetExpenseData[i].budgetTypeId === 2) { total += budgetExpenseData[i].amount / 12; }
                else if (budgetExpenseData[i].budgetTypeId === 3) { total += budgetExpenseData[i].amount * 2; }
                else if (budgetExpenseData[i].budgetTypeId === 4) { total += budgetExpenseData[i].amount * 26 / 12; }
                else if (budgetExpenseData[i].budgetTypeId === 5) { total += budgetExpenseData[i].amount * 52 / 12; }
                else { total += budgetExpenseData[i].amount; }
            }
            setMonthlyExpenses(currency(-1 * total));
            setYearlyExpenses(currency(-1 * total * 12));
        }
        else {
            setMonthlyExpenses(currency(0));
            setYearlyExpenses(currency(0));
        }
    }, [budgetExpenseData]);

    const { data: budgetMortgageData, isLoading: budgetMortgageIsLoading } = useBudgetMortgages();
    const [budgetMortgage, setBudgetMortgage] = useState([]);
    useEffect(() => { 
        setBudgetMortgage(budgetMortgageData); 
        let total = 0;
        if (budgetMortgageData && budgetMortgageData.length > 0) {
            for (var i = 0; i < budgetMortgageData.length; i++ ) {
                total += calculateMortgagePayment((budgetMortgageData[i].houseCost - budgetMortgageData[i].downPayment - budgetMortgageData[i].paidToDate), budgetMortgageData[i].rate, budgetMortgageData[i].timeFrame) + budgetMortgageData[i].propertyTax;
            }
            setMonthlyMortgages(currency(total));
            setYearlyMortgages(currency(total * 12));
        }
        else {
            setMonthlyMortgages(currency(0));
            setYearlyMortgages(currency(0));
        }
    }, [budgetMortgageData]);


    const handleDeleteMortgage = () => {
        deleteMortgageMutation.mutate({
          id: selectedBudgetMortgage.id,
        });
        setShowDeleteMortgageDialog(false);
        setShowEditMortgageDialog(false);
      };
    const handleEditMortgageSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          editMortgageMutation.mutate({
            id: selectedBudgetMortgage.id,
            name: form.formMortgageName.value,
            houseCost: floatWithoutComma(form.formMortgageHouseCost.value),
            downPayment: floatWithoutComma(form.formMortgageDownPayment.value),
            paidToDate: floatWithoutComma(form.formMortgagePaidToDate.value),
            rate: floatWithoutComma(form.formMortgageRate.value),
            maintenance: floatWithoutComma(form.formMortgageMaintenance.value),
            propertyTax: floatWithoutComma(form.formMortgagePropertyTax.value),
            timeFrame: form.formMortgageTimeFrame.value,
          });
    
          setShowEditMortgageDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };    

    const showAddMortgageDialogClick = () => {
        setShowAddMortgageDialog(true);
        setMortgageHouseCost("");
        setMortgageDownPayment("");
        setMortgagePaidToDate("");
        setMortgageRate("");
        setMortgageMaintenance("");
        setMortgagePropertyTax("");
    }
    const handleAddMortgageSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addMortgageMutation.mutate({
            name: form.formMortgageName.value,
            houseCost: floatWithoutComma(form.formMortgageHouseCost.value),
            downPayment: floatWithoutComma(form.formMortgageDownPayment.value),
            paidToDate: floatWithoutComma(form.formMortgagePaidToDate.value),
            rate: floatWithoutComma(form.formMortgageRate.value),
            maintenance: floatWithoutComma(form.formMortgageMaintenance.value),
            propertyTax: floatWithoutComma(form.formMortgagePropertyTax.value),
            timeFrame: form.formMortgageTimeFrame.value,
          });
    
          setShowAddMortgageDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    const handleDeleteIncome = () => {
        deleteBudgetMutation.mutate({
          id: selectedBudgetIncome.id,
        });
        setShowDeleteIncomeDialog(false);
        setShowEditIncomeDialog(false);
      };
    const handleEditIncomeSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          editBudgetMutation.mutate({
            id: selectedBudgetIncome.id,
            budgetTypeId: form.formBudgetType.value,
            amount: floatWithoutComma(form.formIncomeAmount.value),
            name: form.formIncomeName.value,
          });
    
          setShowEditIncomeDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };    

    const showAddIncomeDialogClick = () => {
        setShowAddIncomeDialog(true);
        setIncomeAmount("");
    }
    const handleAddIncomeSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addBudgetMutation.mutate({
            budgetTypeId: form.formBudgetType.value,
            amount: floatWithoutComma(form.formIncomeAmount.value),
            name: form.formIncomeName.value,
          });
    
          setShowAddIncomeDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };    

    const handleDeleteExpense = () => {
        deleteBudgetMutation.mutate({
          id: selectedBudgetExpense.id,
        });
        setShowDeleteExpenseDialog(false);
        setShowEditExpenseDialog(false);
      };
    const handleEditExpenseSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          editBudgetMutation.mutate({
            id: selectedBudgetExpense.id,
            budgetTypeId: form.formBudgetType.value,
            amount: -1 * floatWithoutComma(form.formExpenseAmount.value),
            name: form.formExpenseName.value,
          });
    
          setShowEditExpenseDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };    

    const showAddExpenseDialogClick = () => {
        setShowAddExpenseDialog(true);
        setExpenseAmount("");
    }
    const handleAddExpenseSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addBudgetMutation.mutate({
            budgetTypeId: form.formBudgetType.value,
            amount: -1 * floatWithoutComma(form.formExpenseAmount.value),
            name: form.formExpenseName.value,
          });
    
          setShowAddExpenseDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };    
    
    const onChangeIncomeAmount = e => {setIncomeAmount(e.target.value);}
    const blurIncomeAmount = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setIncomeAmount(currency(result)); }
            else {setIncomeAmount("");}
        }
    }
    const onChangeExpenseAmount = e => {setExpenseAmount(e.target.value);}
    const blurExpenseAmount = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setExpenseAmount(currency(result)); }
            else {setExpenseAmount("");}
        }
    }
    const onChangeMortgageHouseCost = e => {setMortgageHouseCost(e.target.value);}
    const blurMortgageHouseCost = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setMortgageHouseCost(currency(result)); }
            else {setMortgageHouseCost("");}
        }
    }
    const onChangeMortgageDownPayment = e => {setMortgageDownPayment(e.target.value);}
    const blurMortgageDownPayment = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setMortgageDownPayment(currency(result)); }
            else {setMortgageDownPayment("");}
        }
    }
    const onChangeMortgagePaidToDate = e => {setMortgagePaidToDate(e.target.value);}
    const blurMortgagePaidToDate = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setMortgagePaidToDate(currency(result)); }
            else {setMortgagePaidToDate("");}
        }
    }
    const onChangeMortgageRate = e => {setMortgageRate(e.target.value);}
    const blurMortgageRate = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setMortgageRate(currency(result)); }
            else {setMortgageRate("");}
        }
    }
    const onChangeMortgageMaintenance = e => {setMortgageMaintenance(e.target.value);}
    const blurMortgageMaintenance = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setMortgageMaintenance(currency(result)); }
            else {setMortgageMaintenance("");}
        }
    }
    const onChangeMortgagePropertyTax = e => {setMortgagePropertyTax(e.target.value);}
    const blurMortgagePropertyTax = e => {
        if (e.target.value !== "") { 
            let result = parseFloat(e.target.value.replace(/[^0-9.]/g, ""));
            if (!isNaN(result)) {setMortgagePropertyTax(currency(result)); }
            else {setMortgagePropertyTax("");}
        }
    }

    const editIncomeOnClick = (income) => {
        if (income != null) {
            setSelectedBudgetIncome(income);
            setIncomeAmount(currency(income.amount));
            setShowEditIncomeDialog(true);
        }
    };
    const editExpenseOnClick = (expense) => {
        if (expense != null) {
            setSelectedBudgetExpense(expense);
            setExpenseAmount(currency(-1 * expense.amount));
            setShowEditExpenseDialog(true);
        }
    };
    const editMortgageOnClick = (mortgage) => {
        if (mortgage != null) {
            setSelectedBudgetMortgage(mortgage);
            setMortgageHouseCost(currency(mortgage.houseCost));
            setMortgageDownPayment(currency(mortgage.downPayment));
            setMortgagePaidToDate(currency(mortgage.paidToDate));
            setMortgageRate(currency(mortgage.rate));
            setMortgageMaintenance(currency(mortgage.maintenance));
            setMortgagePropertyTax(currency(mortgage.propertyTax));
            setShowEditMortgageDialog(true);
        }
    };

    const formatTrPropsIncome = (state = {}) => {
        return { onClick: () => editIncomeOnClick(state.original) };
    };
    const formatTrPropsExpense = (state = {}) => {
        return { onClick: () => editExpenseOnClick(state.original) };
    };
    const formatTrPropsMortgage = (state = {}) => {
        return { onClick: () => editMortgageOnClick(state.original) };
    };

    const columnsIncome = useMemo(() => [
        {
            Header: "Income",
            accessor: "name",
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(row.original.amount)}</div>
            )
        },
        {
            Header: "Type",
            accessor: "budgetType",
        },
    ],[]);
    const columnsExpenses = useMemo(() => [
        {
            Header: "Expense",
            accessor: "name",
        },
        {
            Header: "Amount",
            accessor: "amount",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(-1 * row.original.amount)}</div>
            )
        },
        {
            Header: "Type",
            accessor: "budgetType",
        },
    ],[]);
    const columnsMortgages = useMemo(() => [
        {
            Header: "Mortgage",
            accessor: "name",
        },
        {
            Header: "Rate",
            accessor: "rate",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(row.original.rate)}%</div>
            )
        },
        {
            Header: "House Cost",
            accessor: "houseCost",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(row.original.houseCost)}</div>
            )
        },
        {
            Header: "Remaining",
            accessor: "downPayment",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(row.original.houseCost - row.original.downPayment - row.original.paidToDate)}</div>
            )
        },
        {
            Header: "Property Tax",
            accessor: "propertyTaxc",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(row.original.propertyTax)}</div>
            )
        },
        {
            Header: "Monthly",
            accessor: "paidToDate",
            Cell: ({ row }) => (
                <div className='text-right'>{currency(calculateMortgagePayment((row.original.houseCost - row.original.downPayment - row.original.paidToDate), row.original.rate, row.original.timeFrame))}</div>
            )
        },
    ],[]);

    return (
        <div className='panel'>
            <h1><Icon.PiggyBank size={40}/> Budget</h1>
            <p></p>
            <hr></hr>
            <div className='row'>
                <div className='col-md-12'>
                    <BTable responsive className="table table-borderless">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Monthly</th>
                                <th>Yearly</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Income</td>
                                <td className='text-right'>{monthlyIncome}</td>
                                <td className='text-right'>{yearlyIncome}</td>
                            </tr>
                            <tr>
                                <td>Expenses</td>
                                <td className='text-right'>{monthlyExpenses}</td>
                                <td className='text-right'>{yearlyExpenses}</td>
                            </tr>
                            <tr>
                                <td>Mortgages</td>
                                <td className='text-right'>{monthlyMortgages}</td>
                                <td className='text-right'>{yearlyMortgages}</td>
                            </tr>
                            <tr className='panel-side-type-summary'>
                                <td>Gross</td>
                                <td className='text-right'>{monthlyIncome && monthlyExpenses && monthlyMortgages && <span>{currency(floatWithoutComma(monthlyIncome) - floatWithoutComma(monthlyExpenses) - floatWithoutComma(monthlyMortgages))}</span> }</td>
                                <td className='text-right'>{yearlyIncome && yearlyExpenses && yearlyMortgages && <span>{currency(floatWithoutComma(yearlyIncome) - floatWithoutComma(yearlyExpenses) - floatWithoutComma(yearlyMortgages))}</span> }</td>
                            </tr>
                        </tbody>
                    </BTable>
                </div>
            </div>
            <hr></hr>
            <p></p>
            <div className='row'>
                <div className='col-lg-6'>
                <h3>Income</h3>
                    <p></p>
                    <button className='btn btn-primary' onClick={showAddIncomeDialogClick}><Icon.Plus size={20}/> Add</button>
                    <p>&nbsp;</p>
                    {budgetIncome && <TableBasicClick responsive columns={columnsIncome} data={budgetIncome} formatRowProps={(state) => formatTrPropsIncome(state)} />}
                    {budgetIncomeIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                    <p>&nbsp;</p>
                    <hr></hr>
                    <p></p>

                    <h3>Mortgages</h3>
                    <p></p>
                    <button className='btn btn-primary' onClick={showAddMortgageDialogClick}><Icon.Plus size={20}/> Add</button>
                    <p>&nbsp;</p>
                    {budgetMortgage && <TableBasicClick responsive columns={columnsMortgages} data={budgetMortgage} formatRowProps={(state) => formatTrPropsMortgage(state)} />}
                    {budgetMortgageIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                    <p>&nbsp;</p>
                    <hr></hr>
                    <p></p>
                </div>
                <div className='col-lg-6'>
                    <h3>Expenses</h3><p></p>
                    <button className='btn btn-primary' onClick={showAddExpenseDialogClick}><Icon.Plus size={20}/> Add</button>
                    <p>&nbsp;</p>
                    {budgetExpense && <TableBasicClick responsive columns={columnsExpenses} data={budgetExpense} formatRowProps={(state) => formatTrPropsExpense(state)} />}
                    {budgetExpenseIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                    <p>&nbsp;</p>
                    <hr></hr>
                    <p>&nbsp;</p>
                </div>
            </div>

            <Modal
                size="lg"
                show={showAddIncomeDialog}
                onHide={() => setShowAddIncomeDialog(false)}
                aria-labelledby="modal-add-income"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-income">Add Income</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleAddIncomeSubmit}>
                    <Form.Group className="mb-3" controlId="formBudgetType">
                        <Form.Label>Type</Form.Label>
                        <Form.Select>
                            <option value={1}>Monthly</option>
                            <option value={2}>Yearly</option>
                            <option value={3}>DualMonth</option>
                            <option value={3}>BiWeekly</option>
                            <option value={3}>Weekly</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formIncomeName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter an income name" />
                        <Form.Control.Feedback type="invalid">Please enter an income name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formIncomeAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control required type="text" value={incomeAmount} autoComplete="off" onBlur={blurIncomeAmount} onChange={onChangeIncomeAmount}  />
                        <Form.Control.Feedback type="invalid">Please enter an amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleAddIncomeClose}>Cancel</Button>
                </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showEditIncomeDialog}
                onHide={() => setShowEditIncomeDialog(false)}
                aria-labelledby="modal-edit-income"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-edit-income">Edit Income</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleEditIncomeSubmit}>
                    <Form.Group className="mb-3" controlId="formBudgetType">
                        <Form.Label>Type</Form.Label>
                        <Form.Select defaultValue={selectedBudgetIncome.budgetTypeId}>
                            <option value={1}>Monthly</option>
                            <option value={2}>Yearly</option>
                            <option value={3}>DualMonth</option>
                            <option value={3}>BiWeekly</option>
                            <option value={3}>Weekly</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formIncomeName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" defaultValue={selectedBudgetIncome.name} placeholder="Enter an income name" />
                        <Form.Control.Feedback type="invalid">Please enter an income name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formIncomeAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control required type="text" value={incomeAmount} autoComplete="off" onBlur={blurIncomeAmount} onChange={onChangeIncomeAmount}  />
                        <Form.Control.Feedback type="invalid">Please enter an amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleEditIncomeClose}>Cancel</Button>
                    <Button variant="danger" className='modal-btn-right' onClick={()=> setShowDeleteIncomeDialog(true)}>Delete</Button>
                </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteIncomeDialog}
                onHide={() => setShowDeleteIncomeDialog(false)}
                aria-labelledby="modal-delete"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete:</p>
                    {showDeleteIncomeDialog && <div><p>{selectedBudgetIncome.budgetIncomeType}</p><p>{selectedBudgetIncome.name}</p><p>{currency(selectedBudgetIncome.amount)}</p></div>}
                    <Button variant="danger" type="submit" onClick={handleDeleteIncome}>Yes</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleDeleteIncomeClose}>No</Button>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showAddExpenseDialog}
                onHide={() => setShowAddExpenseDialog(false)}
                aria-labelledby="modal-add-expense"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-expense">Add Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleAddExpenseSubmit}>
                    <Form.Group className="mb-3" controlId="formBudgetType">
                        <Form.Label>Type</Form.Label>
                        <Form.Select>
                            <option value={1}>Monthly</option>
                            <option value={2}>Yearly</option>
                            <option value={3}>DualMonth</option>
                            <option value={3}>BiWeekly</option>
                            <option value={3}>Weekly</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formExpenseName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter an expense name" />
                        <Form.Control.Feedback type="invalid">Please enter an expense name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formExpenseAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control required type="text" value={expenseAmount} autoComplete="off" onBlur={blurExpenseAmount} onChange={onChangeExpenseAmount}  />
                        <Form.Control.Feedback type="invalid">Please enter an amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleAddExpenseClose}>Cancel</Button>
                </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showEditExpenseDialog}
                onHide={() => setShowEditExpenseDialog(false)}
                aria-labelledby="modal-edit-expense"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-edit-expense">Edit Expense</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleEditExpenseSubmit}>
                    <Form.Group className="mb-3" controlId="formBudgetType">
                        <Form.Label>Type</Form.Label>
                        <Form.Select defaultValue={selectedBudgetExpense.budgetTypeId}>
                            <option value={1}>Monthly</option>
                            <option value={2}>Yearly</option>
                            <option value={3}>DualMonth</option>
                            <option value={3}>BiWeekly</option>
                            <option value={3}>Weekly</option>
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formExpenseName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" defaultValue={selectedBudgetExpense.name} placeholder="Enter an expense name" />
                        <Form.Control.Feedback type="invalid">Please enter an expense name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formExpenseAmount">
                        <Form.Label>Amount</Form.Label>
                        <Form.Control required type="text" value={expenseAmount} autoComplete="off" onBlur={blurExpenseAmount} onChange={onChangeExpenseAmount}  />
                        <Form.Control.Feedback type="invalid">Please enter an amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleEditExpenseClose}>Cancel</Button>
                    <Button variant="danger" className='modal-btn-right' onClick={()=> setShowDeleteExpenseDialog(true)}>Delete</Button>
                </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteExpenseDialog}
                onHide={() => setShowDeleteExpenseDialog(false)}
                aria-labelledby="modal-delete"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete:</p>
                    {showDeleteExpenseDialog && <div><p>{selectedBudgetExpense.budgetExpenseType}</p><p>{selectedBudgetExpense.name}</p><p>{currency(selectedBudgetExpense.amount)}</p></div>}
                    <Button variant="danger" type="submit" onClick={handleDeleteExpense}>Yes</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleDeleteExpenseClose}>No</Button>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showAddMortgageDialog}
                onHide={() => setShowAddMortgageDialog(false)}
                aria-labelledby="modal-add-mortgage"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-mortgage">Add Mortgage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleAddMortgageSubmit}>
                    <Form.Group className="mb-3" controlId="formMortgageName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" placeholder="Enter a mortgage name" />
                        <Form.Control.Feedback type="invalid">Please enter an mortgage name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageHouseCost">
                        <Form.Label>House Cost</Form.Label>
                        <Form.Control required type="text" value={mortgageHouseCost} autoComplete="off" onBlur={blurMortgageHouseCost} onChange={onChangeMortgageHouseCost}  />
                        <Form.Control.Feedback type="invalid">Please enter a house cost.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageDownPayment">
                        <Form.Label>Down Payment</Form.Label>
                        <Form.Control required type="text" value={mortgageDownPayment} autoComplete="off" onBlur={blurMortgageDownPayment} onChange={onChangeMortgageDownPayment}  />
                        <Form.Control.Feedback type="invalid">Please enter a down payment.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgagePaidToDate">
                        <Form.Label>Paid to Date</Form.Label>
                        <Form.Control required type="text" value={mortgagePaidToDate} autoComplete="off" onBlur={blurMortgagePaidToDate} onChange={onChangeMortgagePaidToDate}  />
                        <Form.Control.Feedback type="invalid">Please enter a paid to date amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageRate">
                        <Form.Label>Mortgage Rate %</Form.Label>
                        <Form.Control required type="text" value={mortgageRate} autoComplete="off" onBlur={blurMortgageRate} onChange={onChangeMortgageRate}  />
                        <Form.Control.Feedback type="invalid">Please enter a mortgage rate.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageTimeFrame">
                        <Form.Label>Mortgage Time Frame (years)</Form.Label>
                        <Form.Control required type="text" autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please enter a time frame.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageMaintenance">
                        <Form.Label>Maintenance Fee (monthly)</Form.Label>
                        <Form.Control required type="text" value={mortgageMaintenance} autoComplete="off" onBlur={blurMortgageMaintenance} onChange={onChangeMortgageMaintenance}  />
                        <Form.Control.Feedback type="invalid">Please enter a maintenance amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgagePropertyTax">
                        <Form.Label>Property Tax (monthly)</Form.Label>
                        <Form.Control required type="text" value={mortgagePropertyTax} autoComplete="off" onBlur={blurMortgagePropertyTax} onChange={onChangeMortgagePropertyTax}  />
                        <Form.Control.Feedback type="invalid">Please enter a monthly property tax amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleAddMortgageClose}>Cancel</Button>
                </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showEditMortgageDialog}
                onHide={() => setShowEditMortgageDialog(false)}
                aria-labelledby="modal-edit-mortgage"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-edit-mortgage">Edit Mortgage</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form noValidate validated={validated} onSubmit={handleEditMortgageSubmit}>
                    <Form.Group className="mb-3" controlId="formMortgageName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control required type="text" defaultValue={selectedBudgetMortgage.name} placeholder="Enter a mortgage name" />
                        <Form.Control.Feedback type="invalid">Please enter an mortgage name.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageHouseCost">
                        <Form.Label>House Cost</Form.Label>
                        <Form.Control required type="text" value={mortgageHouseCost} autoComplete="off" onBlur={blurMortgageHouseCost} onChange={onChangeMortgageHouseCost}  />
                        <Form.Control.Feedback type="invalid">Please enter a house cost.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageDownPayment">
                        <Form.Label>Down Payment</Form.Label>
                        <Form.Control required type="text" value={mortgageDownPayment} autoComplete="off" onBlur={blurMortgageDownPayment} onChange={onChangeMortgageDownPayment}  />
                        <Form.Control.Feedback type="invalid">Please enter a down payment.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgagePaidToDate">
                        <Form.Label>Paid to Date</Form.Label>
                        <Form.Control required type="text" value={mortgagePaidToDate} autoComplete="off" onBlur={blurMortgagePaidToDate} onChange={onChangeMortgagePaidToDate}  />
                        <Form.Control.Feedback type="invalid">Please enter a paid to date amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageRate">
                        <Form.Label>Mortgage Rate %</Form.Label>
                        <Form.Control required type="text" value={mortgageRate} autoComplete="off" onBlur={blurMortgageRate} onChange={onChangeMortgageRate}  />
                        <Form.Control.Feedback type="invalid">Please enter a mortgage rate.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageTimeFrame">
                        <Form.Label>Mortgage Time Frame (years)</Form.Label>
                        <Form.Control required type="text"  defaultValue={selectedBudgetMortgage.timeFrame} autoComplete="off" />
                        <Form.Control.Feedback type="invalid">Please enter a time frame.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgageMaintenance">
                        <Form.Label>Maintenance Fee (monthly)</Form.Label>
                        <Form.Control required type="text" value={mortgageMaintenance} autoComplete="off" onBlur={blurMortgageMaintenance} onChange={onChangeMortgageMaintenance}  />
                        <Form.Control.Feedback type="invalid">Please enter a maintenance amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formMortgagePropertyTax">
                        <Form.Label>Property Tax (monthly)</Form.Label>
                        <Form.Control required type="text" value={mortgagePropertyTax} autoComplete="off" onBlur={blurMortgagePropertyTax} onChange={onChangeMortgagePropertyTax}  />
                        <Form.Control.Feedback type="invalid">Please enter a monthly property tax amount.</Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">Save</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleEditMortgageClose}>Cancel</Button>
                    <Button variant="danger" className='modal-btn-right' onClick={()=> setShowDeleteMortgageDialog(true)}>Delete</Button>
                </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteMortgageDialog}
                onHide={() => setShowDeleteMortgageDialog(false)}
                aria-labelledby="modal-delete"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete:</p>
                    {showDeleteMortgageDialog && <div><p>{selectedBudgetMortgage.name}</p><p>{selectedBudgetMortgage.houseCost}</p></div>}
                    <Button variant="danger" type="submit" onClick={handleDeleteMortgage}>Yes</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleDeleteMortgageClose}>No</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
}
