import React, {useState} from 'react';
import { dateTime } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';

export const MarkerWithInfoWindow = (props) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();
  
  let count = 0;

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfowindowOpen(true)}
        position={{lat: props.location.latitude/10000000, lng: props.location.longitude/10000000}}
        title={props.location.name}
      />
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          maxWidth={300}
          minWidth={300}
          onCloseClick={() => setInfowindowOpen(false)}>

            {props.locations && props.location.durationStart === undefined && props.locations.filter(item => item.location === props.location.address).sort((a, b) => a.firstName > b.firstName ? 1 : -1).map(loc => {
              count++;
              return <div key={loc.id}>

                {props.location.type === "board" && (
                  <>
                    <div className="side-img-default div-left"><span className='side-img-default-location'><Icon.GeoAltFill size={25}/></span></div>
                    <span className='map-right'><span className='map-name'>{loc.name}</span><br/></span>
                  </>
                )}
                {props.location.type === "network" && (
                  <>
                    {loc.photoUrl && loc.photoUrl.length !== 0 ? (
                      <button className="side-img div-left map-link" title='Open Profile' style={{backgroundImage:"url(" + loc.photoUrl + ")"}} onClick={() => props.handleReopen(loc)}></button>   
                    ) : (
                      <button className="side-img-default div-left map-link" title='Open Profile'><Icon.Person size={35}  onClick={() => props.handleReopen(loc)}/></button>
                    )}
                    <span className='map-right'><span className='map-name'>{loc.firstName} {loc.lastName}</span><br/></span>
                  </>
                )}
                

                
                <div className='clear'></div>
                <hr/>
                  {count <= 1 && (<span className='map-address'><a href={`https://www.google.com/maps/place/${loc.location}`} target="_blank" title='Open Map'>{loc.location}</a><hr/></span>)}
                  {count <= 1 && (<img className='google-streetview' src={`https://maps.googleapis.com/maps/api/streetview?size=270x200&location=${props.location.latitude/10000000},${props.location.longitude/10000000}&fov=60&key=AIzaSyAvUBtr22IGYHZ7DmfVFSKgTju9FMurawo`}/>)}
              </div>
            })}

            {props.locations && props.location.durationStart !== undefined ? (<>
              {props.location.photoUrl && props.location.photoUrl.length !== 0 ? (
                <div className="side-img div-left" style={{backgroundImage:"url(" + props.location.photoUrl + ")"}}></div>   
              ) : (
                <div className="side-img-default div-left"><span className='side-img-default-location'><Icon.GeoAltFill size={25}/></span></div>
              )}
              <span className='map-right'>
                {props.location.name && (<><span className='map-name'>{props.location.name}</span><br/></>)}
                <span className='map-address'><a href={`https://www.google.com/maps/place/${props.location.address}`} target="_blank">{props.location.address}</a></span>
              </span>
              <div className='clear'></div>
              <hr/>
              <img className='google-streetview' src={`https://maps.googleapis.com/maps/api/streetview?size=270x200&location=${props.location.latitude/10000000},${props.location.longitude/10000000}&fov=60&key=AIzaSyAvUBtr22IGYHZ7DmfVFSKgTju9FMurawo`}/>
            </>
            ) : <></>}
            <div className='clear'>
              {props.locations && props.locations.filter(item => item.address === props.location.address).sort((a, b) => a.durationStart < b.durationStart ? 1 : -1).map(loc => {

                let start = new Date(loc.durationStart);
                start = start.setHours(start.getHours() - 5);
                let end = new Date(loc.durationEnd);
                end = end.setHours(end.getHours() - 5);

                return <div className='map-date' key={loc.id}>{dateTime(start)} to {dateTime(end)}</div>
              })}
            </div>
        </InfoWindow>
      )}
    </>
  );
};