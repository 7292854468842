import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';
import { guid } from 'helpers/formatter';
import { useAddNetwork, useDeleteNetwork, useUploadToNetwork, useEditNetwork } from 'hooks/networks.hook';
import { date, phone } from "helpers/formatter";
import Autocomplete from "react-google-autocomplete";
import "./org.css";

const Detaildetail = (props) => {

    const [validated, setValidated] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const [showEditDialog, setShowEditDialog] = useState(true);
    const [showExternalViewer, setShowExternalViewer] = useState(false);
    const [externalViewerSrc, setExternalViewerSrc] = useState("");

    const [unlist, setUnlist] = useState(props.network.unlist);
    const [isGroup, setIsGroup] = useState(false);
    const [location, setLocation] = useState("");
    const [location2, setLocation2] = useState("");
    const [latitude, setLatitude] = useState(props.network.latitude/10000000);
    const [longitude, setLongitude] = useState(props.network.longitude/10000000);
    const [latitude2, setLatitude2] = useState(0);
    const [longitude2, setLongitude2] = useState(0);

    const [isUploading, setIsUploading] = useState(false);
    const [uploadfileList, setUploadFileList] = useState([]);

    const handleDeleteClose = () => setShowDeleteDialog(false);

    const editNetworkMutation = useEditNetwork();
    const deleteNetworkMutation = useDeleteNetwork();
    const addNetworkMutation = useAddNetwork();
    const uploadToNetwork = useUploadToNetwork();

    const [selectedNetwork] = useState(props.network);

    useEffect(() => {
        setLocation(selectedNetwork.location);
    }, [props]);

    const handleDelete = () => {
        deleteNetworkMutation.mutate({
            id: selectedNetwork.id,
        });
        setShowDeleteDialog(false);
        props.handleClose();
    };

    const handleAddSubmit = (event) => {
        const form = event.currentTarget;
        let hasUploads = false;
        let timeout = 0;

        if (form.checkValidity() === true) {
            const id = guid();
            let network = {};

            let formBirthDate = date(new Date(1900, 0, 1));
            let formAnniversary = date(new Date(1900, 0, 1));
            if (form.formBirthDate && form.formBirthDate.value.length > 0) { formBirthDate = form.formBirthDate.value }
            if (form.formAnniversary && form.formAnniversary.value.length > 0) { formAnniversary = form.formAnniversary.value }

            if (isGroup) {
                network = {
                    id: id,
                    parentId: selectedNetwork.id,
                    firstName: "",
                    lastName: "",
                    position: "",
                    phone: "",
                    email: "",
                    team: form.formTeam.value,
                    location: "",
                    latitude: 0,
                    longitude: 0,
                    description: form.formDescription.value,
                    facebook: "",
                    instagram: "",
                    birthDate: formBirthDate,
                    anniversary: formAnniversary,
                    unlist: false,
                    galleryLink: "",
                    videoLink: "",
                }
            } else {
                network = {
                    id: id,
                    parentId: selectedNetwork.id,
                    firstName: form.formFirstName.value,
                    lastName: form.formLastName.value,
                    position: form.formPosition.value,
                    phone: form.formPhone.value.replace(/\D/g, ""),
                    email: form.formEmail.value,
                    team: "",
                    location: location2,
                    latitude: parseInt(latitude2 * 10000000),
                    longitude: parseInt(longitude2 * 10000000),
                    description: form.formDescription.value,
                    facebook: form.formFacebook.value,
                    instagram: form.formInstagram.value,
                    birthDate: formBirthDate,
                    anniversary: formAnniversary,
                    unlist: unlist,
                    galleryLink: form.formGalleryLink.value,
                    videoLink: form.formVideoLink.value,
                };
            }
            addNetworkMutation.mutate(network);

            let formData = new FormData();
            formData.append(`NetworkId`, id);
            formData.append(`NetworkParentId`, selectedNetwork.id);
            [...uploadfileList].forEach((file, i) => {
                hasUploads = true;
                formData.append(`Files[${i}].Id`, id);
                formData.append(`Files[${i}].File`, file);
            });
            if (hasUploads) {
                setTimeout(function () {
                    uploadToNetwork.mutate({
                        formData: formData
                    });
                    timeout = 3000;
                    setIsUploading(true);
                    setTimeout(function () {
                        setUploadFileList([]);
                        setShowAddDialog(false);
                        setShowEditDialog(true);
                        setValidated(false);
                        setIsUploading(false);
                    }, timeout);
                }, 500);
            }
            else {
                setShowAddDialog(false);
                setShowEditDialog(true);
                setValidated(false);
            }
        }
        else {
            setValidated(true);
        }

        setLocation2("");

        event.preventDefault();
        event.stopPropagation();
    };

    const handleEditSubmit = (event) => {
        const form = event.currentTarget;
        let hasUploads = false;
        let timeout = 0;
        if (form.checkValidity() === true) {
            let network = {};

            let formBirthDate = date(new Date(1900, 0, 1));
            let formAnniversary = date(new Date(1900, 0, 1));
            if (form.formBirthDate && form.formBirthDate.value.length > 0) { formBirthDate = form.formBirthDate.value }
            if (form.formAnniversary && form.formAnniversary.value.length > 0) { formAnniversary = form.formAnniversary.value }

            let newParentId = "";
            if (selectedNetwork.parentId !== "") {
                newParentId = form.formParentId.value;
            }

            if (selectedNetwork.team) {
                network = {
                    id: selectedNetwork.id,
                    parentId: form.formParentId.value,
                    firstName: "",
                    lastName: "",
                    position: "",
                    phone: "",
                    email: "",
                    team: form.formTeam.value,
                    location: "",
                    latitude: 0,
                    longitude: 0,
                    description: form.formDescription.value,
                    facebook: "",
                    instagram: "",
                    birthDate: formBirthDate,
                    anniversary: formAnniversary,
                    unlist: false,
                    galleryLink: "",
                    videoLink: "",
                }
            } else {
                network = {
                    id: selectedNetwork.id,
                    parentId: newParentId,
                    firstName: form.formFirstName.value,
                    lastName: form.formLastName.value,
                    position: form.formPosition.value,
                    phone: form.formPhone.value.replace(/\D/g, ""),
                    email: form.formEmail.value,
                    team: "",
                    location: location,
                    latitude: parseInt(latitude * 10000000),
                    longitude: parseInt(longitude * 10000000),
                    description: form.formDescription.value,
                    facebook: form.formFacebook.value,
                    instagram: form.formInstagram.value,
                    birthDate: formBirthDate,
                    anniversary: formAnniversary,
                    unlist: unlist,
                    galleryLink: form.formGalleryLink.value,
                    videoLink: form.formVideoLink.value,
                };
            }
            editNetworkMutation.mutate(network);

            let formData = new FormData();
            formData.append(`NetworkId`, selectedNetwork.id);
            formData.append(`NetworkParentId`, selectedNetwork.id);
            [...uploadfileList].forEach((file, i) => {
                hasUploads = true;
                formData.append(`Files[${i}].Id`, selectedNetwork.id);
                formData.append(`Files[${i}].File`, file);
            });
            if (hasUploads) {
                setTimeout(function () {
                    uploadToNetwork.mutate({
                        formData: formData
                    });
                    timeout = 3000;
                    setIsUploading(true);
                    setTimeout(function () {
                        setUploadFileList([]);
                        setShowAddDialog(false);
                        setShowEditDialog(true);
                        setValidated(false);
                        setIsUploading(false);
                        props.handleClose();
                    }, timeout);
                }, 500);
            }
            else {
                setShowAddDialog(false);
                setShowEditDialog(true);
                setValidated(false);
                props.handleClose();
            }

            setLocation("");

            setValidated(false);
        }
        else {
            setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <div>
            <Modal
                size="lg"
                show={showEditDialog}
                onHide={props.handleClose}
                aria-labelledby="modal-edit-network"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-edit-network">
                        {selectedNetwork.team === "" ? (
                            <div>
                                {selectedNetwork.firstName} {selectedNetwork.lastName}
                            </div>) : (
                            <div>
                                {selectedNetwork.team}
                            </div>)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <button className="btn-network-viewer" onClick={() => { setShowAddDialog(true); setShowEditDialog(false); setUnlist(false); }}><Icon.Plus size={35} /></button>
                    {showExternalViewer && <>
                        <iframe src={externalViewerSrc} className='external-viewer' scrolling="no" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen=""></iframe>
                        <button className='external-viewer-close' onClick={() => {setShowExternalViewer(false);setExternalViewerSrc("");}}></button>
                    </>}

                    <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
                        {selectedNetwork.team === "" ? (
                            <div>
                                <div className='detail-shortcuts'>
                                    {selectedNetwork.phone.length > 0 &&
                                        <span>
                                            <a href={"tel:" + selectedNetwork.phone} title={selectedNetwork.phone}><Icon.TelephoneFill size={26} /></a>
                                            <a href={"sms:" + selectedNetwork.phone} title={selectedNetwork.phone}><Icon.ChatLeftFill size={26} /></a>
                                            <a href={"https://wa.me/" + selectedNetwork.phone} target='_blank' title={selectedNetwork.phone}><Icon.Whatsapp size={26} /></a>
                                        </span>
                                    }
                                    {selectedNetwork.email.length > 0 &&
                                        <a href={"mailto:" + selectedNetwork.email} title={selectedNetwork.email}><Icon.EnvelopeAtFill size={26} /></a>
                                    }
                                    {selectedNetwork.location.length > 0 &&
                                        <a href={'https://maps.google.com/?q=' + location + ''} target="_blank"><Icon.GeoAltFill size={26} /></a>
                                    }
                                    {selectedNetwork.facebook.length > 0 &&
                                        <a href={"https://www.facebook.com/" + selectedNetwork.facebook} title={selectedNetwork.facebook} target='_blank'><Icon.Facebook size={26} /></a>
                                    }
                                    {selectedNetwork.instagram.length > 0 &&
                                        <a href={"https://www.instagram.com/" + selectedNetwork.instagram} title={selectedNetwork.instagram} target='_blank'><Icon.Instagram size={26} /></a>
                                    }
                                    {selectedNetwork.galleryLink.length > 0 &&
                                        <Button onClick={()=>{setShowExternalViewer(true); setExternalViewerSrc(selectedNetwork.galleryLink);}} title="Gallery"><Icon.Image size={26} /></Button>
                                    }
                                    {selectedNetwork.videoLink.length > 0 &&
                                        <Button onClick={()=>{setShowExternalViewer(true); setExternalViewerSrc(selectedNetwork.videoLink);}} title="Videos"><Icon.Youtube size={26} /></Button>
                                    }
                                </div>
                                {selectedNetwork.photoUrl.length === 0 ? (
                                    <div className="detail-img-default"><Icon.Person size={150} /></div>
                                ) : (
                                    <div className="detail-img" style={{ backgroundImage: "url(" + selectedNetwork.photoUrl + ")" }}></div>
                                )}

                                <Form.Group className="mb-3" controlId="formFile">
                                    <Form.Label></Form.Label>
                                    <Form.Control type="file" accept=".jpg, .png" onChange={(e) => setUploadFileList(e.target.files)} />
                                </Form.Group>
                                <p></p><hr />

                                <div className="detail-body">
                                    {props.networks
                                        .filter(
                                            (network) => network.parentId === selectedNetwork.id.toString()
                                        )
                                        .map((network) => (
                                            <div className="detail-item-team" key={network.id} onClick={() => { props.handleReopen(network); }}>
                                                {network.team === "" ?
                                                    (
                                                        <>
                                                            {network.photoUrl.length === 0 ?
                                                                (
                                                                    <div key={network.id} className="node-team-member-img-default"><Icon.Person size={35} /></div>
                                                                ) : (
                                                                    <div key={network.id} className="node-team-member-img" style={{ backgroundImage: "url(" + network.photoUrl + ")" }}></div>
                                                                )
                                                            }
                                                            <p className="detail-item-name">{network.firstName} {network.lastName}</p>
                                                            <p className="detail-item-role">{network.position}</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div key={network.id} className="node-team-member-img-default"><Icon.PeopleFill size={35} /></div>
                                                            <p className="detail-item-name">{network.team}</p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        ))}
                                        <p></p>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formFirstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required maxLength="50" type="text" defaultValue={selectedNetwork.firstName} placeholder="Enter a first name" />
                                            <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formLastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control maxLength="50" type="text" defaultValue={selectedNetwork.lastName} placeholder="Enter a last name" />
                                            <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formPosition">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Control maxLength="50" type="text" defaultValue={selectedNetwork.position} placeholder="Enter a role" />
                                            <Form.Control.Feedback type="invalid">Please enter a role.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPhone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="text" maxLength="15" defaultValue={phone(selectedNetwork.phone)} placeholder="Enter a phone number" />
                                            <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" maxLength="50" defaultValue={selectedNetwork.email} placeholder="Enter an email" />
                                    <Form.Control.Feedback type="invalid">Please enter an email.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formLocation">
                                    <Form.Label>Address</Form.Label>
                                    <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation(e.target.value)}
                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        onPlaceSelected={(place) => {
                                            setLatitude(place.geometry.location.lat());
                                            setLongitude(place.geometry.location.lng());
                                            setLocation(place.formatted_address);
                                        }}
                                        options={{
                                            types: ["address"],
                                            componentRestrictions: { country: "ca" },
                                        }}
                                        defaultValue={location}
                                    />          
                                </Form.Group>
                                {location && location.length > 0 && <div><iframe style={{ width: "100%" }} src={'https://maps.google.com/maps?q=' + location + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe><br /><br /></div>}
                                <div className='row'>
                                    <Form.Group className="mb-3 col-6" controlId="formBirthDate">
                                        <Form.Label>Birth Date</Form.Label>
                                        <Form.Control type="date" placeholder="Enter a birth date" defaultValue={selectedNetwork.birthDate && !selectedNetwork.birthDate.includes("1900") && (date(selectedNetwork.birthDate))} />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-6" controlId="formAnniversary">
                                        <Form.Label>Anniversary</Form.Label>
                                        <Form.Control type="date" placeholder="Enter an anniversary" defaultValue={selectedNetwork.anniversary && !selectedNetwork.anniversary.includes("1900") && (date(selectedNetwork.anniversary))} />
                                    </Form.Group>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formFacebook">
                                            <Form.Label>Facebook</Form.Label>
                                            <Form.Control maxLength="100" type="text" defaultValue={selectedNetwork.facebook} placeholder="Enter a facebook ID/URL name" />
                                            <Form.Control.Feedback type="invalid">Please enter a Facebook ID.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formInstagram">
                                            <Form.Label>Instagram</Form.Label>
                                            <Form.Control type="text" maxLength="100" defaultValue={selectedNetwork.instagram} placeholder="Enter an instagram ID/URL name" />
                                            <Form.Control.Feedback type="invalid">Please enter an instagram ID.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formGalleryLink">
                                            <Form.Label>Gallery Link</Form.Label>
                                            <Form.Control maxLength="100" type="text" placeholder="Enter a gallery link" defaultValue={selectedNetwork.galleryLink}/>
                                            <Form.Control.Feedback type="invalid">Please enter a gallery link.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formVideoLink">
                                            <Form.Label>Video Link</Form.Label>
                                            <Form.Control type="text" maxLength="100" placeholder="Enter a video link" defaultValue={selectedNetwork.videoLink} />
                                            <Form.Control.Feedback type="invalid">Please enter a video link.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="formUnlist">
                                    <Form.Label>Side Contacts</Form.Label>
                                    <Form.Check type="switch" defaultChecked={!selectedNetwork.unlist} onChange={(e) => { setUnlist(!unlist); }} />
                                </Form.Group>
                                <br></br><br></br>
                            </div>
                        ) : (
                            <div>
                                <Form.Group className="mb-3" controlId="formTeam">
                                    <Form.Label>Group</Form.Label>
                                    <Form.Control required maxLength="50" type="text" defaultValue={selectedNetwork.team} placeholder="Enter a first name" />
                                    <Form.Control.Feedback type="invalid">Please enter a group name.</Form.Control.Feedback>
                                </Form.Group>
                                <div className="detail-body">
                                    {props.networks
                                        .filter(
                                            (network) => network.parentId === selectedNetwork.id.toString()
                                        )
                                        .map((network) => (
                                            <div className="detail-item-team" key={network.id} onClick={() => { props.handleReopen(network);}}>
                                                {network.team === "" ?
                                                    (
                                                        <>
                                                            {network.photoUrl.length === 0 ?
                                                                (
                                                                    <div key={network.id} className="node-team-member-img-default"><Icon.Person size={35} /></div>
                                                                ) : (
                                                                    <div key={network.id} className="node-team-member-img" style={{ backgroundImage: "url(" + network.photoUrl + ")" }}></div>
                                                                )
                                                            }
                                                            <p className="detail-item-name">{network.firstName} {network.lastName}</p>
                                                            <p className="detail-item-role">{network.position}</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div key={network.id} className="node-team-member-img-default"><Icon.PeopleFill size={35} /></div>
                                                            <p className="detail-item-name">{network.team}</p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        ))}
                                </div>
                                <p></p>
                            </div>
                        )}

                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Information</Form.Label>
                            <Form.Control as="textarea" rows={6} maxLength="500" placeholder="Information" defaultValue={selectedNetwork.description} />
                        </Form.Group>
                        {selectedNetwork.parentId !== "" ? (<>
                            <Form.Group className="mb-3" controlId="formParentId">
                                <Form.Label>Attach To</Form.Label>
                                <div className="detail-body">
                                    {props.networks
                                        .filter(
                                            (network) => network.id === selectedNetwork.parentId
                                        )
                                        .map((network) => (
                                            <div className="detail-item-team" key={network.id} onClick={() => { props.handleReopen(network);}}>
                                                {network.team === "" ?
                                                    (
                                                        <>
                                                            {network.photoUrl.length === 0 ?
                                                                (
                                                                    <div key={network.id} className="node-team-member-img-default"><Icon.Person size={35} /></div>
                                                                ) : (
                                                                    <div key={network.id} className="node-team-member-img" style={{ backgroundImage: "url(" + network.photoUrl + ")" }}></div>
                                                                )
                                                            }
                                                            <p className="detail-item-name">{network.firstName} {network.lastName}</p>
                                                            <p className="detail-item-role">{network.position}</p>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div key={network.id} className="node-team-member-img-default"><Icon.PeopleFill size={35} /></div>
                                                            <p className="detail-item-name">{network.team}</p>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        ))}
                                </div>
                                <Form.Select defaultValue={selectedNetwork.parentId}>
                                    {props.networks && (props.networks.filter(item => item.id !== selectedNetwork.id).map((m) => { return <option key={m.id} value={m.id}>{m.team.length === 0 ? <>{m.firstName} {m.lastName}</> : <>{m.team}</>}</option> }))}
                                </Form.Select>
                            </Form.Group></>) : <></>
                        }
                        <br></br>
                        {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={props.handleClose}>Cancel</Button>
                        <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteDialog(true)}>Delete</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="lg"
                show={showAddDialog}
                onHide={() => { setShowAddDialog(false); setShowEditDialog(true) }}
                aria-labelledby="modal-add-network"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-network">Add To:&nbsp;
                        {selectedNetwork.team === "" ? (
                            <>
                                {selectedNetwork.firstName} {selectedNetwork.lastName}
                            </>) : (
                            <>
                                {selectedNetwork.team}
                            </>)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAddSubmit}>
                        <Form.Group className="mb-3 col-sm-3" controlId="formIsGroup">
                            <Form.Label>Group</Form.Label>
                            <Form.Check type="switch" onChange={(e) => { setIsGroup(!isGroup); }} />
                        </Form.Group>
                        <br></br>
                        <p></p><hr />
                        {!isGroup ? (
                            <div>
                                <Form.Group className="mb-3" controlId="formFile">
                                    <Form.Label></Form.Label>
                                    <Form.Control type="file" accept=".jpg, .png" onChange={(e) => setUploadFileList(e.target.files)} />
                                </Form.Group>
                                <p></p><hr />
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formFirstName">
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control required maxLength="50" type="text" placeholder="Enter a first name" />
                                            <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formLastName">
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control maxLength="50" type="text" placeholder="Enter a last name" />
                                            <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formPosition">
                                            <Form.Label>Role</Form.Label>
                                            <Form.Control maxLength="50" type="text" placeholder="Enter a role" />
                                            <Form.Control.Feedback type="invalid">Please enter a role.</Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="formPhone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control type="text" maxLength="15" placeholder="Enter a phone number" />
                                            <Form.Control.Feedback type="invalid">Please enter a phone number.</Form.Control.Feedback>
                                        </Form.Group>

                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="text" maxLength="50" placeholder="Enter an email" />
                                    <Form.Control.Feedback type="invalid">Please enter an email.</Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formLocation">
                                    <Form.Label>Address</Form.Label>
                                    <Autocomplete className='form-control' maxLength="100" onKeyUp={(e) => setLocation2(e.target.value)}
                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        onPlaceSelected={(place) => {
                                            setLatitude2(place.geometry.location.lat());
                                            setLongitude2(place.geometry.location.lng());
                                            setLocation2(place.formatted_address);
                                        }}
                                        options={{
                                            types: ["address"],
                                            componentRestrictions: { country: "ca" },
                                        }}
                                    />          
                                </Form.Group>
                                {location2 && location2.length > 0 && <div><iframe style={{ width: "100%" }} src={'https://maps.google.com/maps?q=' + location2 + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe><br /><br /></div>}
                                <div className='row'>
                                    <Form.Group className="mb-3 col-6" controlId="formBirthDate">
                                        <Form.Label>Birth Date</Form.Label>
                                        <Form.Control type="date" placeholder="Enter a birth date" />
                                    </Form.Group>
                                    <Form.Group className="mb-3 col-6" controlId="formAnniversary">
                                        <Form.Label>Anniversary</Form.Label>
                                        <Form.Control type="date" placeholder="Enter an anniversary" />
                                    </Form.Group>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formFacebook">
                                            <Form.Label>Facebook</Form.Label>
                                            <Form.Control maxLength="100" type="text" placeholder="Enter a facebook ID/URL name" />
                                            <Form.Control.Feedback type="invalid">Please enter a Facebook ID.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formInstagram">
                                            <Form.Label>Instagram</Form.Label>
                                            <Form.Control type="text" maxLength="100" placeholder="Enter an instagram ID/URL name" />
                                            <Form.Control.Feedback type="invalid">Please enter an instagram ID.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formGalleryLink">
                                            <Form.Label>Gallery Link</Form.Label>
                                            <Form.Control maxLength="100" type="text" placeholder="Enter a gallery link" />
                                            <Form.Control.Feedback type="invalid">Please enter a gallery link.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                    <div className='col-md-6'>
                                        <Form.Group className="mb-3" controlId="formVideoLink">
                                            <Form.Label>Video Link</Form.Label>
                                            <Form.Control type="text" maxLength="100" placeholder="Enter a video link" />
                                            <Form.Control.Feedback type="invalid">Please enter a video link.</Form.Control.Feedback>
                                        </Form.Group>
                                    </div>
                                </div>
                                <Form.Group className="mb-3" controlId="formUnlist">
                                    <Form.Label>Side Contacts</Form.Label>
                                    <Form.Check type="switch" defaultChecked={!unlist} onChange={(e) => { setUnlist(!unlist); }} />
                                </Form.Group>
                                <br></br><br></br>
                            </div>
                        ) : (
                            <div>
                                <br></br>
                                <Form.Group className="mb-3" controlId="formTeam">
                                    <Form.Label>Group Name</Form.Label>
                                    <Form.Control required maxLength="50" type="text" defaultValue={selectedNetwork.team} placeholder="Enter a team name" />
                                    <Form.Control.Feedback type="invalid">Please enter a group name.</Form.Control.Feedback>
                                </Form.Group>
                            </div>
                        )}

                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Information</Form.Label>
                            <Form.Control as="textarea" rows={6} maxLength="500" placeholder="Information" />
                        </Form.Group>
                        <br></br>
                        {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={() => { setShowAddDialog(false); setShowEditDialog(true) }}>Cancel</Button>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                show={showDeleteDialog}
                onHide={() => setShowDeleteDialog(false)}
                aria-labelledby="modal-delete"
            >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you would like to delete:</p>
                    {showDeleteDialog && <div><p>{selectedNetwork.team === "" ? (
                        <>
                            {selectedNetwork.firstName} {selectedNetwork.lastName}
                        </>) : (
                        <>
                            {selectedNetwork.team}
                        </>)}
                    </p></div>}
                    <Button variant="danger" type="submit" onClick={(handleDelete)}>Yes</Button>&nbsp;
                    <Button variant="secondary" type="button" onClick={handleDeleteClose}>No</Button>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Detaildetail;
