import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';
import { APIProvider, Map } from '@vis.gl/react-google-maps';
import { MarkerWithInfoWindow } from 'components/MarkerWithInfoWindow';
import DetailCard from "sections/org/detailCard";
import Autocomplete from "react-google-autocomplete";

export { ManageNetworkMap };

function ManageNetworkMap(props) {
  const defaultPosition = { lat: 43.7153176, lng: -79.5178468 };
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [networkId, setNetworkId] = useState("");
  const [cardShow, setCardShow] = useState(false);
  const handleShow = () => setCardShow(true);
  const handleClose = () => { setCardShow(false); props.resetSelectedNetwork(); };
  const handleReopen = (newNetworkId) => { 
    handleClose();
    setTimeout(function () {
      toggleDetailsCard(newNetworkId);
    }, 100);
  }

  const toggleDetailsCard = (nodeId) => {
    setNetworkId(nodeId);
    handleShow();
  };

  useEffect(() => {
    if (props.networks !== undefined) {
      setTimeout(function () {
        setFilteredLocations(props.networks);
        setLocations(props.networks);

        if (props.selectedNetwork !== null && props.selectedNetwork !== '') {
          toggleDetailsCard(props.selectedNetwork);
        }
      }, 500);
    }
  }, [props.networks, props.selectedNetwork]);

  const handleFilterMap = (s) => {
    setSearchText(s);

    let loc = locations;

    if (s !== '') {
      loc = locations.filter(item => (item.firstName !== null && item.firstName.toLowerCase().indexOf(s.toLowerCase()) !== -1) || (item.lastName !== null && item.lastName.toLowerCase().indexOf(s.toLowerCase()) !== -1));
      setFilteredLocations(loc);
    }
    else {
      setFilteredLocations(locations);
    }
  }

  return (
    <div>
      <h1><Icon.GeoAltFill size={35} /> Network Map</h1>
      <p></p>
      <hr></hr>
      {locations && locations.length > 0 ?
        <div className='row'>
          <div className='col-4'>
            <Form.Control type="text" placeholder="Search..." value={searchText} onChange={(e) => handleFilterMap(e.target.value)} />
          </div>
        </div>
        : <></>
      }
      <p>&nbsp;</p>
      <Autocomplete className='hide' maxLength="100" apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} />          

        {filteredLocations && filteredLocations.length > 0 ?
          <div className='map-container'>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={['marker']}>
              <Map
                mapId={'bf51a910020fa25a'}
                defaultZoom={10}
                defaultCenter={defaultPosition}
                gestureHandling={'greedy'}
                disableDefaultUI>
                {filteredLocations.map(item => {
                  if (item.latitude === 0 && item.longitude === 0) { return ""; }
                  return <MarkerWithInfoWindow key={item.id} locations={locations} location={{"type":"network", "name": `${item.firstName} ${item.lastName}`, "address": item.location, "latitude": item.latitude, "longitude": item.longitude, "photoUrl": item.photoUrl }} handleReopen={handleReopen} />
                })}
              </Map>
            </APIProvider>
          </div>
        : <></>
        }
      <p>&nbsp;</p>
      {cardShow && networkId && (
        <DetailCard
          networks={props.networks}
          network={props.networks.find((network) => network.id === networkId.id)}
          handleClose={handleClose}
          handleReopen={handleReopen}
        />
      )}
    </div>
  );
}