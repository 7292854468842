import React from "react";
import * as Icon from 'react-bootstrap-icons';
import "./org.css";

const CustomNodeContent = (props) => {
    return (
        <div>
            {props.data.team === "" ? (
                <div className={"node-container " + (!props.data.unlist ? 'node-details-listed' : '')}>
                    <div className="node-details">
                        <div className="node-content">
                            {props.data.photoUrl.length === 0 ? (
                                <div className="node-img-default"><Icon.Person size={70}/></div>
                             ) : (
                                <div className="node-img" style={{backgroundImage:"url(" + props.data.photoUrl + ")"}}></div>
                             )}
                            <div className="node-info">
                                <div className="node-name">{props.data.firstName} {props.data.lastName}</div>
                                {props.data.position && (
                                    <div className="node-department">
                                        <div>{props.data.position}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="node-container node-container-teams">
                    <div className="node-details">
                        <div className="node-team">
                            <div className="node-team-name">{props.data.team}</div>
                            {props._children && props._children !== null &&
                                props._children
                                    .filter(item => item.data.team === "")
                                    .slice(0,4)
                                    .map((child) => (
                                        child.data.photoUrl.length === 0 ? (
                                            <div key={child.data.id} className="node-team-member-img-default"><Icon.Person size={35}/></div>
                                         ) : (
                                            <div key={child.data.id} className="node-team-member-img" style={{backgroundImage:"url(" + child.data.photoUrl + ")"}}></div>
                                         )
                                    ))}
                        </div>
                    </div>
                </div>

            )}
        </div>
    );
};

export default CustomNodeContent;
