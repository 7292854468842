import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchInventories, addInventory, editInventory, deleteInventory, fetchInventoryRecords, addInventoryRecord, editInventoryRecord, deleteInventoryRecord, fetchWarningInventories } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useInventories = () => {
  const query = useQuery(["get-inventories"], fetchInventories, {select: (data) => data,});
  checkUserAuthorizedQuery(query);
  return query;
};
export const useWarningInventories = () => {
  return useQuery(["get-warning-inventories"], fetchWarningInventories, {select: (data) => data,});
};
export const useAddInventory = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-inventory"], {
    mutationFn: (data) => addInventory(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-inventories'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-inventory"]);
};

export const useEditInventory = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-inventory"], {
    mutationFn: (data) => editInventory(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-inventories'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-inventory"]);
};

export const useDeleteInventory = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-inventory"], {
    mutationFn: (data) => deleteInventory(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-inventories'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-inventory"]);
};

export const useInventoryRecords = (inventoryId) => {
  return useQuery(["get-inventory-records", inventoryId], fetchInventoryRecords, {select: (data) => data,});
};

export const useAddInventoryRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-inventory-record"], {
    mutationFn: (data) => addInventoryRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-inventory-records']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-inventory-record"]);
};

export const useEditInventoryRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-inventory-record"], {
    mutationFn: (data) => editInventoryRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-inventory-records']);
      queryClient.invalidateQueries(['get-warning-inventories']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-inventory-record"]);
};

export const useDeleteInventoryRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-inventory-record"], {
    mutationFn: (data) => deleteInventoryRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-inventory-records']);
      queryClient.invalidateQueries(['get-warning-inventories']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-inventory-record"]);
};