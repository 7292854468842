import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { useParams } from "react-router-dom";
import { useBoardListCard, useEditBoardListCardNetwork} from 'hooks/boards.hook';
import { useBoardListCardNetworks } from 'hooks/networks.hook';
import { currency, date, time24to12 } from 'helpers/formatter';
import { getUser } from 'helpers/current-user';

export { BoardViewer };

function BoardViewer() {
  const [user] = useState(getUser());
  const { id, id2 } = useParams();
  const [invites, setInvites] = useState([]);
  const [boardListCard, setBoardListCard] = useState([]);
  const [pending, setPending] = useState(0);
  const [going, setGoing] = useState(0);
  const [declined, setDeclined] = useState(0);
  const editBoardListCardNetworkMutation = useEditBoardListCardNetwork();

  const { data: boardListCardData, isError: boardListsIsError } = useBoardListCard(id);
  useEffect(() => { 
    if (boardListCardData !== undefined) {
      setBoardListCard(boardListCardData); 
    }
  }, [boardListCardData]);

  const { data: networksData } = useBoardListCardNetworks(boardListCard.id);
  useEffect(() => { 
      if (networksData !== undefined && networksData !== 0) {
        if (boardListCard !== undefined && boardListCard.boardListCardNetworks !== undefined && boardListCard.boardListCardNetworks.length > 0) {
          let going_counter = 0;
          let pending_counter = 0;
          let declined_counter = 0;

          let current = [];
          if (id2 !== undefined) {
            current = boardListCard.boardListCardNetworks.filter(item => item.id === id2).map(item => networksData.filter(i => i.id === item.networkId));
          }

          let invitesTemp = boardListCard.boardListCardNetworks.map(item => networksData.filter(i => i.id === item.networkId));
            
          let invitesFixed = [];
          for (var i in invitesTemp) {
            invitesTemp[i][0].statusId = boardListCard.boardListCardNetworks.filter(item => item.networkId === invitesTemp[i][0].id)[0].statusId;
            invitesTemp[i][0].boardNetworkId = boardListCard.boardListCardNetworks.filter(item => item.networkId === invitesTemp[i][0].id)[0].id;
            invitesTemp[i][0].userId = boardListCard.boardListCardNetworks.filter(item => item.networkId === invitesTemp[i][0].id)[0].userId;
            invitesFixed.push(invitesTemp[i][0]);

            switch (invitesTemp[i][0].statusId) {
              case 2: pending_counter++; break;
              case 3: going_counter++; break;
              case 4: declined_counter++; break;
            }
          }

          setPending(pending_counter);
          setGoing(going_counter);
          setDeclined(declined_counter);

          for (var i in invitesFixed.sort((a, b) => a.lastName > b.lastName ? 1 : -1).sort((a, b) => a.firstName > b.firstName ? 1 : -1)) {
            if (id2 !== undefined && current.length > 0) {
              if (invitesFixed[i].id === current[0][0].id) {
                invitesFixed[i].order = 1;
              }
              else {
                invitesFixed[i].order = parseInt(i) + 2;
              }
            }
            else {
              invitesFixed[i].order = parseInt(i) + 1;
            }
          }

          if (user === null || (invitesFixed.length > 0 && user.id !== invitesFixed[0].userId && invitesFixed[0].statusId === 1)) {
            invitesFixed = invitesFixed.filter(item => item.statusId > 1).sort((a, b) => a.order > b.order ? 1 : -1);
          }

          if (invitesFixed.length > 0 && invitesFixed.filter(item => item.order === 1).length === 0 && id2 !== undefined) {
            window.location.href = "/view/0";
          }
              
          setInvites(invitesFixed.sort((a, b) => a.order > b.order ? 1 : -1));
        }
      }
  }, [networksData, boardListCard]);

  const handleStatusChange = (id, statusId) => {
    if (statusId >= 1 && statusId <= 4 && id !== null) {
      editBoardListCardNetworkMutation.mutate({
        id: id,
        statusId: statusId,
      });
    }
  }

  return (
    <div className='panel external-viewer panel-fill'>
      {boardListsIsError && <h1><Icon.Search size={35}/> Page Not Found</h1>}
        {!boardListsIsError && boardListCard && <div>
          <p><a className='bdc' href='https://www.bartolodigital.com' target='_blank'><img src='/images/icon-powered.png'></img></a></p>
          <h1><Icon.CardChecklist size={35}/> {boardListCard.name}</h1>
          <p></p>
          <hr></hr>
          {invites !== undefined && invites.length !== 0 &&
            <>
              {invites.filter(item => item.boardNetworkId === id2).map(network => { return (
                <div className='board-network-options' key={network.id}>
                  {boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && new Date(boardListCard.dateStart) >= new Date() &&
                    <>
                      {network.statusId === 2 && <span className='board-network-instruction'>Please Choose:</span>}
                      {network.statusId === 3 && <span className='board-network-status status-attended'>GOING<Icon.Check/></span>}
                      {network.statusId === 4 && <span className='board-network-status status-declined'>DECLINED<Icon.X/></span>}
                  
                      <div className="board-network">
                        <div className='invite-actions'>
                          <button className={network.statusId === 2 ? ('btn status-attended') : ('btn status-result')} onClick={() => { handleStatusChange(network.boardNetworkId, 3); }}>Going<Icon.Check/></button>
                          <button className={network.statusId === 2 ? ('btn status-declined') : ('btn status-result')} onClick={() => { handleStatusChange(network.boardNetworkId, 4); }}>Decline<Icon.X/></button>
                        </div>
                      </div>
                  </>
                }
                </div>
              )})}
            </>
          }
          {boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && 
            <div className='board-list-card-viewer-section'>
              <p><strong>Start Date:</strong></p>
              <p>{date(boardListCard.dateStart)}
              {!boardListCard.dateStart.includes("00:00:00") && " @ " + time24to12(boardListCard.dateStart)}
              </p>
              <p></p>
            </div>
          }
          {boardListCard.dateEnd !== undefined && !(boardListCard.dateEnd.includes("1900")) && 
            <div className='board-list-card-viewer-section'>
              <p><strong>End Date:</strong></p>
              <p>{date(boardListCard.dateEnd)}
              {!boardListCard.dateEnd.includes("00:00:00") && " @ " + time24to12(boardListCard.dateEnd)}
              </p>
              <p></p>
            </div>
          }
          {boardListCard.description !== undefined && boardListCard.description.length > 0 &&
            <div className='board-list-card-viewer-section'>
              <p><strong>Description:</strong></p>
              <p>{boardListCard.description}</p>
              <p></p>
            </div>
          }
          {boardListCard.price !== 0 && 
            <div className='board-list-card-viewer-section'>
              <p><strong>Price:</strong></p>
              <p>${currency(boardListCard.price)}</p>
              <p></p>
            </div>
          }
          {boardListCard.location !== undefined && boardListCard.location.length > 0 &&
            <div className='board-list-card-viewer-section'>
              <p><strong>Location:</strong></p>
              <p>{boardListCard.location}</p>
              <iframe title='map' style={{width:"100%", height:"350px"}} src={'https://maps.google.com/maps?q=' + boardListCard.location + '&t=&z=13&ie=UTF8&iwloc=&output=embed'}></iframe>
              <p></p>
            </div>
          }

          {invites !== undefined && invites.length !== 0 &&
            <div className='board-list-card-viewer-section'>

              {boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && new Date(boardListCard.dateStart) > new Date() &&
                <p>
                  <span className='board-network-status status-summary status-attended'><Icon.Check/>{going}</span>
                  {new Date(boardListCard.dateStart) >= new Date() && <span className='board-network-status status-summary status-pending'><Icon.QuestionLg/>{pending}</span>}
                  <span className='board-network-status status-summary status-declined'><Icon.X/>{declined}</span>
                </p>
              }

              {invites.map(network => { return (
                <div className="board-network" key={network.id}>
                  <div>
                    {network.photoUrl.length === 0 ?
                        (
                            <div key={network.id} className="node-img-default"><Icon.Person size={45} /></div>
                        ) : (
                            <div key={network.id} className="node-img" style={{ backgroundImage: "url(" + network.photoUrl + ")" }}></div>
                        )
                    }
                    {boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && new Date(boardListCard.dateStart) >= new Date() &&
                      <>
                        {network.statusId === 1 && <div className='board-network-status'>INVITE<Icon.Plus/></div>}
                        {network.statusId === 2 && network.boardNetworkId !== id2 && <div className='board-network-status status-pending'>WAITING<Icon.QuestionLg/></div>}
                        {network.statusId === 3 && <div className='board-network-status status-attended'>GOING<Icon.Check/></div>}
                        {network.statusId === 4 && <div className='board-network-status status-declined'>DECLINED<Icon.X/></div>}

                      </>
                    }
                    {boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && new Date(boardListCard.dateStart) < new Date() &&
                      <>
                        {network.statusId === 2 && <div className='board-network-status status-pending'>WAITING<Icon.QuestionLg/></div>}
                        {network.statusId === 3 && <div className='board-network-status status-attended'>ATTENDED<Icon.Check/></div>}
                        {network.statusId === 4 && <div className='board-network-status status-declined'>DECLINED<Icon.X/></div>}
                      </>
                    }
                  </div>
                  <div>
                    &nbsp;
                    {network.firstName} {network.lastName} 
                    <br/>
                    {network.boardNetworkId === id2 && boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && new Date(boardListCard.dateStart) >= new Date() &&
                      <div className='invite-actions'>
                        <button className={network.statusId === 2 ? ('btn status-attended') : ('btn status-result')} onClick={() => { handleStatusChange(network.boardNetworkId, 3); }}>Going<Icon.Check/></button>
                        <button className={network.statusId === 2 ? ('btn status-declined') : ('btn status-result')} onClick={() => { handleStatusChange(network.boardNetworkId, 4); }}>Decline<Icon.X/></button>
                      </div>
                    }
                    {user !== null && user.id === network.userId && boardListCard.dateStart !== undefined && !(boardListCard.dateStart.includes("1900")) && new Date(boardListCard.dateStart) >= new Date() &&
                      <>
                        <div className='board-network-shortcuts'>
                          {network.phone !== undefined && network.phone.length > 0 &&
                            <span>
                                <a href={"tel:" + network.phone} title={network.phone}><Icon.TelephoneFill size={18} /></a>
                                <a href={"sms:" + network.phone} title={network.phone}><Icon.ChatLeftFill size={18} /></a>
                                <a href={"https://wa.me/" + network.phone} target='_blank' title={network.phone}><Icon.Whatsapp size={18} /></a>
                            </span>
                          }
                          {network.email !== undefined && network.email.length > 0 &&
                              <a href={"mailto:" + network.email} title={network.email}><Icon.EnvelopeAtFill size={18} /></a>
                          }
                          {network.facebook !== undefined && network.facebook.length > 0 &&
                              <a href={"https://www.facebook.com/" + network.facebook} title={network.facebook} target='_blank'><Icon.Facebook size={18} /></a>
                          }
                          {network.instagram !== undefined && network.instagram.length > 0 &&
                              <a href={"https://www.instagram.com/" + network.instagram} title={network.instagram} target='_blank'><Icon.Instagram size={18} /></a>
                          }
                        </div>
                        <div className='invite-actions'>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network.boardNetworkId, 3); }}>Going<Icon.Check/></button>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network.boardNetworkId, 2); }}>Pending<Icon.QuestionLg/></button>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network.boardNetworkId, 4); }}>Decline<Icon.X/></button>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network.boardNetworkId, 1); }}>Cancel<Icon.X/></button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              )})}
              <p></p>
            </div>
          }

        </div>}
    </div>
  );
}