import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import {date } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';
import { useEditVault, useDeleteVault, useAddVaultRecord, useVaultRecords, useEditVaultRecord, useDeleteVaultRecord, useVaultRecordKey, useUploadToVaultRecord, useVaultRecordUpload, useDeleteVaultRecordUpload } from 'hooks/vaults.hook';

export { ManageVaults };

function ManageVaults(props) {
  const [validated, setValidated] = useState(false);
  const [showEditVaultDialog, setShowEditVaultDialog] = useState(false);
  const [showDeleteVaultDialog, setShowDeleteVaultDialog] = useState(false);
  const [showAddVaultRecordDialog, setShowAddVaultRecordDialog] = useState(false);
  const [showEditVaultRecordDialog, setShowEditVaultRecordDialog] = useState(false);
  const [showDeleteVaultRecordDialog, setShowDeleteVaultRecordDialog] = useState(false);
  const [showDeleteVaultRecordUploadDialog, setShowDeleteVaultRecordUploadDialog] = useState(false);

  const [selectedVaultRecord, setSelectedVaultRecord] = useState(0);
  const [selectedVaultRecordId, setSelectedVaultRecordId] = useState(0);
  const [selectedVaultRecordId2, setSelectedVaultRecordId2] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedVaultRecordUpload, setSelectedVaultRecordUpload] = useState(0);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("******");
  const [uploadfileList, setUploadFileList] = useState([]);
  const [fileList, setFileList] = useState([]);

  const [vaultRecords, setVaultRecords] = useState([]);
  const { data: vaultRecordsData, isError: vaultRecordsIsError, error: vaultRecordsError, isLoading: vaultRecordsIsLoading } = useVaultRecords(props.selectedVault.id);
  useEffect(() => { setVaultRecords(vaultRecordsData); }, [vaultRecordsData]);

  const { data: vaultRecordData } = useVaultRecordKey(selectedVaultRecordId);
  useEffect(() => { 
    if (vaultRecordData !== undefined) {
      setPassword(vaultRecordData.password);
    } 
  }, [vaultRecordData]);

  const { data: vaultRecordFilesData } = useVaultRecordUpload(selectedVaultRecordId2);
  useEffect(() => { 
    if (vaultRecordFilesData !== undefined) {
      setFileList(vaultRecordFilesData);
    } 
  }, [vaultRecordFilesData]);

  const editVaultMutation = useEditVault();
  const deleteVaultMutation = useDeleteVault();
  const addVaultRecordMutation = useAddVaultRecord();
  const editVaultRecordMutation = useEditVaultRecord();
  const deleteVaultRecordMutation = useDeleteVaultRecord();
  const uploadToVaultRecord = useUploadToVaultRecord();
  const deleteVaultRecordUploadMutation = useDeleteVaultRecordUpload();

  const handleEditVaultClose = () => setShowEditVaultDialog(false);
  const handleDeleteVaultClose = () => setShowDeleteVaultDialog(false);
  const handleAddVaultRecordClose = () => {setShowAddVaultRecordDialog(false); setShowPassword(false); }
  const handleEditVaultRecordClose = () => { setShowEditVaultRecordDialog(false); setShowPassword(false);}
  const handleDeleteVaultRecordClose = () => setShowDeleteVaultRecordDialog(false);
  const handleDeleteVaultRecordUploadClose = () => setShowDeleteVaultRecordUploadDialog(false);

  const handleVaultDelete = () => {
    deleteVaultMutation.mutate({
      id: props.selectedVault.id,
    });
    setShowDeleteVaultDialog(false);
    setShowEditVaultDialog(false);
    setShowPassword(false);
    window.location.reload(false);
  };
  const handleEditVaultSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      editVaultMutation.mutate({
        id: props.selectedVault.id,
        name: form.formVaultName.value,
      });

      props.selectedVault.name = form.formVaultName.value;

      setShowEditVaultDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const handleVaultRecordDelete = () => {
    deleteVaultRecordMutation.mutate({
      id: selectedVaultRecord.id,
    });
    setShowPassword(false);
    setShowDeleteVaultRecordDialog(false);
    setShowEditVaultRecordDialog(false);
  };

  const handleVaultRecordUploadDelete = () => {
    deleteVaultRecordUploadMutation.mutate({
      id: selectedVaultRecord.id,
      filename: selectedVaultRecordUpload
    });

    setFileList(fileList.filter(e => !e.includes(selectedVaultRecordUpload)));
    setShowDeleteVaultRecordUploadDialog(false);
  };

  const handleEditVaultRecordSubmit = (event) => {
    const form = event.currentTarget;
    let hasUploads = false;
    let timeout = 0;
    
    if (form.checkValidity() === true) {
      editVaultRecordMutation.mutate({
        id: selectedVaultRecord.id,
        vaultId: props.selectedVault.id,
        name: form.formVaultRecordName.value,
        username: form.formVaultRecordUsername.value,
        password: form.formVaultRecordPassword.value,
        notes: form.formVaultRecordNotes.value,
      });

      let formData = new FormData();
      formData.append(`VaultId`, selectedVaultRecord.vaultId);
      formData.append(`VaultRecordId`, selectedVaultRecord.id);
      [...uploadfileList].forEach((file, i) => {
        hasUploads = true;
        formData.append(`Files[${i}].Id`, selectedVaultRecord.id);
        formData.append(`Files[${i}].File`, file);
      });
      if (hasUploads) {
        uploadToVaultRecord.mutate({
          formData: formData
        });
        timeout = 3000;
        setIsUploading(true);
      }
      setTimeout(function(){
        setUploadFileList([]);
        setShowEditVaultRecordDialog(false);
        setValidated(false);
        setIsUploading(false);
      }, timeout);
    }
    else {
      setValidated(true);
    }
    setShowPassword(false);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAddVaultRecordSubmit = (event) => {
    const form = event.currentTarget;
    let hasUploads = false;
    let timeout = 0;

    if (form.checkValidity() === true) {
      addVaultRecordMutation.mutate({
        vaultId: props.selectedVault.id,
        name: form.formVaultRecordName.value,
        username: form.formVaultRecordUsername.value,
        password: form.formVaultRecordPassword.value,
        notes: form.formVaultRecordNotes.value
      });

      let formData = new FormData();
      formData.append(`VaultId`, props.selectedVault.id);
      formData.append(`VaultRecordId`, props.selectedVault.id);
      [...uploadfileList].forEach((file, i) => {
        hasUploads = true;
        formData.append(`Files[${i}].Id`, props.selectedVault.id);
        formData.append(`Files[${i}].File`, file);
      });
      if (hasUploads) {
        setTimeout(function() {
          uploadToVaultRecord.mutate({
            formData: formData
          });
          timeout = 3000;
          setIsUploading(true);
          setTimeout(function(){
            setUploadFileList([]);
            setShowAddVaultRecordDialog(false);
            setValidated(false);
            setIsUploading(false);
          }, timeout);
        }, 500);
      }
      else {
        setShowAddVaultRecordDialog(false);
        setValidated(false);
      }
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const editVaultRecordOnClick = (vaultRecord) => {
    if (vaultRecord != null) {
      setPassword("******");
      setSelectedVaultRecord(vaultRecord);
      setSelectedVaultRecordId2(vaultRecord.id);
      setShowEditVaultRecordDialog(true);
    }
  };

  const handleTogglePassword = (event) => {
    setShowPassword(!showPassword);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleTogglePasswordEdit = (event) => {
    setSelectedVaultRecordId(selectedVaultRecord.id);
    if (vaultRecordData !== undefined) {
      setPassword(vaultRecordData.password);
    } 
    setTimeout(function(){
      setShowPassword(!showPassword);
    },1000);

    event.preventDefault();
    event.stopPropagation();
  };

  const deleteVaultRecordUploadOnClick = (filename) => {
    if (filename != null) {
      setSelectedVaultRecordUpload(filename);
      setShowDeleteVaultRecordUploadDialog(true);
    }
  };

  const onChangePassword = e => {
    setPassword(e.target.value);
  }

  const formatTrProps = (state = {}) => {
    return { onClick: () => editVaultRecordOnClick(state.original) };
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Modified",
        accessor: "modified",
        Cell: ({ row }) => (
          date(row.original.modified)
        )
      },
      {
        Header: "Created",
        accessor: "created",
        Cell: ({ row }) => (
          date(row.original.created)
        )
      },
    ],
    []
  );
 
  return (
    <div>
      <h1><Icon.Lock size={30} /> {props.selectedVault.name} <button className="button-text" onClick={() => setShowEditVaultDialog(true)}><Icon.ThreeDots size={40} /></button></h1>
      <p></p>
      <p><button className='btn btn-primary' onClick={() => setShowAddVaultRecordDialog(true)} ><Icon.Plus size={20}/> Add Record</button></p>
      <p></p>
      {vaultRecords && <TableBasicClick responsive columns={columns} data={vaultRecords} formatRowProps={(state) => formatTrProps(state)} />}
      {vaultRecordsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
      {vaultRecordsIsError && <div className="text-danger">Error loading vaultRecords: {vaultRecordsError.message}</div>}

      <Modal
        size="lg"
        show={showEditVaultDialog}
        onHide={() => setShowEditVaultDialog(false)}
        aria-labelledby="modal-edit-vault"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-vault">Edit Vault</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditVaultSubmit}>
            <Form.Group className="mb-3" controlId="formVaultName">
              <Form.Label>Vault Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter a vault name" defaultValue={props.selectedVault.name} />
              <Form.Control.Feedback type="invalid">Please enter a vault name.</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditVaultClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteVaultDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteVaultDialog}
        onHide={() => setShowDeleteVaultDialog(false)}
        aria-labelledby="modal-delete"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteVaultDialog && <div><p>{props.selectedVault.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleVaultDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteVaultClose}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showAddVaultRecordDialog}
        onHide={() => setShowAddVaultRecordDialog(false)}
        aria-labelledby="modal-add-vault-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-add-vault-record">Add Vault Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddVaultRecordSubmit}>
            <Form.Group className="mb-3" controlId="formVaultRecordName">
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" maxLength="100" placeholder="Enter a vault record name" autoComplete="off" />
              <Form.Control.Feedback type="invalid">Please enter a vault record name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" maxLength="100" placeholder="Enter a vault record username" autoComplete="off" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type={showPassword? "text": "password"} maxLength="100" placeholder="Enter a vault record password" autoComplete="new-password" />
              <button className='see' onClick={handleTogglePassword}><Icon.EyeFill size={22} /></button>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control as="textarea" rows={6} maxLength="500" placeholder="Notes" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordFiles">
              <Form.Label>Files</Form.Label>
              <Form.Control type="file" multiple="multiple" onChange={(e)=> setUploadFileList(e.target.files)} accept=".jpg, .png, .pdf, .docx, .xlsx" />
            </Form.Group>
            {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleAddVaultRecordClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showEditVaultRecordDialog}
        onHide={() => setShowEditVaultRecordDialog(false)}
        aria-labelledby="modal-edit-vault-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-vault-record">Edit Vault Record</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditVaultRecordSubmit}>
            <Form.Group className="mb-3" controlId="formVaultRecordName">
              <Form.Label>Vault Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter a vault name" autoComplete="off" defaultValue={selectedVaultRecord.name} />
              <Form.Control.Feedback type="invalid">Please enter a vault name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" maxLength="100" placeholder="Enter a vault record username" autoComplete="off" defaultValue={selectedVaultRecord.username} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control type={showPassword? "text": "password"} maxLength="100" placeholder="Enter a vault record password" autoComplete="new-password" value={password} onChange={onChangePassword} />
              <button className='see' onClick={handleTogglePasswordEdit}><Icon.EyeFill size={22} /></button>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control as="textarea" rows={6} maxLength="500" placeholder="Notes" defaultValue={selectedVaultRecord.notes} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formVaultRecordFiles">
              <Form.Label>Files</Form.Label>
              <Form.Control type="file" multiple="multiple" onChange={(e)=> setUploadFileList(e.target.files)} accept=".jpg, .png, .pdf, .docx, .xlsx" />
            </Form.Group>
            <div className='file-list'>
              {fileList && fileList.map((file, i) => {
                if (file.toLowerCase().slice(file.lastIndexOf(".")) === ".pdf") {
                  return (
                      <div key={i}><Button type="button" onClick={() => deleteVaultRecordUploadOnClick(file.replace(/^.*[\\/]/, ''))}><Icon.X size={35}/></Button><a href={file} target='_blank'><Icon.FiletypePdf size={35}/>{file.replace(/^.*[\\/]/, '')}</a></div>
                  )
                }
                else if (file.toLowerCase().slice(file.lastIndexOf(".")) === ".docx") {
                  return (
                      <div key={i}><Button type="button" onClick={() => deleteVaultRecordUploadOnClick(file.replace(/^.*[\\/]/, ''))}><Icon.X size={35}/></Button><a href={file} target='_blank'><Icon.FiletypeDocx size={35}/>{file.replace(/^.*[\\/]/, '')}</a></div>
                  )
                }
                else if (file.toLowerCase().slice(file.lastIndexOf(".")) === ".xlsx") {
                  return (
                      <div key={i}><Button type="button" onClick={() => deleteVaultRecordUploadOnClick(file.replace(/^.*[\\/]/, ''))}><Icon.X size={35}/></Button><a href={file} target='_blank'><Icon.FiletypeXlsx size={35}/>{file.replace(/^.*[\\/]/, '')}</a></div>
                  )
                }
                else if (file.toLowerCase().slice(file.lastIndexOf(".")) === ".jpg") {
                  return (
                      <div key={i}><Button type="button" onClick={() => deleteVaultRecordUploadOnClick(file.replace(/^.*[\\/]/, ''))}><Icon.X size={35}/></Button><a href={file} target='_blank'><Icon.FiletypeJpg size={35}/>{file.replace(/^.*[\\/]/, '')}</a></div>
                  )
                }
                else if (file.toLowerCase().slice(file.lastIndexOf(".")) === ".png") {
                  return (
                      <div key={i}><Button type="button" onClick={() => deleteVaultRecordUploadOnClick(file.replace(/^.*[\\/]/, ''))}><Icon.X size={35}/></Button><a href={file} target='_blank'><Icon.FiletypePng size={35}/>{file.replace(/^.*[\\/]/, '')}</a></div>
                  )
                }
              })}
            </div>
            {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }

            <Button variant="primary" type="submit" disabled={isUploading}>Save</Button>&nbsp;
            <Button variant="secondary" type="button" disabled={isUploading} onClick={handleEditVaultRecordClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' disabled={isUploading} onClick={() => setShowDeleteVaultRecordDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteVaultRecordDialog}
        onHide={() => setShowDeleteVaultRecordDialog(false)}
        aria-labelledby="modal-delete-vault-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete-vault-record">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteVaultRecordDialog && <div><p>{selectedVaultRecord.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleVaultRecordDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteVaultRecordClose}>No</Button>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteVaultRecordUploadDialog}
        onHide={() => setShowDeleteVaultRecordUploadDialog(false)}
        aria-labelledby="modal-delete-vault-record"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete-vault-record-upload">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteVaultRecordUploadDialog && <div><p>{selectedVaultRecordUpload}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleVaultRecordUploadDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteVaultRecordUploadClose}>No</Button>
        </Modal.Body>
      </Modal>

    </div>
  );
}