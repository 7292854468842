import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchBudgetExpenses, fetchBudgetIncome, addBudget, editBudget, deleteBudget, fetchBudgetMortgages, addBudgetMortgage, editBudgetMortgage, deleteBudgetMortgage  } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useBudgetExpenses = () => {
  const query = useQuery(["get-budget-expenses"], fetchBudgetExpenses, {select: (data) => data,});
  checkUserAuthorizedQuery(query);
  return query;
};
export const useBudgetIncome = () => {
  return useQuery(["get-budget-income"], fetchBudgetIncome, {select: (data) => data,});
};
export const useAddBudget = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-budget"], {
    mutationFn: (data) => addBudget(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-budget-expenses'])
      queryClient.invalidateQueries(['get-budget-income'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-budget"]);
};

export const useEditBudget= () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-budget"], {
    mutationFn: (data) => editBudget(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-budget-expenses'])
      queryClient.invalidateQueries(['get-budget-income'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-budget"]);
};

export const useDeleteBudget = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-budget"], {
    mutationFn: (data) => deleteBudget(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-budget-expenses'])
      queryClient.invalidateQueries(['get-budget-income'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-budget"]);
};

export const useBudgetMortgages = () => {
  return useQuery(["get-budget-mortgages"], fetchBudgetMortgages, {select: (data) => data,});
};

export const useAddBudgetMortgage = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-budget-mortgage"], {
    mutationFn: (data) => addBudgetMortgage(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-budget-mortgages'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-budget-mortgage"]);
};

export const useEditBudgetMortgage = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-budget-mortgage"], {
    mutationFn: (data) => editBudgetMortgage(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-budget-mortgages'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-budget-mortgage"]);
};

export const useDeleteBudgetMortgage = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-budget-mortgage"], {
    mutationFn: (data) => deleteBudgetMortgage(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-budget-mortgages'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-budget-mortgage"]);
};