import {currency} from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';

export { SidePanelRow };

function SidePanelRow(props) {
  return (
    <div id={props.obj && props.hideCost && props.obj.id} className={"panel-side-row " + props.type} onClick={(e)=>props.sendHandleTransaction(e, props.obj)} >
        <div className="panel-side-title">{props.type === "panel-side-type-account" ? (<span><Icon.Coin size={20} /></span>):""}{props.title}</div>
        {!props.hideCost ? (<div className="panel-side-amount">{currency(props.amount)}</div>):""}
    </div>
  );
}