import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getUser } from 'helpers/current-user';
import FullCalendar from "@fullcalendar/react";
import daygridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid'
import { useUploadToLocation, useLocations, useEditLocation } from 'hooks/locations.hook';
import * as Icon from 'react-bootstrap-icons';
import { dateTime, date, time24to12_2d } from 'helpers/formatter';

export { ManageProfileLocationHistoryCalendar };

function ManageProfileLocationHistoryCalendar() {
  const [validated, setValidated] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [user] = useState(getUser());
  const uploadToLocation = useUploadToLocation();
  const [locations, setlocations] = useState([]);
  const [showLocationDialog, setShowLocationDialog] = useState(false);
  const [showLocationsDialog, setShowLocationsDialog] = useState(false);
  const [showEditLocationName, setShowLocationName] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({});
  const [events, setEvents] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState([])
  const [yearRange, setYearRange] = useState([]);
  const editLocationMutation = useEditLocation();

  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState("0" + (new Date().getMonth() + 1).toString().slice(-2));

  const handleEditLocationClose = () => { setShowLocationDialog(false); }
  const handleEditLocationNameClose = () => { 
    setShowLocationName(false);
  }

  const { data: locationsData, isError: locationsIsError, error: locationsError, isLoading: locationsIsLoading } = useLocations();
  useEffect(() => {
    if (locationsData !== undefined) {
      setTimeout(function () {
        setlocations(locationsData);
        if (events.length === 0) {
          for (var i in locationsData) {
            let title = locationsData[i].address;
            if (locationsData[i].name != null) {
              if (locationsData[i].name.length > 0) {
                title = locationsData[i].name + " - " + title;
              }
            }
            let start = new Date(locationsData[i].durationStart);
            start = start.setHours(start.getHours() - 5);
            let end = new Date(locationsData[i].durationEnd);
            end = end.setHours(end.getHours() - 5);
            events.push({ 'id': locationsData[i].id, 'start': start, 'end': end, 'title': title, 'description': locationsData[i].address });
          }
        }
      }, 500);

      var currentYear = new Date().getFullYear(), years = [];
      var startYear = 2010;  
      while (startYear <= currentYear) {
          years.push(currentYear--);
      }   
      setYearRange(years);
    }
  }, [locationsData, events]);

  const handleJsonUpload = (files) => {
    let hasUploads = false;
    let timeout = 0;
    let formData = new FormData();
    formData.append(`UserId`, user.id);
    formData.append(`Filter`, selectedYear + "-" + selectedMonth);
    [...files].forEach((file, i) => {
      hasUploads = true;
      formData.append(`Files[${i}].Id`, user.id);
      formData.append(`Files[${i}].File`, file);
    });
    if (hasUploads) {
      setTimeout(function () {
        uploadToLocation.mutate({
          formData: formData
        });
        timeout = 20000;
        setIsUploading(true);
        setTimeout(function () {
          window.location.reload();
        }, timeout);
      }, 500);
    }
  };

  const viewLocationOnClick = (e) => {
    if (e.event !== undefined) {
      setShowLocationName(false);
      let l = locations.filter((item) => item.id === e.event.id);
      if (l && l.length > 0) {
        setSelectedLocation(l[0]);
      }
      setShowLocationDialog(true);
      //window.open("https://www.google.com/maps/place/" + e.event._def.extendedProps.description);
    }
  }

  const handleEditLocationSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      let l = selectedLocation;
      l.name = form.formLocationName.value;
      setSelectedLocation(l);
      editLocationMutation.mutate({
        id: selectedLocation.id,
        name: form.formLocationName.value,
      });

      const newEvents = events.map((e) => {
        if (e.id === selectedLocation.id) {
            let title = l.address;
            if (l.name != null) {
              if (l.name.length > 0) {
                title = l.name + " - " + title;
              }
            }
            let start = new Date(selectedLocation.durationStart);
            start = start.setHours(start.getHours() - 5);
            let end = new Date(selectedLocation.durationEnd);
            end = end.setHours(end.getHours() - 5);
            return { ...e, id: selectedLocation.id, start: start, end: end, title: title, description: selectedLocation.address };
        }
        else {
            return e;
        }
      });

      setEvents([]);
      setTimeout(function () {
          setEvents(newEvents);
      }, 500);

      setShowLocationName(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };
  const calendarChangeOnClick = (e) => {
    let start = new Date(e.start);
    start = start.setHours(start.getHours() + 12);
    let end = new Date(e.end);
    end = end.setHours(end.getHours() + 12);
    setFilteredLocations(locations.filter(item => new Date(item.durationStart) >= start && new Date(item.durationStart) <= end).sort((a, b) => a.durationStart > b.durationStart ? 1 : -1)
    .map(item => { 
      let i = item;
      if (i.name === null) {
        i.name = i.address;
      }
      return i; 
    }));   
  }

  const formatTrProps = (state = {}) => {
    return { onClick: () => viewLocationOnClick({'event':{ 'id': state.original.id, 'start': state.original.start, 'end': state.original.end, 'title': state.original.title, 'description': state.original.address }}) };
  };
  const columns = useMemo(() => [
    {
        Header: "Location",
        accessor: "name",
    },
    {
        Header: "Date",
        accessor: "durationStart",
        Cell: ({ row }) => (
            date(new Date(row.original.durationStart).setHours(new Date(row.original.durationStart).getHours() - 5))
        )
    },
    {
        Header: "Time",
        accessor: "timeStart",
        Cell: ({ row }) => (
            <div>
                {!row.original.durationStart.includes("00:00:00") && (time24to12_2d(new Date(row.original.durationStart).setHours(new Date(row.original.durationStart).getHours() - 5)))}
            </div>
        )
    }
], []);

  return (
    <div>
      <p><Icon.CloudArrowUpFill size={30}/>&nbsp; Upload Timeline Data: <span className='directory'><Icon.Android/> Settings - Location - Location Services - Timeline - Export Timeline Data</span></p>
      <Form.Group className="mb-3" controlId="formFile">
        <div className='row'>
          <div className='col-sm-2'>
            <Form.Select defaultValue={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
              {yearRange && yearRange.map((m) => { return <option key={m} value={m}>{m}</option>})}
            </Form.Select>
          </div>
          <div className='col-sm-2'>
            <Form.Select defaultValue={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
              <option key={1} value={'01'}>January</option>
              <option key={2} value={'02'}>February</option>
              <option key={3} value={'03'}>March</option>
              <option key={4} value={'04'}>April</option>
              <option key={5} value={'05'}>May</option>
              <option key={6} value={'06'}>June</option>
              <option key={7} value={'07'}>July</option>
              <option key={8} value={'08'}>August</option>
              <option key={9} value={'09'}>September</option>
              <option key={10} value={'10'}>October</option>
              <option key={11} value={'11'}>November</option>
              <option key={12} value={'12'}>December</option>
            </Form.Select>
          </div>
          <div className='col-sm-8'>
            <Form.Control type="file" accept=".json" onChange={(e) => { handleJsonUpload(e.target.files); }} />
          </div>
        </div>
      </Form.Group>
      <hr/>
      {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
      {locationsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
      {events && events.length > 0 &&
        <div className='calendar-container'>
          <p>&nbsp;</p>
          <div className='row'>
            <div className='col-lg-8 no-padding-lg text-left'>
              <FullCalendar
                headerToolbar={{
                  start: "today prev next",
                  end: "dayGridMonth timeGridWeek timeGridDay",
                  center: "title"
                }}
                events={events}
                plugins={[daygridPlugin, timeGridPlugin]}
                views={["dayGridMonth", "timeGridWeek", "timeGridDay"]}
                eventClick={(e) => viewLocationOnClick(e)}
                datesSet={(e) => calendarChangeOnClick(e)}
              />
              <p>&nbsp;</p>
            </div>
            <div className='no-padding-lg col-lg-4'>
            {filteredLocations && <TableBasicClick responsive columns={columns} data={filteredLocations} formatRowProps={(state) => formatTrProps(state)} />}
            {locationsIsError && <div className="text-danger">Error loading cards: {locationsError.message}</div>}
            </div>
          </div>
        </div>
      }


      <Modal
          size="xl"
          show={showLocationDialog}
          onHide={handleEditLocationClose}
          aria-labelledby="modal-view-location"
          dialogClassName='modal-full-height'
      >
          <Modal.Header closeButton closeVariant='white'>
              <Modal.Title id="modal-view-location">
                  Map
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a className="btn-card-viewer" href={`https://www.google.com/maps/place/"${selectedLocation.address}'`} target='_blank' rel="noreferrer"><Icon.WindowFullscreen size={20} /></a>
            <h2>{selectedLocation.name} <button className="button-text" onClick={() => setShowLocationName(true)}><Icon.ThreeDots size={40} /></button></h2>
            {showEditLocationName &&
              <div>
                <Form noValidate validated={validated} onSubmit={handleEditLocationSubmit}>
                  <Form.Group className="mb-3" controlId="formLocationName">
                    <Form.Label>Location Name</Form.Label>
                    <Form.Control required type="text" maxLength="50" placeholder="Enter a location name" autoComplete="off" defaultValue={selectedLocation.name} />
                    <Form.Control.Feedback type="invalid">Please enter a location name.</Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="primary" type="submit">Save</Button>&nbsp;
                  <Button variant="secondary" type="button" onClick={handleEditLocationNameClose}>Cancel</Button>
                </Form>
                <p></p>
              </div>
            }
            <hr/>
            {selectedLocation.address !== selectedLocation.name && <h4>{selectedLocation.address}</h4>}
            <p></p>
            <p><strong>{dateTime(new Date(selectedLocation.durationStart).setHours(new Date(selectedLocation.durationStart).getHours() - 5))}</strong> to <strong>{dateTime(new Date(selectedLocation.durationEnd).setHours(new Date(selectedLocation.durationEnd).getHours() - 5))}</strong> 
            {locations && locations.filter(item => item.address === selectedLocation.address).length > 1 ? <button className='map-history-btn' onClick={() => setShowLocationsDialog(!showLocationsDialog)}><Icon.ClockFill size={18}/></button> : <></> }</p>
            <p></p>
            {selectedLocation.address && selectedLocation.address.length > 0 && <iframe style={{width:"100%",height:"100%"}} src={'https://maps.google.com/maps?q=' + selectedLocation.address.replace("#", "") + '&t=&z=16&ie=UTF8&iwloc=&output=embed'}></iframe> }
            <div className='bottom-border'></div>
          </Modal.Body>
      </Modal>

      <Modal
          size="lg"
          show={showLocationsDialog}
          onHide={() => setShowLocationsDialog(false)}
          aria-labelledby="modal-view-locations"
      >
          <Modal.Header closeButton closeVariant='white'>
              <Modal.Title id="modal-view-locations">
                  {selectedLocation.name && selectedLocation.name.length > 0 ? <>{selectedLocation.name}</> : <>{selectedLocation.address}</>}
              </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h5 className='text-center'>{selectedLocation.name && selectedLocation.name.length > 0 ? <>{selectedLocation.address}</> : <></> }</h5>
            <hr/>
            {locations && locations.filter(item => item.address === selectedLocation.address).sort((a, b) => a.durationStart < b.durationStart ? 1 : -1).map(loc => {
              let start = new Date(loc.durationStart);
              start = start.setHours(start.getHours() - 5);
              let end = new Date(loc.durationEnd);
              end = end.setHours(end.getHours() - 5);
              return <div key={loc.id}><div className='map-date-light text-center'>{dateTime(start)} to {dateTime(end)}</div><hr/></div>
            })}
            <p></p>
            <Button variant="secondary" type="button" onClick={() => setShowLocationsDialog(false)}>Close</Button>

          </Modal.Body>
      </Modal>


    </div>
  );
}