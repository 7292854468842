import React from 'react';
import * as Icon from 'react-bootstrap-icons';
import { OrganizationalChart} from 'sections/org/OrganizationalChart';
import { useAddDefaultNetwork } from 'hooks/networks.hook';
export { ManageNetworkTree };

function ManageNetworkTree(props) {
  const addNetworkMutation = useAddDefaultNetwork();

  const handleAddNetwork = (event) => {
    addNetworkMutation.mutate({});
    event.preventDefault();
    event.stopPropagation();
  };

  return (
    <>
        <h1><Icon.People size={35}/> Network Tree</h1>
        <p>{props.networks && props.networks.length < 1 && <button className='btn btn-primary' onClick={handleAddNetwork}><Icon.GooglePlay size={20}/> Start</button>}</p>
        <OrganizationalChart data={props.networks} selectedNetwork={props.selectedNetwork} resetSelectedNetwork={props.resetSelectedNetwork} />
    </>
  );
}