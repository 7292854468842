import React, { useState, useEffect } from 'react';
import { SidePanelRow } from 'components';
import { ManageInventories } from 'sections';
import { useInventories, useAddInventory } from 'hooks/inventories.hook';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { highlightInventory } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';

export { Inventories };

function Inventories() {
    const [validated, setValidated] = useState(false);
    const [showAddInventoryDialog, setShowAddInventoryDialog] = useState(false);

    const [manageInventories, setManageInventories] = useState(false);
    const [dashboard, setDashboard] = useState(true);
    
    const [selectedInventory, setSelectedInventory] = useState({});

    const { data: inventoriesData, isError:inventoriesIsError, error:inventoriesError, isLoading:inventoriesIsLoading } = useInventories();
    const [inventories, setInventories] = useState([]);
    useEffect(() => { setInventories(inventoriesData);}, [inventoriesData]);

    const handleAddInventoryClose = () => setShowAddInventoryDialog(false);

    const addInventoryMutation = useAddInventory();

    const resetContent = () => {
        //reset
        setDashboard(false);
        setManageInventories(false);
    }

    const handleInventory=(e, obj) =>{
        if (obj) {
            resetContent();
            setSelectedInventory(obj);
            setManageInventories(true);
            highlightInventory(e);
        }
    }

    const handleAddInventorySubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addInventoryMutation.mutate({
            name: form.formInventoryName.value,
          });
    
          setShowAddInventoryDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
      };

    return (
        <div>
            <div className="panel-side">
                <div className="panel-side-row">
                    <button className='btn btn-primary' onClick={() => setShowAddInventoryDialog(true)}><Icon.Plus size={20}/> Add Inventory</button>
                    &nbsp; &nbsp;
                </div>
                {inventories && inventories.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((inventory) => {
                    return (
                        <SidePanelRow key={inventory.id} obj={inventory} title={<span><Icon.ClipboardCheck size={20}/>&nbsp;&nbsp;{inventory.name}</span>} amount={0} type="panel-side-type-board" sendHandleTransaction={handleInventory}  hideCost={true}/>
                    )
                })}
                {inventoriesIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                {inventoriesIsError && <div className="text-danger">Error loading inventories: {inventoriesError.message}</div>}     
            </div> 
            <div className="panel-content">
                {dashboard && (
                    <div className='text-center-mobile home-nav home-nav'>
                    <h1><Icon.ClipboardCheck size={30}/> Inventory</h1>
                    {inventories && inventories.sort((a, b) => a.name > b.name ? 1 : -1).map((inventory) => {
                        return (
                            <Button key={inventory.id} id={inventory.id} onClick={(e) => handleInventory(e, inventory)}><span><Icon.ClipboardCheck size={60} /></span>{inventory.name}</Button>
                        )
                    })}
                    </div>
                )}
                {manageInventories && (
                    <ManageInventories selectedInventory={selectedInventory} inventoriesData={inventories} inventoriesError={inventoriesError} inventoriesIsError={inventoriesIsError} inventoriesIsLoading={inventoriesIsLoading} />
                )}
            </div>

            <Modal
                size="lg"
                show={showAddInventoryDialog}
                onHide={() => setShowAddInventoryDialog(false)}
                aria-labelledby="modal-add-inventory"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-inventory">Add Inventory</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAddInventorySubmit}>
                        <Form.Group className="mb-3" controlId="formInventoryName">
                            <Form.Label>Inventory Name</Form.Label>
                            <Form.Control required type="text" maxLength="50" placeholder="Enter a inventory name" />
                            <Form.Control.Feedback type="invalid">Please enter a inventory name.</Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={handleAddInventoryClose}>Cancel</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
}
