import React, { useState, useEffect } from 'react';
import * as Icon from 'react-bootstrap-icons';
import { useBoardListCard, useEditBoardListCardNetwork} from 'hooks/boards.hook';
import { useBoardListCardNetworks } from 'hooks/networks.hook';
import { getUser } from 'helpers/current-user';
import DetailCard from "sections/org/detailCard";

export { ManageBoardNetworks };

function ManageBoardNetworks(props) {
  const [user] = useState(getUser());
  const [invites, setInvites] = useState([]);
  const [boardListCard, setBoardListCard] = useState([]);
  const [pending, setPending] = useState(0);
  const [going, setGoing] = useState(0);
  const [declined, setDeclined] = useState(0);
  const editBoardListCardNetworkMutation = useEditBoardListCardNetwork();
  const [selectedNetwork, setSelectedNetwork] = useState({});
  const [cardShow, setCardShow] = useState(false);

  const { data: boardListCardData, isError: boardListsIsError } = useBoardListCard(props.selectedBoardListCard.id);
  useEffect(() => { 
    if (boardListCardData !== undefined) {
      setBoardListCard(boardListCardData); 
    }
  }, [boardListCardData]);

  const { data: networksData } = useBoardListCardNetworks(boardListCard.id);
  useEffect(() => { 
      if (networksData !== undefined && networksData !== 0) {
        if (boardListCard !== undefined && boardListCard.boardListCardNetworks !== undefined && boardListCard.boardListCardNetworks.length > 0) {
          let going_counter = 0;
          let pending_counter = 0;
          let declined_counter = 0;

          let invitesTemp = boardListCard.boardListCardNetworks.map(item => networksData.filter(i => i.id === item.networkId));
            
          let invitesFixed = [];
          for (var i in invitesTemp) {
            if (invitesTemp[i][0] === undefined) { continue; }

            invitesTemp[i][0].statusId = boardListCard.boardListCardNetworks.filter(item => item.networkId === invitesTemp[i][0].id)[0].statusId;
            invitesTemp[i][0].boardNetworkId = boardListCard.boardListCardNetworks.filter(item => item.networkId === invitesTemp[i][0].id)[0].id;
            invitesTemp[i][0].userId = boardListCard.boardListCardNetworks.filter(item => item.networkId === invitesTemp[i][0].id)[0].userId;
            invitesFixed.push(invitesTemp[i][0]);

            switch (invitesTemp[i][0].statusId) {
              case 2: pending_counter++; break;
              case 3: going_counter++; break;
              case 4: declined_counter++; break;
            }
          }

          setPending(pending_counter);
          setGoing(going_counter);
          setDeclined(declined_counter);

          for (var i in invitesFixed.sort((a, b) => a.lastName > b.lastName ? 1 : -1).sort((a, b) => a.firstName > b.firstName ? 1 : -1)) {
              invitesFixed[i].order = parseInt(i) + 1;
          }

          if (user === null || (invitesFixed.length > 0 && user.id !== invitesFixed[0].userId && invitesFixed[0].statusId === 1)) {
            invitesFixed = invitesFixed.filter(item => item.statusId > 1).sort((a, b) => a.order > b.order ? 1 : -1);
          }
              
          setInvites(invitesFixed.sort((a, b) => a.order > b.order ? 1 : -1));
        }
      }
  }, [networksData, boardListCard, invites, pending, going, declined]);

  const handleStatusChange = (network, statusId) => {
    if (statusId >= 1 && statusId <= 4 && network.boardNetworkId !== null) {
      editBoardListCardNetworkMutation.mutate({
        id: network.boardNetworkId,
        statusId: statusId,
      });
    }
  };

  const handleShow = (network) => {
    setSelectedNetwork(network);
    setCardShow(true);
  };
  const handleClose = () => { setCardShow(false); setSelectedNetwork({}); };
  const handleReopen = (newNetwork) => { 
    handleClose();
    setTimeout(function () {
      handleShow(newNetwork);
    }, 100);
  };

  return (
    <div>
        {boardListCard && <div>
          {invites !== undefined && invites.length !== 0 &&
            <div>
              <p>
                <span className='board-network-status status-summary status-attended'><Icon.Check/>{going}</span>
                {new Date(boardListCard.dateStart) >= new Date() && <span className='board-network-status status-summary status-pending'><Icon.QuestionLg/>{pending}</span>}
                <span className='board-network-status status-summary status-declined'><Icon.X/>{declined}</span>
              </p>
              <hr/>
              {invites.map(network => { return (
                <div className="board-network" key={network.id}>
                  <div>
                    <button className='network-link' onClick={() => {handleShow(network);}}>
                      {network.photoUrl.length === 0 ?
                          (
                              <div key={network.id} className="node-img-default"><Icon.Person size={45} /></div>
                          ) : (
                              <div key={network.id} className="node-img" style={{ backgroundImage: "url(" + network.photoUrl + ")" }}></div>
                          )
                      }
                    </button>
                    {network.statusId === 1 && <div className='board-network-status'>INVITE<Icon.Plus/></div>}
                    {network.statusId === 2 && <div className='board-network-status status-pending'>WAITING<Icon.QuestionLg/></div>}
                    {network.statusId === 3 && <div className='board-network-status status-attended'>GOING<Icon.Check/></div>}
                    {network.statusId === 4 && <div className='board-network-status status-declined'>DECLINED<Icon.X/></div>}
                  </div>
                  <div>
                    &nbsp;
                    <a target='_blank' className='invite-share' href={`${process.env.REACT_APP_BDC_URL}/event/${boardListCard.id}/${network.boardNetworkId}`}><Icon.ShareFill size={16}/></a> {network.firstName} {network.lastName} 
                    <br/>
                    {user !== null && user.id === network.userId &&
                      <>
                        <div className='board-network-shortcuts'>
                          {network.phone !== undefined && network.phone.length > 0 &&
                            <span>
                                <a href={"tel:" + network.phone} title={network.phone}><Icon.TelephoneFill size={18} /></a>
                                <a href={"sms:" + network.phone} title={network.phone}><Icon.ChatLeftFill size={18} /></a>
                                <a href={"https://wa.me/" + network.phone} target='_blank' title={network.phone}><Icon.Whatsapp size={18} /></a>
                            </span>
                          }
                          {network.email !== undefined && network.email.length > 0 &&
                              <a href={"mailto:" + network.email} title={network.email}><Icon.EnvelopeAtFill size={18} /></a>
                          }
                          {network.facebook !== undefined && network.facebook.length > 0 &&
                              <a href={"https://www.facebook.com/" + network.facebook} title={network.facebook} target='_blank'><Icon.Facebook size={18} /></a>
                          }
                          {network.instagram !== undefined && network.instagram.length > 0 &&
                              <a href={"https://www.instagram.com/" + network.instagram} title={network.instagram} target='_blank'><Icon.Instagram size={18} /></a>
                          }
                        </div>
                        <div className='invite-actions'>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network, 3); }}>Going<Icon.Check/></button>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network, 2); }}>Waiting<Icon.QuestionLg/></button>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network, 4); }}>Decline<Icon.X/></button>
                          <button className='btn status-result' onClick={() => { handleStatusChange(network, 1); }}>Cancel<Icon.X/></button>
                        </div>
                      </>
                    }
                  </div>
                </div>
              )})}
              <p></p>
            </div>
          }

        </div>}


        {cardShow && selectedNetwork && (
          <DetailCard
            networks={props.networks}
            network={props.networks.find((network) => network.id === selectedNetwork.id)}
            handleClose={handleClose}
            handleReopen={handleReopen}
          />
        )}
    </div>
  );
}