import { history } from 'helpers';

export const isUserInRole = (e) => {
  let result = false;
  const user = localStorage.getItem('user');
  if (user){
    const roles = JSON.parse(JSON.parse(user).roles);
    roles.map(role => { 
      if (role.RoleId === e) {
        result = true;
        return result;
      }
    });
  }
  return result;
};

export const getUser = () => {
  const user = localStorage.getItem('user');
  return JSON.parse(user);
}

export const checkUserAuthorizedQuery = (query) => {
  setTimeout(function() {
    if (query?.error?.response?.status === 401) {
      localStorage.removeItem('user');
      history.navigate('/login');
      window.location.reload();
    }
  }, 500);
};

export const isUserVerified = () => {
  const user = localStorage.getItem('user');
  if (JSON.parse(user).verified === "true"){
    return true;
  }
  return false;
};

export const updateUser = (user) => {
  localStorage.setItem('user', JSON.stringify(user));
};