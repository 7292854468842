import React, { useState, useEffect } from 'react';
import { SidePanelRow } from 'components';
import { ManageBoards } from 'sections';
import { useBoards, useAddBoard } from 'hooks/boards.hook';
import { useNetworks } from 'hooks/networks.hook';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { highlightBoard } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';

export { Boards };

function Boards() {
    const [validated, setValidated] = useState(false);
    const [showAddBoardDialog, setShowAddBoardDialog] = useState(false);

    const [manageBoards, setManageBoards] = useState(false);
    const [dashboard, setDashboard] = useState(true);
    const [networks, setNetworks] = useState([]);
    const [selectedBoard, setSelectedBoard] = useState({});

    const { data: boardsData, isError:boardsIsError, error:boardsError, isLoading:boardsIsLoading } = useBoards();
    const [boards, setBoards] = useState([]);
    useEffect(() => { setBoards(boardsData);}, [boardsData]);

    const { data: networksData } = useNetworks();
    useEffect(() => { 
        if (networksData !== undefined) {
            setNetworks(networksData.sort((a, b) => a.lastName > b.lastName ? 1 : -1).sort((a, b) => a.firstName > b.firstName ? 1 : -1));
        }
    }, [networksData]);

    const handleAddBoardClose = () => setShowAddBoardDialog(false);

    const addBoardMutation = useAddBoard();

    const resetContent = () => {
        //reset
        setDashboard(false);
        setManageBoards(false);
    }
    const handleBoard=(e, obj) =>{
        if (obj) {
            resetContent();
            setSelectedBoard(obj);
            setManageBoards(true);
            highlightBoard(e);
        }
    }

    const handleAddBoardSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addBoardMutation.mutate({
            name: form.formBoardName.value,
          });
    
          setShowAddBoardDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
      };

    return (
        <div>
            <div className="panel-side">
                <div className="panel-side-row">
                    <button className='btn btn-primary' onClick={() => setShowAddBoardDialog(true)}><Icon.Plus size={20}/> Add Board</button>
                    &nbsp; &nbsp;
                </div>
                {boards && boards.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((board) => {
                    return (
                        <SidePanelRow key={board.id} obj={board} title={<span><Icon.CardChecklist size={20}/>&nbsp;&nbsp;{board.name}</span>} amount={0} type="panel-side-type-board" sendHandleTransaction={handleBoard}  hideCost={true}/>
                    )
                })}
                {boardsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                {boardsIsError && <div className="text-danger">Error loading boards: {boardsError.message}</div>}     
            </div> 
            <div className="panel-content">
                {dashboard && (
                    <div className='text-center-mobile home-nav'>
                    <h1><Icon.CardChecklist size={30}/> Boards</h1>
                    {boards && boards.sort((a, b) => a.name > b.name ? 1 : -1).map((board) => {
                        return (
                            <Button key={board.id} id={board.id} onClick={(e) => handleBoard(e, board)}><span><Icon.CardChecklist size={60} /></span>{board.name}</Button>
                        )
                    })}
                    </div>
                )}
                {manageBoards && (
                    <ManageBoards selectedBoard={selectedBoard} boardsData={boards} boardsError={boardsError} boardsIsError={boardsIsError} boardsIsLoading={boardsIsLoading} networks={networks} />
                )}
            </div>

            <Modal
                size="lg"
                show={showAddBoardDialog}
                onHide={() => setShowAddBoardDialog(false)}
                aria-labelledby="modal-add-board"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-board">Add Board</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAddBoardSubmit}>
                        <Form.Group className="mb-3" controlId="formBoardName">
                            <Form.Label>Board Name</Form.Label>
                            <Form.Control required type="text" maxLength="50" placeholder="Enter a board name" />
                            <Form.Control.Feedback type="invalid">Please enter a board name.</Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={handleAddBoardClose}>Cancel</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
}
