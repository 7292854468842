import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchBoards, addBoard, editBoard, deleteBoard, fetchBoardLists, addBoardList, editBoardList, deleteBoardList, fetchBoardListCard, fetchBoardListCards, addBoardListCard, editBoardListCard, deleteBoardListCard, positionBoardList, positionBoardListCard, fetchScheduledBoardListCards, updateBoardListCardNetwork, editBoardListCardNetworks } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useBoards = () => {
  const query = useQuery(["get-boards"], fetchBoards, {select: (data) => data,});
  checkUserAuthorizedQuery(query);
  return query;
};

export const useAddBoard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-board"], {
    mutationFn: (data) => addBoard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-boards'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-board"]);
};

export const useEditBoard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-board"], {
    mutationFn: (data) => editBoard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-boards'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-board"]);
};

export const useDeleteBoard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-board"], {
    mutationFn: (data) => deleteBoard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-boards'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-board"]);
};

export const useBoardLists = (boardId) => {
  return useQuery(["get-board-lists", boardId], fetchBoardLists, {select: (data) => data,});
};

export const useAddBoardList = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-board-list"], {
    mutationFn: (data) => addBoardList(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-board-list"]);
};

export const useEditBoardList = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-board-list"], {
    mutationFn: (data) => editBoardList(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-board-list"]);
};

export const useDeleteBoardList = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-board-list"], {
    mutationFn: (data) => deleteBoardList(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-board-list"]);
};

export const useBoardListCard = (id) => {
  return useQuery(["get-board-list-card", id], fetchBoardListCard, {select: (data) => data,});
};

export const useBoardListCards = () => {
  return useQuery(["get-board-list-cards"], fetchBoardListCards, {select: (data) => data,});
};


export const useAddBoardListCard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-board-list-card"], {
    mutationFn: (data) => addBoardListCard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-board-list-card"]);
};

export const useEditBoardListCard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-board-list-card"], {
    mutationFn: (data) => editBoardListCard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      //queryClient.invalidateQueries(['get-board-lists']); //called after updated boardNetworks
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-board-list-card"]);
};

export const useDeleteBoardListCard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-board-list-card"], {
    mutationFn: (data) => deleteBoardListCard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-board-list-card"]);
};

export const useDeleteBoardListCardHome = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-board-list-card-home"], {
    mutationFn: (data) => deleteBoardListCard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-scheduled-board-list-cards'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-board-list-card-home"]);
};

export const usePositionBoardList = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["position-board-list"], {
    mutationFn: (data) => positionBoardList(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["position-board-list"]);
};

export const usePositionBoardListCard = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["position-board-list-card"], {
    mutationFn: (data) => positionBoardListCard(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["position-board-list-card"]);
};

export const useScheduledBoardListCards = () => {
  return useQuery(["get-scheduled-board-list-cards"], fetchScheduledBoardListCards, {select: (data) => data,});
};

export const useEditBoardListCardNetwork = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-board-list-card-network"], {
    mutationFn: (data) => updateBoardListCardNetwork(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-list-card'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-board-list-card-network"]);
};

export const useEditBoardListCardNetworks = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-board-list-card-networks"], {
    mutationFn: (data) => editBoardListCardNetworks(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-board-lists']);
      queryClient.invalidateQueries(['get-board-list-card']);     
      queryClient.invalidateQueries(['get-networks2']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-board-list-card-networks"]);
};