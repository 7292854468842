import React, {useState} from 'react'
import {Form, ListGroup, Spinner} from 'react-bootstrap';
import { capitalizeWords } from 'helpers/formatter';

export function TypeAhead(props) {
   const mockResults = (keyword) => {
    return new Promise((res, rej) => {
      setTimeout(() => {
        const searchResults = props.data.filter(item => item.toLowerCase().includes(keyword.toLowerCase()));
        res(searchResults);
      }, 500);
    });
  };

  const [results, setResults] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState(props.value);
  const [isNameSelected, setIsNameSelected] = useState(false);

  const handleInputChange = (e) => {
    const nameValue = e.target.value;
    setName(capitalizeWords(nameValue));
    // even if we've selected already an item from the list, we should reset it since it's been changed
    setIsNameSelected(false);
    // clean previous results, as would be the case if we get the results from a server
    setResults([]);
    if (nameValue.length > 1) {
      setIsLoading(true);
      mockResults(nameValue)
        .then((res) => {
          setResults(res);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  const onNameSelected = (selectedName) => {
    setName(selectedName);

    if (typeof props.lookUpCategory === "function") {
      props.lookUpCategory(selectedName);
    }
    setIsNameSelected(true);
    setResults([]);
  };
 
  return (
    <Form.Group controlId={props.controlId} className="mb-3 typeahead-form-group">
      <Form.Label>{props.label}</Form.Label>
      <Form.Control
        type="text"
        autoComplete="off"
        onChange={handleInputChange}
        value={name}
        required={props.required}
        placeholder={props.placeholder}
        maxLength={props.maxLength}
      />
      <Form.Control.Feedback type="invalid">{props.feedback}</Form.Control.Feedback>
      <ListGroup className="typeahead-list-group">
        {!isNameSelected &&
          results.length > 0 &&
          results.map((result, index) => (
            <ListGroup.Item
              key={index}
              className="typeahead-list-group-item"
              onClick={() => onNameSelected(result)}
            >
              {result}
            </ListGroup.Item>
          ))}
        {!results.length && isLoading && (
          <div className="typeahead-spinner-container">
            <Spinner animation="border" />
          </div>
        )}
      </ListGroup>
    </Form.Group>
  );
}