import React, { useState, useEffect, useMemo } from 'react';
import { useNetworks } from 'hooks/networks.hook';
import * as Icon from 'react-bootstrap-icons';
import { TableBasicClick } from 'components';
import { utils, writeFile } from 'xlsx';
import { date, phone } from 'helpers/formatter';
import { ManageNetworkTree } from 'sections/ManageNetworkTree';
import { ManageNetworkMap } from 'sections/ManageNetworkMap';

export { Network };

function Network() {

  const [selectedNetwork, setSelectedNetwork] = useState("");
  const [networks, setNetworks] = useState([]);
  const [filteredNetworks, setFilteredNetworks] = useState([]);
  const [manageTree, setManageTree] = useState(true);
  const [manageMap, setManageMap] = useState(false);
  const { data: networksData, error: networksError, isError: networksIsError, isLoading: networksIsLoading } = useNetworks();
  useEffect(() => { 
    if (networksData !== undefined) {
      setNetworks(networksData); 
      setFilteredNetworks(networksData.filter(item => item.team === "" && !item.unlist).sort((a, b) => a.lastName > b.lastName ? 1 : -1).sort((a, b) => a.firstName > b.firstName ? 1 : -1));
    }
  }, [networksData]);

  const handleContent = (e) => {
    resetContent();
      switch (e) {
          case "tree":
            setManageTree(true);
            break;
          case "map":
            setManageMap(true);
            break;
          default:
            break;
      }
  }

  const resetSelectedNetwork = () => { setSelectedNetwork(""); }

  const resetContent = () => {
    //reset
    setSelectedNetwork("");
    setManageTree(false);
    setManageMap(false);
  }

  const handleExportToGoogle = (event) => {
    const format = [];
    const contacts = networks.filter(item => item.team === "" && (item.unlist === false || item.phone.length > 1 || item.email.length > 1));
    contacts.map(item => {
      const birthDate = date(item.birthDate) === '1900-01-01' ? '' : date(item.birthDate);
      format.push({ 
        'Name': `${item.firstName} ${item.lastName}`,
        'Given Name': item.firstName,
        'Family Name': item.lastName,
        "Birthday": birthDate,
        'Group Membership': '* myContacts',
        'E-mail Type': '* Home',
        'E-mail Value': item.email,
        'Phone 1 - Type': 'Mobile',
        'Phone 1 - Value': phone(item.phone),
        'Address 1 - Formatted': item.location,
      });
    });

    const headings = [[
      'Name',
      'Given Name',
      'Family Name',
      'Birthday',
      'Group Membership', //* myContacts
      'E-mail Type', //* Home
      'E-mail Value',
      'Phone 1 - Type', //Mobile
      'Phone 1 - Value',
      'Address 1 - Formatted'
    ]];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, format, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'contacts');
    writeFile(wb, `network=export-google-${date(Date.now())}.csv`);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleExportToMicrosoft = (event) => {
    const format = [];
    const contacts = networks.filter(item => item.team === "" && (item.unlist === false || item.phone.length > 1 || item.email.length > 1));
    contacts.map(item => {
      const birthDate = date(item.birthDate) === '1900-01-01' ? '' : date(item.birthDate);
      const anniversary = date(item.anniversary) === '1900-01-01' ? '' : date(item.anniversary);

      format.push({ 
        'NickName': `${item.firstName} ${item.lastName}`,
        'First Name': item.firstName,
        'Last Name': item.lastName,
        "Birthday": birthDate,
        'E-mail Address': item.email,
        'Mobile Phone': phone(item.phone),
        'Home Street': item.location,
        'Anniversary': anniversary,
      });
    });

    const headings = [[
      'NickName',
      'First Name',
      'Last Name',
      'Birthday',
      'E-mail Address',
      'Mobile Phone',
      'Home Street',
      'Anniversary'
    ]];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, format, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'contacts');
    writeFile(wb, `network-export-microsoft-${date(Date.now())}.csv`);
    event.preventDefault();
    event.stopPropagation();
  };

  const formatTrProps = (state = {}) => {
    return { onClick: () => {
      setSelectedNetwork(state.original);
     }};
  };
  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "id",
        Cell: ({ row }) => (
          <>
            {row.original.photoUrl.length === 0 ? (
              <div className="side-img-default"><Icon.Person size={35} /></div>
            ) : (
              <div className="side-img" style={{ backgroundImage: "url(" + row.original.photoUrl + ")" }}></div>
            )}
          </>
        )
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "",
        accessor: "fullName",
      },
    ],
    []
  );

  return (
    <div>
        <div className="panel-side network-panel">
          <div className="panel-side-row toggle-desktop">
                <button className='btn btn-primary' onClick={()=> handleContent("tree")}><Icon.People size={20}/> Tree</button>
                &nbsp; &nbsp;
                <button className='btn btn-primary' onClick={()=> handleContent("map")}><Icon.GeoAltFill size={20}/> Map</button>
          </div>
            {networks && <TableBasicClick responsive columns={columns} data={filteredNetworks} formatRowProps={(state) => formatTrProps(state)} hiddenColumns={['fullName']} />}
            {networksIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
            {networksIsError && <div className="text-danger">Error loading networks: {networksError.message}</div>}     
            <div className="panel-side-row">
                <button className='btn btn-primary' onClick={(e)=> handleExportToGoogle(e)}><Icon.ArrowUpSquare size={20}/> Export to Google</button>
            </div>
            <div className="panel-side-row">
                <button className='btn btn-primary' onClick={(e)=> handleExportToMicrosoft(e)}><Icon.ArrowUpSquare size={20}/> Export to Microsoft</button>
            </div>
        </div> 
        <div className="panel-content">
          <div className="panel-side-row toggle-mobile">
                <button className='btn btn-primary' onClick={()=> handleContent("tree")}><Icon.People size={20}/> Tree</button>
                &nbsp; &nbsp;
                <button className='btn btn-primary' onClick={()=> handleContent("map")}><Icon.GeoAltFill size={20}/> Map</button>
          </div>
          <p className='toggle-mobile'></p>
          {manageTree && (
            <ManageNetworkTree networks={networks} selectedNetwork={selectedNetwork} resetSelectedNetwork={resetSelectedNetwork} />
          )}
          {manageMap && (
            <ManageNetworkMap networks={networks} selectedNetwork={selectedNetwork} resetSelectedNetwork={resetSelectedNetwork} />
          )}
        </div>
    </div>
  );
}