import React, { useState, useEffect, useMemo } from 'react';
import { TableBasicClick } from 'components';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { currency, date } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';
import { useEditAccount, useAddAccount, useDeleteAccount, useEraseAccount, useRestoreAccount, useBackup} from 'hooks/accounts.hook';

export { ManageAccounts };

function ManageAccounts(props) {
  const [validated, setValidated] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEraseDialog, setShowEraseDialog] = useState(false);
  const [showRestoreDialog, setShowRestoreDialog] = useState(false);
  const [showRestoreConfirmDialog, setShowRestoreConfirmDialog] = useState(false);

  const [selectedAccount, setSelectedAccount] = useState({});
  const [selectedAccountId, setSelectedAccountId] = useState('00000000-0000-0000-0000-000000000000');
  const [selectedBackup, setSelectedBackup] = useState(0);

  //const { data: accountsData, error: accountsError, isError: accountsIsError, isLoading: accountsIsLoading } = useAccounts();
  const [accounts, setAccounts] = useState([]);
  useEffect(() => { setAccounts(props.accountsData); }, [props.accountsData]);

  //const { data: bankData } = useBanks();
  const [banks, setBanks] = useState([]);
  useEffect(() => { setBanks(props.bankData); }, [props.bankData]);

  //const { data: accountTypeData } = useAccountTypes();
  const [accountTypes, setAccountTypes] = useState([]);
  useEffect(() => { setAccountTypes(props.accountTypeData); }, [props.accountTypeData]);

  const { data: backupData } = useBackup(selectedAccountId);
  const [backups, setBackups] = useState([]);
  useEffect(() => { setBackups(backupData); }, [backupData]);

  const addAccountMutation = useAddAccount();
  const editAccountMutation = useEditAccount();
  const deleteAccountMutation = useDeleteAccount();
  const eraseAccountMutation = useEraseAccount();
  const restoreAccountMutation = useRestoreAccount();

  const handleAddClose = () => setShowAddDialog(false);
  const handleEditClose = () => setShowEditDialog(false);
  const handleDeleteClose = () => setShowDeleteDialog(false);
  const handleEraseClose = () => setShowEraseDialog(false);
  const handleRestoreClose = () => setShowRestoreDialog(false);
  const handleRestoreConfirmClose = () => setShowRestoreConfirmDialog(false);

  const handleDelete = () => {
    deleteAccountMutation.mutate({
      id: selectedAccount.id,
    });
    setShowDeleteDialog(false);
    setShowEditDialog(false);
  };

  const handleErase = () => {
    eraseAccountMutation.mutate({
      accountId: selectedAccount.id,
    });
    setShowEraseDialog(false);
    setShowEditDialog(false);
  };

  const handleRestore = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      setSelectedBackup(form.formBackup);
      setShowRestoreConfirmDialog(true);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleRestoreConfirm = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      restoreAccountMutation.mutate({
        id: selectedBackup.value,
      });

      setShowRestoreConfirmDialog(false);
      setShowRestoreDialog(false);
      setShowEditDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleAddSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      addAccountMutation.mutate({
        bankId: form.formAccountBank.value,
        accountTypeId: form.formAccountType.value,
        name: form.formAccountName.value,
      });

      setShowAddDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEditSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      editAccountMutation.mutate({
        id: selectedAccount.id,
        bankId: form.formAccountBank.value,
        accountTypeId: form.formAccountType.value,
        name: form.formAccountName.value,
      });

      setShowEditDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const editOnClick = (account) => {
    if (account != null) {
      setSelectedAccount(account);
      setShowEditDialog(true);
      setSelectedAccountId(account.id);
    }
  };

  const formatTrProps = (state = {}) => {
    return { onClick: () => editOnClick(state.original) };
  };
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Bank",
        accessor: "bank.name",
      },
      {
        Header: "Type",
        accessor: "accountType.name",
      },
      {
        Header: "Total",
        accessor: "total",
        Cell: ({ row }) => (
          <div className='text-right w-100'>
              {currency(row.original.total)}
          </div>
        )
      },
      {
        Header: "Transactions",
        accessor: "count",
        Cell: ({ row }) => (
          <div className='text-right w-100'>
            {row.original.count}
          </div>
        )
      },
    ],
    []
  );

  return (
    <div>
        <h1><Icon.GearFill size={30}/> Manage Accounts</h1>
        <p></p>
        <p><button className='btn btn-primary' onClick={() => setShowAddDialog(true)} ><Icon.Plus size={20}/> Add Account</button></p>

        {accounts && <TableBasicClick responsive columns={columns} data={accounts} formatRowProps={(state) => formatTrProps(state)} />}
        {props.accountsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
        {props.accountsIsError && <div className="text-danger">Error loading accounts: {props.accountsError.message}</div>}

        <Modal
          size="lg"
          show={showAddDialog}
          onHide={() => setShowAddDialog(false)}
          aria-labelledby="modal-add-account"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-add-account">Add Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleAddSubmit}>
              <Form.Group className="mb-3" controlId="formAccountBank">
                <Form.Label>Bank</Form.Label>
                <Form.Select>
                  {banks && (banks.map((m) => {return <option key={m.id} value={m.id}>{m.name}</option>}))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAccountType">
                <Form.Label>Type</Form.Label>
                <Form.Select>
                  {accountTypes && (accountTypes.map((m) => {return <option key={m.id} value={m.id}>{m.name}</option>}))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAccountName">
                <Form.Label>Account Name</Form.Label>
                <Form.Control required type="text" maxLength="100" placeholder="Enter an account name" />
                <Form.Control.Feedback type="invalid">Please enter an account name.</Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit">Save</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleAddClose}>Cancel</Button>
            </Form>
          </Modal.Body>
        </Modal>
        
        <Modal
          size="lg"
          show={showEditDialog}
          onHide={() => setShowEditDialog(false)}
          aria-labelledby="modal-edit-account"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-edit-account">Edit Account</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
              <Form.Group className="mb-3" controlId="formAccountBank">
                <Form.Label>Bank</Form.Label>
                <Form.Select defaultValue={selectedAccount.bankId}>
                  {banks && (banks.map((m) => {return <option key={m.id} value={m.id}>{m.name}</option>}))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAccountType">
                <Form.Label>Type</Form.Label>
                <Form.Select defaultValue={selectedAccount.accountTypeId}>
                  {accountTypes && (accountTypes.map((m) => {return <option key={m.id} value={m.id}>{m.name}</option>}))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formAccountName">
                <Form.Label>Account Name</Form.Label>
                <Form.Control required type="text" maxLength="100" placeholder="Enter an account name" defaultValue={selectedAccount.name} />
                <Form.Control.Feedback type="invalid">Please enter an account name.</Form.Control.Feedback>
              </Form.Group>
              <Button variant="primary" type="submit">Save</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleEditClose}>Cancel</Button>
              <Button variant="danger" className='modal-btn-right' onClick={()=> setShowDeleteDialog(true)}>Delete</Button>
              <Button variant="danger" className='modal-btn-right' onClick={()=> setShowEraseDialog(true)}>Erase</Button>
              <Button variant="warning" className='modal-btn-right' onClick={()=> setShowRestoreDialog(true)}>Restore</Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showDeleteDialog}
          onHide={() => setShowDeleteDialog(false)}
          aria-labelledby="modal-delete"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Are you sure you would like to delete:</p>
              {showDeleteDialog && <div><p>{selectedAccount.bank.name}</p><p>{selectedAccount.accountType.name}</p><p>{selectedAccount.name}</p></div>}
              <hr></hr>
              <p><strong>Warning: All {selectedAccount.count} transactions at a balance of {selectedAccount.total && currency(selectedAccount.total)} will be deleted. Continue?</strong></p>
              <Button variant="danger" type="submit" onClick={handleDelete}>Yes</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleDeleteClose}>No</Button>
          </Modal.Body>
        </Modal>

        <Modal
          show={showEraseDialog}
          onHide={() => setShowEraseDialog(false)}
          aria-labelledby="modal-erase"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-erase">Confirm Erase</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <p>Are you sure you would like to erase all {selectedAccount.count} transactions at a balance of {selectedAccount.total && currency(selectedAccount.total)}?</p>
              <Button variant="danger" type="submit" onClick={handleErase}>Yes</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleEraseClose}>No</Button>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={showRestoreDialog}
          onHide={() => setShowRestoreDialog(false)}
          aria-labelledby="modal-restore"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-restore">Restore</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>You will be restoring this account:</p>
            {showRestoreDialog && <div><p>{selectedAccount.bank.name}</p><p>{selectedAccount.accountType.name}</p><p>{selectedAccount.name}</p></div>}
            <hr></hr>
            <Form noValidate validated={validated} onSubmit={handleRestore}>
              <Form.Group className="mb-3" controlId="formBackup">
                <Form.Label>Restore Points</Form.Label>
                <Form.Select>
                  {backups && (backups.map((m) => {return <option key={m.id} value={m.id}>{date(m.created)}</option>}))}
                </Form.Select>
              </Form.Group>
              <Button variant="warning" type="submit">Restore</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleRestoreClose}>Cancel</Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Modal
          show={showRestoreConfirmDialog}
          onHide={() => setShowRestoreConfirmDialog(false)}
          aria-labelledby="modal-restore-confirm"
        >
          <Modal.Header closeButton closeVariant='white'>
            <Modal.Title id="modal-restore-confirm">Confirm Restore</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form noValidate validated={validated} onSubmit={handleRestoreConfirm}>
              <p>Are you sure you would like to restore?</p>
              <p>Any changes after this date will be lost.</p>
              <Button variant="warning" type="submit" onClick={handleRestoreConfirm}>Yes</Button>&nbsp;
              <Button variant="secondary" type="button" onClick={handleRestoreConfirmClose}>No</Button>
            </Form>
          </Modal.Body>
        </Modal>
    </div>
  );
}