import React, { useState, useEffect } from 'react';
import { SidePanelRow } from 'components';
import { ManageVaults } from 'sections';
import { useVaults, useAddVault } from 'hooks/vaults.hook';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { highlightVault } from 'helpers/formatter';
import * as Icon from 'react-bootstrap-icons';

export { Vaults };

function Vaults() {
    const [validated, setValidated] = useState(false);
    const [showAddVaultDialog, setShowAddVaultDialog] = useState(false);

    const [manageVaults, setManageVaults] = useState(false);
    const [dashboard, setDashboard] = useState(true);
    
    const [selectedVault, setSelectedVault] = useState({});

    const { data: vaultsData, isError:vaultsIsError, error:vaultsError, isLoading:vaultsIsLoading } = useVaults();
    const [vaults, setVaults] = useState([]);
    useEffect(() => { setVaults(vaultsData);}, [vaultsData]);

    const handleAddVaultClose = () => setShowAddVaultDialog(false);

    const addVaultMutation = useAddVault();

    const resetContent = () => {
        //reset
        setDashboard(false);
        setManageVaults(false);
    }

    const handleVault=(e, obj) =>{
        if (obj) {
            resetContent();
            setSelectedVault(obj);
            setManageVaults(true);
            highlightVault(e);
        }
    }

    const handleAddVaultSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === true) {
    
          addVaultMutation.mutate({
            name: form.formVaultName.value,
          });
    
          setShowAddVaultDialog(false);
          setValidated(false);
        }
        else {
          setValidated(true);
        }
        event.preventDefault();
        event.stopPropagation();
      };

    return (
        <div>
            <div className="panel-side">
                <div className="panel-side-row">
                    <button className='btn btn-primary' onClick={() => setShowAddVaultDialog(true)}><Icon.Plus size={20}/> Add Vault</button>
                    &nbsp; &nbsp;
                </div>
                {vaults && vaults.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1).map((vault) => {
                    return (
                        <SidePanelRow key={vault.id} obj={vault} title={<span><Icon.Lock size={20}/>&nbsp;&nbsp;{vault.name}</span>} amount={0} type="panel-side-type-vault" sendHandleTransaction={handleVault}  hideCost={true}/>
                    )
                })}
                {vaultsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
                {vaultsIsError && <div className="text-danger">Error loading vaults: {vaultsError.message}</div>}     
            </div> 
            <div className="panel-content">
                {dashboard && (
                    <div className='text-center-mobile home-nav home-nav-2'>
                    <h1><Icon.Lock size={30}/> Vaults</h1>
                    {vaults && vaults.sort((a, b) => a.name > b.name ? 1 : -1).map((vault) => {
                        return (
                            <Button key={vault.id} id={vault.id} onClick={(e) => handleVault(e, vault)}><span><Icon.Lock size={60} /></span>{vault.name}</Button>
                        )
                    })}
                    </div>
                )}
                {manageVaults && (
                    <ManageVaults selectedVault={selectedVault} vaultsData={vaults} vaultsError={vaultsError} vaultsIsError={vaultsIsError} vaultsIsLoading={vaultsIsLoading} />
                )}
            </div>

            <Modal
                size="lg"
                show={showAddVaultDialog}
                onHide={() => setShowAddVaultDialog(false)}
                aria-labelledby="modal-add-vault"
                >
                <Modal.Header closeButton closeVariant='white'>
                    <Modal.Title id="modal-add-vault">Add Vault</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleAddVaultSubmit}>
                        <Form.Group className="mb-3" controlId="formVaultName">
                            <Form.Label>Vault Name</Form.Label>
                            <Form.Control required type="text" maxLength="50" placeholder="Enter a vault name" />
                            <Form.Control.Feedback type="invalid">Please enter a vault name.</Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary" type="submit">Save</Button>&nbsp;
                        <Button variant="secondary" type="button" onClick={handleAddVaultClose}>Cancel</Button>
                    </Form>
                </Modal.Body>
            </Modal>

        </div>
    );
}
