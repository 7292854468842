export const currency = (e) => {
  if (e === undefined) { return 0; }
  return e.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
export const date = (e) => {
  let dt = new Date(e);
  return `${dt.getFullYear()}-${(dt.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}-${dt.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 })}`;
};
export const capitalizeWords = (e) => {
  return e
    //.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
export const switchToBool = (e) => {
  if (e === "on") {
    return true;
  }
  else {
    return false;
  }
};
export const phone = (e) => {
  if (e.length === 10) {
    return e.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
  }
  else if (e.length === 11) {
    return e.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
  }
  else {
    return e;
  }
};
export const floatWithoutComma = (e) => {
  return parseFloat(e.replace(/,/g, ''));
};

export const getSelectSelectedText = (e) => {
  let text = "";
  for (var i = 0; i < e.length; i++) {
    var option = e.options[i];
    if (option.value === e.value) {
      text = option.text;
      break;
    }
  }
  return text;
}

export const highlightTr = (e) => {
  var children = e.target.parentElement.parentElement.children;
  for (var i = 0; i < children.length; i++) {
    children[i].className = children[i].className.replace("selected", "");
  }
  e.target.parentElement.className += " selected";
};

export const highlightAccount = (e) => {
  var className = "panel-side-row panel-side-type-account";
  let elements = document.getElementsByClassName(className + " selected");
  for (var i = 0; i < elements.length; i++) {
    elements[i].className = className;
  }

  if (e.target.className === "panel-side-title" || e.target.className === "panel-side-amount") {
    e.target.parentElement.className = className + " selected";
  }
  else if (e.target.className === className) {
    e.target.className = className + " selected";
  }
};

export const highlightBoard = (e) => {
  var className = "panel-side-row panel-side-type-board";
  let elements = document.getElementsByClassName(className + " selected");
  for (var i = 0; i < elements.length; i++) {
    elements[i].className = className;
  }

  if (e.target.className === "panel-side-title" || e.target.className === "panel-side-amount") {
    e.target.parentElement.className = className + " selected";
  }
  else if (e.target.className === className) {
    e.target.className = className + " selected";
  }
  if (e.target.id.length > 0) {
    let sidepanel = document.getElementById(e.target.id);
    sidepanel.className += " selected";
  }
};

export const highlightVault = (e) => {
  var className = "panel-side-row panel-side-type-vault";
  let elements = document.getElementsByClassName(className + " selected");
  for (var i = 0; i < elements.length; i++) {
    elements[i].className = className;
  }

  if (e.target.className === "panel-side-title" || e.target.className === "panel-side-amount") {
    e.target.parentElement.className = className + " selected";
  }
  else if (e.target.className === className) {
    e.target.className = className + " selected";
  }
  if (e.target.id.length > 0) {
    let sidepanel = document.getElementById(e.target.id);
    sidepanel.className += " selected";
  }
};

export const highlightInventory = (e) => {
  var className = "panel-side-row panel-side-type-board";
  let elements = document.getElementsByClassName(className + " selected");
  for (var i = 0; i < elements.length; i++) {
    elements[i].className = className;
  }

  if (e.target.className === "panel-side-title" || e.target.className === "panel-side-amount") {
    e.target.parentElement.className = className + " selected";
  }
  else if (e.target.className === className) {
    e.target.className = className + " selected";
  }
  if (e.target.id.length > 0) {
    let sidepanel = document.getElementById(e.target.id);
    sidepanel.className += " selected";
  }
};

export const calculateMortgagePayment = (principle, rate, years) => {
  rate = rate / 100;
  var presentValue = Math.pow((1 + rate / 12), years * 12);
  var total = (rate / 12) * principle * (presentValue) / (presentValue - 1) * years / 25;
  return total;
};

export const dateAppendTime = (dt) => {
  var result = "";
  if (dt.length > 0) {
    result = "T" + dt + ":00";
  }
  else {
    result= "T00:00:00";
  }
  return result;
};

export const time24to12 = (t) => {
  var post = "AM";
  var h = new Date(t).getHours();

  if (h >= 12) {
    post = "PM";
    h =  h - 12;
  }
  
  if (h === 0) {
    h = 12;
  }

  return  h + ":" + ("0" + new Date(t).getMinutes()).slice(-2) + post;
}

export const time24to12_2d = (t) => {
  var post = "AM";
  var h = new Date(t).getHours();

  if (h >= 12) {
    post = "PM";
    h =  h - 12;
  }
  
  if (h === 0) {
    h = 12;
  }

  return  ("0" + h).slice(-2) + ":" + ("0" + new Date(t).getMinutes()).slice(-2) + post;
}

export const guid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
    .replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0, 
            v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export const dateTime = (e) => {
  let dt = new Date(e);
  return `${dt.getFullYear()}-${(dt.getMonth() + 1).toLocaleString('en-US', { minimumIntegerDigits: 2 })}-${dt.getDate().toLocaleString('en-US', { minimumIntegerDigits: 2 })} ${time24to12_2d(e)}`;
};

export const dateYear = (e) => {
  let dt = new Date(e);
  return dt.getFullYear();
};
export const time = (e) => {
  return ("0" + new Date(e).getHours()).slice(-2) + ":" + ("0" + new Date(e).getMinutes()).slice(-2);
}

export const togglePanelSideChildren = (e) => {
  e.classList.toggle("panel-side-hide");
}
export const togglePanelSideSelected = (e) => {
  e.classList.toggle("panel-side-open");
}