import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';
import { useNews, useEditNews, useDeleteNews } from 'hooks/news.hook';
import { date } from 'helpers/formatter';

export { ManageNews };

function ManageNews(props) {
  const [validated, setValidated] = useState(false);
  const [showEditNewsDialog, setShowEditNewsDialog] = useState(false);
  const [showDeleteNewsDialog, setShowDeleteNewsDialog] = useState(false);
  const [pinned, setPinned] = useState(props.selectedNews.pinned);
  const [count, setCount] = useState('10');

  const editNewsMutation = useEditNews();
  const deleteNewsMutation = useDeleteNews();
  
  const handleEditNewsClose = () => setShowEditNewsDialog(false);
  const handleDeleteNewsClose = () => setShowDeleteNewsDialog(false);

  const { data: newsData, isError:newsIsError, error:newsError, isLoading:newsIsLoading } = useNews(props.selectedNews.id, count);
  const [news, setNews] = useState();
  useEffect(() => { 
    setNews(newsData); 
  }, [newsData, count]);

  const handleNewsDelete = () => {
    deleteNewsMutation.mutate({
      id: props.selectedNews.id,
    });
    setShowDeleteNewsDialog(false);
    setShowEditNewsDialog(false);
    window.location.reload(false);
  };
  const handleEditNewsSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {

      editNewsMutation.mutate({
        id: props.selectedNews.id,
        name: form.formNewsName.value,
        pinned: pinned,
        tag: form.formNewsTag.value
      });

      props.selectedNews.name = form.formNewsName.value;
      props.selectedNews.tag  = form.formNewsTag.value;
      props.selectedNews.pinned = pinned;
      setShowEditNewsDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleCount = (e) => {
    setCount(e);
  };
 
  return (
    <div>
      <h1><Icon.Book size={30} /> {props.selectedNews.name} <button className="button-text" onClick={() => setShowEditNewsDialog(true)}><Icon.ThreeDots size={40} /></button></h1>
      <p></p>
      <Form.Select className='news-count' value={count} onChange={(e) => handleCount(e.target.value)}>
        <option key={0} value={'10'}>10</option>
        <option key={1} value={'20'}>20</option>
        <option key={2} value={'40'}>40</option>
        <option key={3} value={'80'}>80</option>
      </Form.Select>
      {newsIsLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> }
      {newsIsError && <div className="text-danger">Error loading news: {newsError.message}</div>}     
      <p></p>
      {newsData && newsData.feeds.map((item, index) => (
        <a key={index} className='news' href={item.link} target='_blank' rel="noreferrer">
          <span className='news-title'>{item.title}</span>
          <hr/>
          <span className='news-date'>{date(item.pubDate)} - {item.category} - {item.source}</span>
          {item.imageUrl !== null && <span className='news-img' style={{"backgroundImage":`url(${item.imageUrl})`}} />  }
          <span className='news-description'>{item.description}</span>
        </a>
      ))}

      <Modal
        size="lg"
        show={showEditNewsDialog}
        onHide={() => setShowEditNewsDialog(false)}
        aria-labelledby="modal-edit-board"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-board">Edit News</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditNewsSubmit}>
            <Form.Group className="mb-3" controlId="formNewsName">
              <Form.Label>Name</Form.Label>
              <Form.Control required type="text" maxLength="50" placeholder="Enter an board name" defaultValue={props.selectedNews.name} />
              <Form.Control.Feedback type="invalid">Please enter an board name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNewsTag">
                <Form.Label>Tag</Form.Label>
                <Form.Control required type="text" maxLength="50" placeholder="Enter tags" defaultValue={props.selectedNews.tag} />
                <Form.Control.Feedback type="invalid">Please enter a news tags.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formNewsPinned">
                <Form.Label>Pinned</Form.Label>
                <Form.Check type="switch" defaultChecked={props.selectedNews.pinned} onChange={(e) => {setPinned(!props.selectedNews.pinned);}} />
            </Form.Group>
            <br/><br/>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditNewsClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteNewsDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteNewsDialog}
        onHide={() => setShowDeleteNewsDialog(false)}
        aria-labelledby="modal-delete"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteNewsDialog && <div><p>{props.selectedNews.name}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleNewsDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteNewsClose}>No</Button>
        </Modal.Body>
      </Modal>

    </div>
  );
}