import React from "react";

const CustomExpandButton = (node) => {
  return (
    <div>
      {node && (
          <div className="org-expand-btn">
            <span>{node.data._directSubordinates}</span>
          </div>
      )}
    </div>
  );
};

export default CustomExpandButton;
