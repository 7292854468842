import React, { useState, useEffect, useMemo } from 'react';
import { TypeAhead, TableTransactions } from 'components';
import { Button, Modal, Form, DropdownButton, Dropdown } from 'react-bootstrap';
import { currency, date, getSelectSelectedText, highlightTr, floatWithoutComma } from 'helpers/formatter';
import { useTransactions, useAddTransaction, useEditTransaction, useDeleteTransaction, useTransactionPayees, useTransactionCategories, useImportTransactions, useLatestTransactionOfPayee, useFilteredBalance } from 'hooks/transactions.hook';
import { read, utils, writeFile } from 'xlsx';
import * as Icon from 'react-bootstrap-icons';
import { TransactionSummary } from './TransactionSummary';

export { Transactions };

function Transactions(props) {
  const [validated, setValidated] = useState(false);
  const [accountSelectionValidation, setAccountSelectionValidation] = useState(true);
  const [amountValidation, setAmountValidation] = useState(true);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [showSearchDialog, setShowSearchDialog] = useState(false);
  const [showExportDialog, setShowExportDialog] = useState(false);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [showTransferDialog, setShowTransferDialog] = useState(false);
  const [showSummaryDialog, setShowSummaryDialog] = useState(false);

  const [isImporting, setIsImporting] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState({});
  const [limit, setLimit] = useState(props.limit);
  const [count, setCount] = useState(props.account.count);
  const [currentAccountId, setCurrentAccountId] = useState(0);
  const [searchText, setSearchText] = useState("0");
  const [startDate, setStartDate] = useState(date(new Date(1900, 0, 1)));
  const [endDate, setEndDate] = useState(date(props.account.lastTransactionDate));

  useEffect(() => {
    //run once on account change
    if (currentAccountId !== props.account.id) {
      setCurrentAccountId(props.account.id);
      //setBalance(props.account.total);
      setLimit(props.limit);
      setCount(props.account.count);

      if (new Date(props.account.lastTransactionDate) > Date.now()) { 
        setEndDate(date(props.account.lastTransactionDate)); 
      }
      else {
        setEndDate(date(Date.now()));
      }
    }
  }, [props, currentAccountId]);

  const [accounts, setAccounts] = useState([]);
  useEffect(() => { setAccounts(props.accountsData); }, [props.accountsData]);

  const { data, error, isError, isLoading, hasNextPage, fetchNextPage, isFetching, isFetchingNextPage } = useTransactions(props.account.id, limit, count, searchText, startDate, endDate);
  const [transactions, setTransactions] = useState([]);
  useEffect(() => { setTransactions(data?.pages); }, [data]);

  const { data: filteredBalanceData} = useFilteredBalance(props.account.id, searchText, endDate);
  const [filteredBalance, setFilteredBalance] = useState(0);
  useEffect(() => { setFilteredBalance(filteredBalanceData); }, [filteredBalanceData]);

  const { data: payeeData } = useTransactionPayees(props.account.id);
  const [payees, setPayees] = useState([]);
  useEffect(() => { setPayees(payeeData); }, [payeeData]);

  const { data: categoryData } = useTransactionCategories(props.account.id);
  const [categories, setCategories] = useState([]);
  useEffect(() => { setCategories(categoryData); }, [categoryData]);

  const [payeeInput, setPayeeInput] = useState("0");
  const { data: latestTransactionData } = useLatestTransactionOfPayee(props.account.id, payeeInput);
  useEffect(() => {
    if (latestTransactionData !== undefined) {
      if (document.getElementById("formTransactionCategory") !== null) {
        document.getElementById("formTransactionCategory").value = latestTransactionData.category;
        if (latestTransactionData.amount < 0) {
          setWithdrawal(currency(-1 * latestTransactionData.amount));
          setDeposit("");
        }
        else if (latestTransactionData.amount > 0) {
          setDeposit(currency(latestTransactionData.amount));
          setWithdrawal("");
        }
        else {
          setWithdrawal("");
          setDeposit("");
        }
      }
    }
  }, [latestTransactionData]);

  const addTransactionMutation = useAddTransaction();
  const importTransactionsMutation = useImportTransactions();
  const editTransactionMutation = useEditTransaction();
  const deleteTransactionMutation = useDeleteTransaction();

  const handleAddClose = () => setShowAddDialog(false);
  const handleEditClose = () => { setShowEditDialog(false); }
  const handleDeleteClose = () => setShowDeleteDialog(false);
  const handleSearchClose = () => setShowSearchDialog(false);
  const handleExportClose = () => setShowExportDialog(false);
  const handleImportClose = () => setShowImportDialog(false);
  const handleTransferClose = () => setShowTransferDialog(false);
  const handleSummaryClose = () => setShowSummaryDialog(false);

  const [fileData, setFileData] = useState([{}]);
  const [files, setFiles] = useState([]);

  const handleSearch = (event) => {
    let search = event.target.value;
    if (search.length < 1) { search = "0"; }
    setSearchText(search);

    event.preventDefault();
    event.stopPropagation();
  };

  const handleDateStartFilter = (event) => {
    let dt = event.target.value;
    let dtModify = '';

    if (dt.length < 1) { dtModify = new Date("1900-01-01"); }
    else { dtModify = new Date(dt); }

    setStartDate(date(dtModify.setDate(dtModify.getDate() + 1)));
    
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDateEndFilter = (event) => {
    let dt = event.target.value;
    let dtModify = '';
    if (dt.length < 1) { dtModify = Date.now(); }
    else { dtModify = new Date(dt); }

    setEndDate(date(dtModify.setDate(dtModify.getDate() + 1)));

    event.preventDefault();
    event.stopPropagation();
  };

  const handleImport = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      if (files.length) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (event) => {
          const wb = read(event.target.result);
          const sheets = wb.SheetNames;
          if (sheets.length) {
            const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
            setFileData(rows)
          }
        }
        reader.readAsArrayBuffer(file);

        if (fileData.length > 1) {
          setIsImporting(true);
          importTransactionsMutation.mutate({
            accountId: props.account.id,
            fileData: fileData,
          });

          setTimeout(() => {
            setValidated(false);
            setShowImportDialog(false);

            let newBalance = 0;
            fileData.map(item => {
              newBalance += item.Amount;
            });

            //setBalance(balance + newBalance);
            setFilteredBalance(filteredBalance + newBalance);
            calculateTransactionBalance();

            setFileData([{}]);
          }, 5000);

        }
      }
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleExport = (event) => {
    const format = [];
    let amount = 0;

    for (var i = 0; i < transactions.length; i++) {
      transactions[i].map(item => {
        let dt = new Date(item.date);
        if (item.withdrawal < 0 && item.deposit === 0) { amount = item.withdrawal; }
        else if (item.deposit > 0 && item.withdrawal === 0) { amount = item.deposit; }
        if (item.withdrawal === 0 && item.deposit === 0) { amount = 0; }
        format.push({ "Date": (25569.0 + ((dt.getTime() - (dt.getTimezoneOffset() * 60 * 1000)) / (1000 * 60 * 60 * 24))), "Payee": item.payee, "Category": item.category, "Amount": amount });
      })
    }
    const headings = [[
      'Date',
      'Payee',
      'Category',
      'Amount'
    ]];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, format, { origin: 'A2', skipHeader: true });
    utils.book_append_sheet(wb, ws, 'Sheet1');
    writeFile(wb, `${props.account.name}-${date(Date.now())}.xlsx`);
    setShowExportDialog(false);
    event.preventDefault();
    event.stopPropagation();
  };

  const handleTransfer = (event) => {
    const form = event.currentTarget;

    if (form.formAccountFrom.value === form.formAccountTo.value) { setAccountSelectionValidation(false); } else { setAccountSelectionValidation(true); }
    if (form.formAmount.value <= 0) { setAmountValidation(false); } else { setAmountValidation(true); }

    if (form.checkValidity() === true && accountSelectionValidation && amountValidation) {
      form.formAmount.value = floatWithoutComma(form.formAmount.value);
      addTransactionMutation.mutate({
        date: date(new Date()),
        payee: `Transfer Money To: ${getSelectSelectedText(form.formAccountTo)}`,
        category: "Transfer Money",
        amount: -1 * form.formAmount.value,
        accountId: props.account.id
      });

      //setBalance(balance - form.formAmount.value);
      setFilteredBalance(filteredBalance - form.formAmount.value);
      calculateTransactionBalance();

      addTransactionMutation.mutate({
        date: date(new Date()),
        payee: `Transfer Money From: ${getSelectSelectedText(form.formAccountFrom)}`,
        category: "Transfer Money",
        amount: form.formAmount.value,
        accountId: form.formAccountTo.value
      });

      setShowTransferDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDelete = () => {
    deleteTransactionMutation.mutate({
      id: selectedTransaction.id,
    });

    setShowDeleteDialog(false);
    setShowEditDialog(false);

    //setBalance(balance - selectedTransaction.amount);
    setFilteredBalance(filteredBalance - selectedTransaction.amount);

    setCount(count - 1);
    calculateTransactionBalance();
  };

  const handleAddSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      let amount = 0;
      if (form.formTransactionWithdrawal.value.length > 0) {
        amount = -1 * floatWithoutComma(form.formTransactionWithdrawal.value);
      }
      else if (form.formTransactionDeposit.value.length > 0) {
        amount = floatWithoutComma(form.formTransactionDeposit.value);
      }

      addTransactionMutation.mutate({
        date: form.formTransactionDate.value,
        payee: form.formTransactionPayee.value,
        category: form.formTransactionCategory.value,
        amount: amount,
        accountId: props.account.id
      });

      setShowAddDialog(false);
      setValidated(false);

      //setBalance(balance + amount);
      setFilteredBalance(filteredBalance + amount);
      setCount(count + 1);
      calculateTransactionBalance();
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEditSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      let amount = 0;
      if (form.formTransactionWithdrawal.value.length > 0) {
        amount = -1 * floatWithoutComma(form.formTransactionWithdrawal.value);
      }
      else if (form.formTransactionDeposit.value.length > 0) {
        amount = floatWithoutComma(form.formTransactionDeposit.value);
      }

      editTransactionMutation.mutate({
        id: selectedTransaction.id,
        date: form.formTransactionDate.value,
        payee: form.formTransactionPayee.value,
        category: form.formTransactionCategory.value,
        amount: amount,
        accountId: props.account.id
      });

      setShowEditDialog(false);
      setValidated(false);

      //setBalance(balance - selectedTransaction.amount + amount);
      setFilteredBalance(filteredBalance - selectedTransaction.amount + amount);
      calculateTransactionBalance();
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const editOnClick = (transaction, e) => {
    highlightTr(e);
    if (transaction != null) {
      setSelectedTransaction(transaction);

      if (transaction.amount > 0) {
        setWithdrawal("");
        setDeposit(currency(transaction.amount));
      }
      else if (transaction.amount < 0) {
        setWithdrawal(currency(-1 * transaction.amount));
        setDeposit("");
      }
      else {
        setWithdrawal("");
        setDeposit("");
      }
      setShowEditDialog(true);
    }
  };

  const formatTrProps = (state = {}) => {
    return { onClick: (e) => editOnClick(state.original, e), className: new Date(state.original.date) > Date.now() ? "future-transaction" : ""};
  };
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        Cell: ({ row }) => (
          date(row.original.date)
        )
      },
      {
        Header: "Payee",
        accessor: "payee",
      },
      {
        Header: "Category",
        accessor: "category",
      },
      {
        Header: "Withdrawal",
        accessor: "withdrawal",
        Cell: ({ row }) => (
          <div className='text-right'>{row.original.withdrawal !== 0 ? currency(-1 * row.original.withdrawal) : ""}</div>
        )
      },
      {
        Header: "Deposit",
        accessor: "deposit",
        Cell: ({ row }) => (
          <div className='text-right'>{row.original.deposit !== 0 ? currency(row.original.deposit) : ""}</div>
        )
      },
      {
        Header: "Balance",
        accessor: "balance",
        Cell: ({ row }) => (
          <div className='text-right'>{currency(row.original.balance)}</div>
        )
      },
    ],
    []
  );

  const showAll = () => {
    setLimit(9999999);
  }

  const currentTransactions = [];

  const calculateTransactionBalance = () => {
    {
      transactions && transactions.map((group, index) => {
        group && group.map((g, i) => {

          //if (searchText !== "0") {
          //  g.balance = g.amount;
          //}
          //else {
            if (i === 0 && index === 0) {
              g.balance = filteredBalance;
            }
            else {
              if (i === 0) {
                g.balance = transactions[index - 1][props.limit - 1].balance - transactions[index - 1][props.limit - 1].amount;
              }
              else {
                g.balance = group[i - 1].balance - group[i - 1].amount;
              }
            }
          //}
          currentTransactions.push(g);
        })
      })
    }
  }
  calculateTransactionBalance();

  const [withdrawal, setWithdrawal] = useState("");
  const [deposit, setDeposit] = useState("");
  const [transferAmount, setTransferAmount] = useState("");

  const onChangeWithdrawal = e => {
    setDeposit("");
    setWithdrawal(e.target.value);
  }
  const onChangeDeposit = e => {
    setWithdrawal("");
    setDeposit(e.target.value);
  }
  const blurDeposit = e => {
    if (e.target.value !== "") {
      let result = parseFloat(floatWithoutComma(e.target.value));
      if (!isNaN(result)) { setDeposit(currency(result)); }
      else { setDeposit(""); }
    }
  }
  const blurWithdrawal = e => {
    if (e.target.value !== "") {
      let result = parseFloat(floatWithoutComma(e.target.value));
      if (!isNaN(result)) { setWithdrawal(currency(result)); }
      else { setWithdrawal(""); }
    }
  }
  const blurTransferAmount = e => {
    if (e.target.value !== "") {
      let result = parseFloat(floatWithoutComma(e.target.value));
      if (!isNaN(result)) { setTransferAmount(currency(result)); }
      else { setTransferAmount(""); }
    }
  }
  const onChangeAmount = e => {
    setTransferAmount(e.target.value);
  }
  const showAddDialogClick = e => {
    setDeposit("");
    setWithdrawal("");
    setShowAddDialog(true);
  }

  const showImportDialogClick = e => {
    setIsImporting(false);
    setShowImportDialog(true);
  }
  const showExportDialogClick = e => {
    showAll();
    setShowExportDialog(true);
  }
  const showTransferDialogClick = e => {
    setTransferAmount("");
    setShowTransferDialog(true);
  }

  const lookUpCategory = (e) => {
    setPayeeInput(e);
  }

  return (
    <div>
      <h1><span className='title-icon'><Icon.Bank size={25} /> </span>{props.account.name} - {props.account.accountType.name} - {props.account.bank.name}</h1>
      <p></p>
      <hr/>
      <div className="menu-content">
        <div>
          <button className='btn btn-primary' onClick={showAddDialogClick}><Icon.Plus size={20} /> Transaction</button>&nbsp;
          <DropdownButton id="dropdown-tools" title={<span><Icon.Wrench size={20} /> Tools</span>} className="dropdown-transactions">
            <Dropdown.Item onClick={showTransferDialogClick}><Icon.CurrencyExchange size={20} /> Transfer</Dropdown.Item>
            <Dropdown.Item onClick={showImportDialogClick}><Icon.ArrowDownSquare size={20} /> Import</Dropdown.Item>
            <Dropdown.Item onClick={showExportDialogClick}><Icon.ArrowUpSquare size={20} /> Export</Dropdown.Item>
          </DropdownButton>&nbsp;
          <button className='btn btn-primary' onClick={() => { setShowSummaryDialog(true); }}><Icon.BarChartFill size={20} /></button>&nbsp;
        </div>
        <div className='transition-filters'>
          <span><Form.Control type="text" placeholder="Search..." onChange={handleSearch} /></span>
          <span className='transition-filters-to'> </span>
          <span><Form.Control type="date" placeholder="Enter a start date" onChange={handleDateStartFilter} /></span>
          <span className='transition-filters-to'>-</span>
          <span><Form.Control type="date" placeholder="Enter an end date" onChange={handleDateEndFilter} value={endDate} /></span>
        </div>
      </div>
      <div className='clear'></div>
      {transactions && <TableTransactions columns={columns} data={currentTransactions} formatRowProps={(state) => formatTrProps(state)} />}
      {isLoading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
      {isError && <div className="text-danger">Error loading transactions: {error.message}</div>}

      {hasNextPage && <Button onClick={fetchNextPage} className='btn-full-width'>Load more</Button>}
      {isFetching && !isFetchingNextPage && !isLoading ? <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div> : null}

      {hasNextPage && <Button onClick={showAll} className='btn-full-width'>Show All</Button>}

      <Modal
        size="lg"
        show={showAddDialog}
        onHide={() => setShowAddDialog(false)}
        aria-labelledby="modal-add-transaction"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-add-transaction">Add Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleAddSubmit}>
            <Form.Group className="mb-3" controlId="formTransactionDate">
              <Form.Label>Date</Form.Label>
              <Form.Control required type="date" defaultValue={date(new Date())} placeholder="Enter a date" />
              <Form.Control.Feedback type="invalid">Please enter a date.</Form.Control.Feedback>
            </Form.Group>
            {payees && <TypeAhead controlId="formTransactionPayee" lookUpCategory={lookUpCategory} maxLength="100" data={payees} required={true} label="Payee" placeholder="Enter a payee" feedback="Please enter a payee." />}
            {categories && <TypeAhead controlId="formTransactionCategory" data={categories} maxLength="100" required={true} label="Category" placeholder="Enter a category" feedback="Please enter a category." />}
            <Form.Group className="mb-3" controlId="formTransactionWithdrawal">
              <Form.Label>Withdrawal</Form.Label>
              <Form.Control type="text" value={withdrawal} autoComplete="off" onBlur={blurWithdrawal} onChange={onChangeWithdrawal} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTransactionDeposit">
              <Form.Label>Deposit</Form.Label>
              <Form.Control type="text" value={deposit} autoComplete="off" onBlur={blurDeposit} onChange={onChangeDeposit} />
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleAddClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showEditDialog}
        onHide={() => setShowEditDialog(false)}
        aria-labelledby="modal-edit-transaction"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-transaction">Edit Transaction</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditSubmit}>
            <Form.Group className="mb-3" controlId="formTransactionDate">
              <Form.Label>Date</Form.Label>
              <Form.Control required type="date" defaultValue={date(selectedTransaction.date)} placeholder="Enter a date" />
              <Form.Control.Feedback type="invalid">Please enter a date.</Form.Control.Feedback>
            </Form.Group>
            {payees && <TypeAhead controlId="formTransactionPayee" data={payees} lookUpCategory={lookUpCategory} maxLength="100" required={true} value={selectedTransaction.payee} label="Payee" placeholder="Enter a payee" feedback="Please enter a payee." />}
            {categories && <TypeAhead controlId="formTransactionCategory" data={categories} required={true} maxLength="100" value={selectedTransaction.category} label="Category" placeholder="Enter a category" feedback="Please enter a category." />}
            <Form.Group className="mb-3" controlId="formTransactionWithdrawal">
              <Form.Label>Withdrawal</Form.Label>
              <Form.Control type="text" value={withdrawal} autoComplete="off" onBlur={blurWithdrawal} onChange={onChangeWithdrawal} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTransactionDeposit">
              <Form.Label>Deposit</Form.Label>
              <Form.Control type="text" value={deposit} autoComplete="off" onBlur={blurDeposit} onChange={onChangeDeposit} />
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditClose}>Cancel</Button>
            <Button variant="danger" className='modal-btn-right' onClick={() => setShowDeleteDialog(true)}>Delete</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDeleteDialog}
        onHide={() => setShowDeleteDialog(false)}
        aria-labelledby="modal-delete"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete:</p>
          {showDeleteDialog && <div><p>Date: <strong>{date(selectedTransaction.date)}</strong></p><p>Payee: <strong>{selectedTransaction.payee}</strong></p><p>Category: <strong>{selectedTransaction.category}</strong></p><p>Withdrawal: <strong>{currency(selectedTransaction.withdrawal)}</strong></p><p>Deposit: <strong>{currency(selectedTransaction.deposit)}</strong></p></div>}
          <Button variant="danger" type="submit" onClick={handleDelete}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteClose}>No</Button>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showSearchDialog}
        onHide={() => setShowSearchDialog(false)}
        aria-labelledby="modal-search"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-search">Search</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleSearch}>

            <Form.Group className="mb-3" controlId="formSearchText">
              <Form.Label>Search</Form.Label>
              <Form.Control type="text" maxLength="50" placeholder="Search..." onChange={handleSearch} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDataRange1">
              <Form.Label>Date Start</Form.Label>
              <Form.Control type="date" placeholder="Enter a start date" />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDataRange2">
              <Form.Label>Date End</Form.Label>
              <Form.Control type="date" defaultValue={date(new Date())} placeholder="Enter an end date" />
            </Form.Group>
            <Button variant="primary" type="submit">Search</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleSearchClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showExportDialog}
        onHide={() => setShowExportDialog(false)}
        aria-labelledby="modal-export"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-export">Export</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleExport}>
            <p>Click "Start" to begin exporting.</p>
            <Button variant="primary" type="submit">Start</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleExportClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showImportDialog}
        onHide={() => setShowImportDialog(false)}
        aria-labelledby="modal-import"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-import">Import</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleImport}>
            <Form.Group className="mb-3" controlId="formAccountTo">
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Select File</Form.Label>
              <Form.Control type="file" onChange={(e) => setFiles(e.target.files)} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            </Form.Group>
            <Button variant="primary" type="submit">Import</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleImportClose}>Cancel</Button>
            {isImporting && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={showTransferDialog}
        onHide={() => setShowTransferDialog(false)}
        aria-labelledby="modal-transfer"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-transfer">Transfer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleTransfer}>
            <Form.Group className="mb-3" controlId="formAccountFrom">
              <Form.Label>Account From</Form.Label>
              <Form.Select defaultValue={props.account.id}>
                {accounts && (accounts.map((m) => { return <option key={m.id} value={m.id}>{m.name} - {m.accountType.name} - {m.bank.name}</option> }))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAccountTo">
              <Form.Label>Account To</Form.Label>
              <Form.Select>
                {accounts && (accounts.map((m) => { return <option key={m.id} value={m.id}>{m.name} - {m.accountType.name} - {m.bank.name}</option> }))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAmount">
              <Form.Label>Amount</Form.Label>
              <Form.Control required type="text" value={transferAmount} autoComplete="off" onBlur={blurTransferAmount} onChange={onChangeAmount} placeholder="Enter an amount" />
              <Form.Control.Feedback type="invalid">Please enter an amount.</Form.Control.Feedback>
              {!accountSelectionValidation && <div className="form-error">You must select 2 different accounts. Please try again.</div>}
              {!amountValidation && <div className="form-error">Only an amount greater than 0 is allowed. Please try again</div>}
            </Form.Group>
            <Button variant="primary" type="submit">Transfer</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleTransferClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal
        size="xl"
        show={showSummaryDialog}
        onHide={() => setShowSummaryDialog(false)}
        aria-labelledby="modal-summary"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-summary">{props.account.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <TransactionSummary account={props.account}/>
          <Button variant="secondary" type="button" onClick={handleSummaryClose}>Close</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}