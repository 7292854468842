import { useQuery, useQueryClient, useMutation } from "react-query";
import { fetchVaults, addVault, editVault, deleteVault, fetchVaultRecords, addVaultRecord, editVaultRecord, deleteVaultRecord, fetchVaultRecordKey, uploadToVaultRecord, fetchVaultRecordUpload, deleteVaultRecordUpload } from "../helpers/api";
import { checkUserAuthorizedQuery } from 'helpers/current-user';

export const useVaults = () => {
  const query = useQuery(["get-vaults"], fetchVaults, {select: (data) => data,});
  checkUserAuthorizedQuery(query);
  return query;
};

export const useAddVault = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-vault"], {
    mutationFn: (data) => addVault(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vaults'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-vault"]);
};

export const useEditVault = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-vault"], {
    mutationFn: (data) => editVault(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vaults'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-vault"]);
};

export const useDeleteVault = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-vault"], {
    mutationFn: (data) => deleteVault(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vaults'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-vault"]);
};

export const useVaultRecords = (vaultId) => {
  return useQuery(["get-vault-records", vaultId], fetchVaultRecords, {select: (data) => data,});
};

export const useVaultRecordKey = (vaultRecordId) => {
  return useQuery(["get-vault-record", vaultRecordId], fetchVaultRecordKey, {select: (data) => data,});
};

export const useVaultRecordUpload = (vaultRecordId) => {
  return useQuery(["get-vault-record-upload", vaultRecordId], fetchVaultRecordUpload, {select: (data) => data,});
};

export const useAddVaultRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["add-vault-record"], {
    mutationFn: (data) => addVaultRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vault-records']);
      //queryClient.invalidateQueries(['get-vault-record-upload']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["add-vault-record"]);
};

export const useEditVaultRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["edit-vault-record"], {
    mutationFn: (data) => editVaultRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vault-records']);
      queryClient.invalidateQueries(['get-vault-record']);

      setTimeout(function(){
        queryClient.invalidateQueries(['get-vault-record-upload']);
      }, 5000);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["edit-vault-record"]);
};

export const useDeleteVaultRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-vault-record"], {
    mutationFn: (data) => deleteVaultRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vault-records'])
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-vault-record"]);
};

export const useUploadToVaultRecord = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["upload-vault-record"], {
    mutationFn: (data) => uploadToVaultRecord(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["upload-vault-record"]);
};

export const useDeleteVaultRecordUpload = () => {
  const queryClient = useQueryClient();
  queryClient.setMutationDefaults(["delete-vault-record-upload"], {
    mutationFn: (data) => deleteVaultRecordUpload(data),
    onMutate: async (variables) => {
      const { successCb, errorCb } = variables;
      return { successCb, errorCb };
    },
    onSuccess: (result, variables, context) => {
      if (context.successCb) {
        context.successCb(result);
      }
      queryClient.invalidateQueries(['get-vault-record-upload']);
    },
    onError: (error, variables, context) => {
      if (context.errorCb) {
        context.errorCb(error);
      }
    },
  });
  
  return useMutation(["delete-vault-record-upload"]);
};