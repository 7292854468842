import React from "react";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import BTable from 'react-bootstrap/Table';
import {GlobalFilterInput} from 'components/GlobalFilterInput';
//import InfiniteScroll from "react-infinite-scroll-component";

export function TableBasicClick({ columns, data, formatRowProps, hiddenColumns }) {

  if (hiddenColumns === undefined) { hiddenColumns = []; }
  const initialState = { hiddenColumns: hiddenColumns };

  // Use the useTable Hook to send the columns and data to build the table
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
    preGlobalFilteredRows, setGlobalFilter, state,
  } = useTable({
    columns,
    data,
    initialState
  },
  useGlobalFilter,
  useSortBy,
  );


  /* 
    Render the UI for your table
    - react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
  */
  return (
    <>
      <GlobalFilterInput
          preGlobalFilteredRows={preGlobalFilteredRows}
          setGlobalFilter={setGlobalFilter} globalFilter={state.globalFilter}
        />
      <p></p>
      <BTable {...getTableProps()} responsive className="table table-borderless">
          <thead>
              {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                            {column.render('Header')}
                        </th>
                      ))}
                  </tr>
              ))}
          </thead>
          <tbody {...getTableBodyProps()}>
          {
              rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps(formatRowProps && formatRowProps(row))}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      );
                    })}
                  </tr>
                );
              }
          )}
          </tbody>
      </BTable>
    </>
  );
}