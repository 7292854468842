import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';
import { getUser, isUserVerified, updateUser } from 'helpers/current-user';
import { useEditUserPassword, useEditUserProfile, useDeactivateUser } from 'hooks/users.hook';
import { useAddDefaultNetwork, useUploadToNetwork } from 'hooks/networks.hook';

export { ManageProfileGeneral };

function ManageProfileGeneral() {

  const dispatch = useDispatch();
  const logout = () => dispatch(authActions.logout());

  const [validated, setValidated] = useState(false);
  const [options, setOptions] = useState(false);
  const [showEditPasswordDialog, setShowEditPasswordDialog] = useState(false);
  const [showEditProfileDialog, setShowEditProfileDialog] = useState(false);
  const [showDeleteUserDialog, setShowDeleteUserDialog] = useState(false);

  const [isUploading, setIsUploading] = useState(false);

  const [passwordMatchValidation, setPasswordMatchValidation] = useState(true);

  const [user] = useState(getUser());

  const editUserPasswordMutation = useEditUserPassword();
  const editUserProfileMutation = useEditUserProfile();
  const deactivateUserProfileMutation = useDeactivateUser();
  const uploadToNetwork = useUploadToNetwork();
  const addNetworkMutation = useAddDefaultNetwork();

  const handleEditPasswordClose = () => setShowEditPasswordDialog(false);
  const handleEditProfileClose = () => setShowEditProfileDialog(false);
  const handleDeleteUserClose = () => setShowDeleteUserDialog(false);

  const handleEditPasswordSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true && isUserVerified()) {
      if (form.formPassword1.value === form.formPassword2.value) {
        editUserPasswordMutation.mutate({
          id: user.id,
          password: form.formPassword1.value
        });
      }
      else {
        setPasswordMatchValidation(false);
        setValidated(true);
      }

      setShowEditPasswordDialog(false);
      setValidated(false);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleEditProfileSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true && isUserVerified()) {
      editUserProfileMutation.mutate({
        id: user.id,
        firstName: form.formFirstName.value,
        lastName: form.formLastName.value,
      });

      setShowEditProfileDialog(false);
      setValidated(false);

      user.firstName = form.formFirstName.value;
      user.lastName = form.formLastName.value;
      updateUser(user);
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleUserDeleteSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true && isUserVerified()) {
      deactivateUserProfileMutation.mutate({
        id: user.id,
      });

      setShowDeleteUserDialog(false);
      setValidated(false);

      logout();
    }
    else {
      setValidated(true);
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handlePhotoUpload = (files) => {
    if (user.photoUrl !== undefined && user.photoUrl.length < 1) {
      addNetworkMutation.mutate({});
    }
    let hasUploads = false;
    let timeout = 0;
    let formData = new FormData();
    formData.append(`NetworkId`, user.id);
    formData.append(`NetworkParentId`, user.id);
    [...files].forEach((file, i) => {
        hasUploads = true;
        formData.append(`Files[${i}].Id`, user.id);
        formData.append(`Files[${i}].File`, file);
    });
    if (hasUploads) {
        setTimeout(function () {
            uploadToNetwork.mutate({
                formData: formData
            });
            timeout = 3000;
            setIsUploading(true);
            setTimeout(function () {
              user.photoUrl = `${process.env.REACT_APP_API_URL}/data/networks/${user.id}/${user.id}.jpg`;
              updateUser(user);
              window.location.reload();
            }, timeout);
        }, 500);
    }
  };

  return (
    <div>
      <h1><Icon.PersonSquare size={35} /> Profile</h1>
      <p></p>
      <hr></hr>
      <div className='row'>
        <div className='col-sm-12'>
          <div className="profile-container">
            {user.photoUrl !== undefined && user.photoUrl.length > 0 ? 
              <div className="profile-img" style={{backgroundImage:"url(" + user.photoUrl + ")"}}></div>
            : <div className="profile-img-default"><Icon.Person size={200} /></div>
          }
            <p></p>
            <Form.Group className="mb-3" controlId="formFile">
                <Form.Control type="file" accept=".jpg, .png" onChange={(e) => { handlePhotoUpload(e.target.files);}} />
            </Form.Group>
            <p></p>
            {isUploading && <div className='text-center'><div className="spinner-border spinner-border-lg"></div></div>}
            <p className='text-center'>{`${user.firstName} ${user.lastName}`}</p>
            <p className='text-center'>{user.username}</p>

            {isUserVerified() && options && <div>
              <p><button className='btn btn-primary' onClick={() => setShowEditPasswordDialog(true)}><Icon.GearFill size={20} /> Change Password</button></p>
              <p><button className='btn btn-primary' onClick={() => setShowEditProfileDialog(true)}><Icon.GearFill size={20} /> Update Account</button></p>
              <p><button className='btn btn-danger' onClick={() => setShowDeleteUserDialog(true)}><Icon.GearFill size={20} /> Delete Account</button></p>
            </div>}
            {!options && <div>
              <p><button className='btn btn-primary' onClick={() => setOptions(true)}><Icon.GearFill size={20} /> Options</button></p>
            </div>}
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={showEditPasswordDialog}
        onHide={() => setShowEditPasswordDialog(false)}
        aria-labelledby="modal-edit-profile"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-account">Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditPasswordSubmit}>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control readOnly type="text" defaultValue={user.username} placeholder="Enter a username" />
              <Form.Control.Feedback type="invalid">Please enter a username.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword1">
              <Form.Label>Password</Form.Label>
              <Form.Control required type="password" placeholder="Enter a password" />
              <Form.Control.Feedback type="invalid">Please enter a password.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPassword2">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control required type="password" placeholder="Confirm password" />
              <Form.Control.Feedback type="invalid">Please confirm password.</Form.Control.Feedback>
              {!passwordMatchValidation && <div className="form-error">Your password does not match. Please try again</div>}
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditPasswordClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        size="lg"
        show={showEditProfileDialog}
        onHide={() => setShowEditProfileDialog(false)}
        aria-labelledby="modal-edit-profile"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-edit-account">Update Account</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form noValidate validated={validated} onSubmit={handleEditProfileSubmit}>
            <Form.Group className="mb-3" controlId="formUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control readOnly type="text" defaultValue={user.username} placeholder="Enter a username" />
              <Form.Control.Feedback type="invalid">Please enter a username.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control type="text" defaultValue={user.firstName} placeholder="Enter a first name" />
              <Form.Control.Feedback type="invalid">Please enter a first name.</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control type="text" defaultValue={user.lastName} placeholder="Enter a last name" />
              <Form.Control.Feedback type="invalid">Please enter a last name.</Form.Control.Feedback>
            </Form.Group>
            <Button variant="primary" type="submit">Save</Button>&nbsp;
            <Button variant="secondary" type="button" onClick={handleEditProfileClose}>Cancel</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteUserDialog}
        onHide={() => setShowDeleteUserDialog(false)}
        aria-labelledby="modal-delete-user"
      >
        <Modal.Header closeButton closeVariant='white'>
          <Modal.Title id="modal-delete-user">Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you would like to delete your account:</p>
          {showDeleteUserDialog && <div><p>{user.username}</p></div>}
          <hr></hr>
          <Button variant="danger" type="submit" onClick={handleUserDeleteSubmit}>Yes</Button>&nbsp;
          <Button variant="secondary" type="button" onClick={handleDeleteUserClose}>No</Button>
        </Modal.Body>
      </Modal>
    </div>
  );
}