import { useEffect } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import * as Icon from 'react-bootstrap-icons';
import { history } from 'helpers';
import { authActions } from 'store';
import { useDate } from 'hooks/date-time.hook';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);

    useEffect(() => {
        // redirect to home if already logged in
        if (authUser) history.navigate('/');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // form validation rules 
    const validationSchema = Yup.object().shape({
        username: Yup.string().required('Username is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    const { currentDate, currentTime } = useDate();

    function onSubmit({ username, password }) {
        return dispatch(authActions.login({ username, password }));
    }

    return (
        <div className="container">
            <div className="col-md-6 offset-md-3">
                <div className='text-center panel'>
                    <h6>{currentDate}</h6>
                    <h1>{currentTime}</h1>
                    <hr/>
                </div>
                <div className="card">
                    <h4 className="card-header">Login</h4>
                    <div className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form-group">
                                <label>Username</label>
                                <input name="username" type="text" {...register('username')} className={`form-control ${errors.username ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.username?.message}</div>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                                <div className="invalid-feedback">{errors.password?.message}</div>
                            </div>
                            <button disabled={isSubmitting} className="btn btn-primary btn-login">
                                {isSubmitting && <span className="spinner-border spinner-border-sm mr-1" style={{margin:"0px"}}></span>}
                                Login
                            </button>
                            {authError &&
                                <div className="mt-3 mb-0 error">{authError.message}</div>
                            }
                        </form>
                    </div>
                </div>
                <div className='text-center panel'>
                    <hr/>
                    <br/>
                    <p><a className='android' title='Download Android App' href={`${process.env.REACT_APP_API_URL}/data/apps/bartolo-digital-accounting-1.0.1.apk`}><Icon.Android2 size={60}/><span>Download Android App</span></a></p>
                </div>
            </div>
        </div>
    )
}
